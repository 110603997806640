import "./index.scss";

type Props = {
    title: string,
    description?: string
}

const StreamTooltip: React.FC<Props> = ({
    title,
    description
}) => {
    return (
        <p className="StreamTooltip">
            <strong>{title}</strong><br />
            {description}
        </p>
    )
}

export default StreamTooltip;