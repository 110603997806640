import React from "react";
import { toastNotifications } from "@/components/Toastify";
import useStepper from "@/hooks/useStepper";
import RegistrationPrevBtn from "@/components/RegistrationPrevBtn";
import FormBadge from "@/pages/SignUp/common/FormBadge";
import AuthorizationFormHeader from "@/components/AuthorizationFormHeader";
import FormStepper from "@/pages/SignUp/common/FormStepper";
import BasicInformation from "@/pages/SignUp/common/BasicInformation";
import LocationInformation from "@/pages/SignUp/common/LocationInformation";
import SberInformation from "@/pages/SignUp/common/SberInformation";
import { formHeaderTitles, Steps } from "@/pages/SignUp/FromSber";
import { useChangeTariffs } from "@/pages/ChangeTariff/change-tariffs";
import { useNavigate } from "react-router-dom";
import routes from "@/routes";
import { changeTariffSber } from "@/Schema/changeTariffSchema";

export const STEPS_CHAIN = [Steps.BASE_INFO, Steps.LOCATION_INFO, Steps.SBER_INFO];

export const TariffSber: React.FC = () => {
    const navigate = useNavigate();
    const formik = useChangeTariffs({
        values: {
            format: "offline",
            tariff: "from_sber",
            activity: ["work"],
        },
        validationSchema: (initialValues) => changeTariffSber(initialValues),
        onSuccess: async () => {
            toastNotifications.success("Тариф изменен");
            navigate(routes.profileTariffs);
        },
    });

    const { currStep, nextStep, prevStep } = useStepper(STEPS_CHAIN);
    const title = formHeaderTitles[currStep];
    const showSubtitle = Boolean(currStep === Steps.BASE_INFO);

    return (
        <>
            {currStep !== Steps.BASE_INFO && <RegistrationPrevBtn toPrev={prevStep} />}

            <FormBadge text="Я из Сбера" />

            <AuthorizationFormHeader
                className="FromSber__Header"
                title={title}
                subtitle={showSubtitle && "Здравствуйте!"}
                subtitleOnTop
            />

            <FormStepper className="FromSber__Stepper" steps={3} startFrom={1} currentStep={currStep} />

            <form className="FromSber__Form" autoComplete="off" onSubmit={formik.handleSubmit}>
                {currStep === Steps.BASE_INFO && (
                    <BasicInformation formik={formik} withPhoneNumber disableEmail nextStep={nextStep} />
                )}

                {currStep === Steps.LOCATION_INFO && <LocationInformation formik={formik} nextStep={nextStep} />}

                {currStep === Steps.SBER_INFO && <SberInformation formik={formik} nextStep={nextStep} />}
            </form>
        </>
    );
};
