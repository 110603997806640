import Container from '@/components/Container';
import UnderLiner from "@/components/UnderLiner";
import GreenBgLight from "./icons/lbg-light-green.png";
import './index.scss';

const IntroCompetition = () => {
    return (
        <section className="Intro-Competition">
            <img className="Intro-Competition__Green-Light" src={GreenBgLight} alt="decoration light" />
            <Container className="Intro-Competition__wrapper">
                <span className="Intro-Competition__Catch-Phrase">Больше чем обучение</span>
                <h1 className="Intro-Competition__Title">
                    <span className="Intro-Competition__Title Intro-Competition__Title--gradient-white">Объявляем</span>
                    <span className="Intro-Competition__Title Intro-Competition__Title--gradient-white">победителей конкурса</span>
                    <span className="Intro-Competition__Title Intro-Competition__Title--gradient-white">EdTech-компаний</span>
                </h1>
                <p className="Intro-Competition__Description">
                        Мы получили 63 заявки, из&nbsp;них&nbsp;выбрали <UnderLiner className='Static Intro-Competition__Underline'>12 лучших</UnderLiner>
                </p>
            </Container>
        </section>
    )
}

export default IntroCompetition;