import React, { useEffect, useState } from "react";
import classNames from "classnames";

import Button from "@/components/Button";
import { TariffStatus } from "@/pages/Tariffs/TarrifStatus";
import { useUser } from "@/context/UserContext";
import { TARIFF_WITH_PAYMENT } from "@/constants/validations";
import { useCheckPaymentStatus } from "./useCheckPaymentStatus";
import "./index.scss";
import { openPaymentAcquire } from "@/pages/SignUp/registrations";
import { toastNotifications } from "@/components/Toastify";

export const ProfilePaymentInfo: React.FC = ({ className }: { className?: string }) => {
    const { userInfo } = useUser();
    const [isOpening, setIsOpening] = useState(false);
    const { data: isNotPayedComplete } = useCheckPaymentStatus();

    const handleClickPayment = async () => {
        try {
            setIsOpening(true);
            await openPaymentAcquire();
        } catch (e) {
            setIsOpening(false);
            toastNotifications.error("Что-то пошло не так. Повторите попытку позже");
        }
    };

    if (
        userInfo?.info.format === "offline" &&
        userInfo.info.legal_type === "individual" &&
        TARIFF_WITH_PAYMENT.includes(userInfo.info.tariff) &&
        isNotPayedComplete
    ) {
        return (
            <TariffStatus className={classNames(className, "ProfilePaymentInfo")} status="wait">
                <div className="ProfilePaymentInfo__Content">
                    <div>Вы не произвели оплату, пожалуйста оплатите свое участие с помощью системы оплаты</div>
                    <div>
                        <Button
                            className="ProfilePaymentInfo__Btn"
                            size="sm"
                            text="Оплатить"
                            disabled={isOpening}
                            onClick={handleClickPayment}
                        />
                    </div>
                </div>
            </TariffStatus>
        );
    }

    return null;
};
