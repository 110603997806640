import SocialItem from 'components/SocialItem';
import { SOCIAL_EDUTECH, SOCIAL_TELEGRAM, SOCIAL_VK } from 'constants/social';

const SOCIALS_DATA = [
    SOCIAL_TELEGRAM,
    SOCIAL_VK,
    SOCIAL_EDUTECH
];

const SocialElements = ({ theme = "light" }) => {
    const renderItems = SOCIALS_DATA.map((el, index) => <SocialItem key={index} icon={el.icon} title={el.title} link={el.link} theme={theme} />)

    return (
        <>
            {renderItems}
        </>
    )
}

export default SocialElements;