import React from 'react';
import classNames from "classnames";
import './index.scss';

const headings = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];

type Props = {
    level: 'h1'| 'h2'| 'h3'| 'h4'| 'h5'| 'h6',
    children: React.ReactNode,
    theme?: "light" | "dark",
    gradient?: boolean,
    className?: string,
}

const Headline: React.FC<Props> = ({
    level = 'h2',
    children,
    theme = 'dark',
    gradient = false,
    className
}) => {

    const Tag = headings.includes(level) ? level : 'h2';

    return (
        <Tag className={classNames({
            'Headline': true,
            'Headline--Gradient': gradient,
            [`${className}`]: className,
            [`Headline--${theme}`]: theme,
        })}>
            { children }
        </Tag>
    );
};

export default Headline;