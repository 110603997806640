import React from 'react';
import {ReactComponent as Success} from "./icons/accept-message.svg";

const SuccessIcon = () => {
    return (
        <div style={{
            borderRadius: '50%',
            boxShadow: '0px 2.5px 5px 0px rgba(255, 255, 255, 0.80) inset, -2.5px 0px 5px 0px rgba(255, 255, 255, 0.80) inset'
        }}><Success/></div>
    );
};

export default SuccessIcon;