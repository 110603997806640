import GreenLightTop from './icons/green-light-top.png';
import useMedia from '@/hooks/useMedia';
import EventsSpeakers from './EventsSpeakers';
import EventsWidgets from './EventsWidgets';
import EventsIntro from './EventsIntro';
import EventsAdvantages from './EventsAdvantages';
import './index.scss';


const Events = () => {

    return (
        <div className="Events">
            <img className="Events__Shadow Events__Shadow--1" src={GreenLightTop} alt='decoration light' />
            <EventsAdvantages />
        </div>
    );
};

export default Events;