import React, { useCallback, useEffect, useMemo, useState } from "react";

import "./index.scss";
import { FormikData } from "@/types/formik";
import Button from "@/components/Button";
import Tooltip from "@/components/Tooltip";
import Checkbox from "@/components/Checkbox";
import PhoneInput from "@/components/PhoneInput";
import RegistrationInput from "@/components/RegistrationInput";
import { FormikErrorMessage } from "@/components/FormikErrorMessage";
import { hasError } from "@/utils/formik";
import { FormikErrorWrap } from "@/components/FormikErrorWrap";
import { useFormikDebounceEmail } from "@/hooks/useFormikDebounceEmail";
import { PROCESSING_PERSONAL_LINK } from "@/constants/links";

export type BasicInformationProps = {
    formik: FormikData<any>;
    disableEmail?: boolean;
    nextStep?: () => void;
    withPhoneNumber?: boolean;
};

const BasicInformation: React.FC<BasicInformationProps> = (props) => {
    const { formik, disableEmail, nextStep, withPhoneNumber } = props;

    const isValid = useMemo(() => {
        const basicValid = !(
            hasError(formik, "name", false) ||
            hasError(formik, "surname", false) ||
            hasError(formik, "email", false) ||
            hasError(formik, "agree_processing_of_personal_data", false)
        );
        return !(withPhoneNumber && hasError(formik, "phone", false)) && basicValid;
    }, [formik]);

    const [email, setEmail] = useFormikDebounceEmail(formik);

    return (
        <div className="BasicInformation">
            <FormikErrorWrap className="BasicInformation__InputWrap" formik={formik} name="name">
                <RegistrationInput
                    className="BasicInformation__Input"
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Введите имя"
                    label="Имя"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={hasError(formik, "name")}
                />
            </FormikErrorWrap>

            <FormikErrorWrap className="BasicInformation__InputWrap" formik={formik} name="surname">
                <RegistrationInput
                    className="BasicInformation__Input"
                    type="text"
                    id="surname"
                    name="surname"
                    placeholder="Введите фамилию"
                    label="Фамилия"
                    value={formik.values.surname}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={hasError(formik, "surname")}
                />
            </FormikErrorWrap>

            <FormikErrorWrap className="BasicInformation__InputWrap" formik={formik} name="email">
                <RegistrationInput
                    className="BasicInformation__Input"
                    type="email"
                    id="email"
                    name="email"
                    placeholder={formik.values.tariff === "from_sber" ? "Введите почту Сбера" : "Введите свою почту"}
                    label={formik.values.tariff === "from_sber" ? "Почта Сбера" : "Электронная почта"}
                    value={email}
                    disabled={disableEmail}
                    error={hasError(formik, "email")}
                    onBlur={formik.handleBlur}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </FormikErrorWrap>

            {withPhoneNumber && (
                <FormikErrorWrap className="BasicInformation__InputWrap" formik={formik} name="phone">
                    <PhoneInput
                        className="BasicInformation__Input"
                        id="phone"
                        name="phone"
                        placeholder="Введите номер телефона"
                        label="Телефон"
                        value={formik.values.phone}
                        error={hasError(formik, "phone")}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </FormikErrorWrap>
            )}

            <div className="BasicInformation__Links">
                <Checkbox
                    className="BasicInformation__Checkbox"
                    id="agree_processing_of_personal_data"
                    name="agree_processing_of_personal_data"
                    text="Согласие на"
                    link={PROCESSING_PERSONAL_LINK}
                    linkText="обработку персональных данных"
                    target="_blank"
                    onChange={formik.handleChange}
                    checked={formik.values.agree_processing_of_personal_data}
                    error={hasError(formik, "agree_processing_of_personal_data")}
                />
                <FormikErrorMessage formik={formik} name="agree_processing_of_personal_data" />
            </div>

            <Tooltip
                className="BasicInformation__Tooltip"
                text={"Необходимо заполнить все поля и/или дать согласие\nна обработку персональных данных"}
                diraction="top"
                disabled={isValid}
            >
                <Button
                    className="BasicInformation__Btn"
                    tag="button"
                    onClick={nextStep}
                    text="Далее"
                    size="lg"
                    type="colored"
                    isWide
                    gradient
                    disabled={!isValid}
                />
            </Tooltip>
        </div>
    );
};

export default BasicInformation;
