import { ModerationStatusType } from "@/types/chatTypes";
import "./index.scss";

type Props = {
    moderation_status: ModerationStatusType
}

const ModerationStatus: React.FC<Props> = ({ moderation_status }) => {
    return (
        <>
            {moderation_status === "on_moderation" &&
                <p className="ModerationStatus">
                    Ваш вопрос на модерации. Как только он будет одобрен, сообщение отправится автоматически.
                </p>
            }

            {moderation_status === "rejected" &&
                <p className="ModerationStatus ModerationStatus--rejected">
                    К сожалению, ваш вопрос не прошёл модерацию.
                </p>
            }
        </>
    )
}

export default ModerationStatus;