import React, { PropsWithChildren } from "react";
import classNames from "classnames";

import "./index.scss";
import { ReactComponent as IconWait } from "./icon-wait.svg";
import { ReactComponent as IconReject } from "./icon-reject.svg";

export const TariffStatus: React.FC<
    PropsWithChildren<{
        className?: string;
        status: "wait" | "reject";
    }>
> = (props) => {
    const { className, status, children } = props;
    return (
        <div className={classNames(className, "TariffStatus")}>
            <div className="TariffStatus__Icon">
                {status === "wait" && <IconWait />}
                {status === "reject" && <IconReject />}
            </div>
            <div className="TariffStatus__Content">
                <div className="TariffStatus__ContentInner">{children}</div>
            </div>
        </div>
    );
};
