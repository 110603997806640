import { Outlet } from 'react-router-dom';
import AccessGateway from '@/components/AccessGateway';
import { Helmet } from "react-helmet";

const SignIn = () => {

    return (
        <>
            <Helmet>
                <title>Войдите в профиль | СберУниверситет</title>
                <meta name="description" content="Войти в личный кабинет" />
            </Helmet>
            <AccessGateway
                onCloseNavigateTo="/"
            >
                <Outlet />
            </AccessGateway>
        </>

    )
}

export default SignIn;