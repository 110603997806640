import { useContext, useRef } from "react";
import classNames from "classnames";
import { ReactComponent as FilterIcon } from "./assets/filter-icon.svg";
import { ReactComponent as ArrowIcon } from "./assets/arrow-down.svg";
import { BroadcastContext } from "@/context/BroadcastContext";
import { useClickAway, useToggle } from "react-use";
import "./index.scss";

type Props = {
    className?: string,
    openToRight?: boolean,
}

const ChatFilter: React.FC<Props> = ({ className, openToRight }) => {
    const { filters, activeFilter, toggleFilterMessages } = useContext(BroadcastContext);
    const [isActive, toggle] = useToggle(false);
    const btnRef = useRef(null);

    useClickAway(btnRef, (e) => {
        e.stopPropagation();

        if (isActive) {
            toggle();
        }

    }, ["click"]);

    const renderFilters = filters?.map((el, index) => {
        return (
            <li
                key={index}
                className={classNames({
                    "ChatFilter__Menu-Item": true,
                    "ChatFilter__Menu-Item--active": activeFilter === el.type,
                })}
                onClick={() => toggleFilterMessages(el.type)}
            >
                {el.text}
            </li>
        )
    })

    return (
        <div className={classNames(className, "ChatFilter")}>
            <button
                className="ChatFilter__Btn"
                onClick={toggle}
                ref={btnRef}
            >
                <FilterIcon />
                <ArrowIcon />
            </button>

            <ul className={classNames({
                "ChatFilter__Menu": true,
                "ChatFilter__Menu--active": isActive,
                "ChatFilter__Menu--open-to-right": openToRight,
            })}>
                {renderFilters}
            </ul>
        </div>
    )
}

export default ChatFilter;