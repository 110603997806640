import Headline from "components/Headline";
import Button from "components/Button";
import { ReactComponent as LinkIcon } from "./assets/link-icon.svg";
import classNames from "classnames";
import './index.scss';

type Props = {
    children?: React.ReactNode,
    className?: string,
    title: string[],
    subtitle?: string,
    reference?: string,
    link?: string,
    vertical?: boolean,
    gradientSubtitle?: boolean,
    titleLinked? : boolean,
    togglePopup?: () => void,
}

const SessionHeader: React.FC<Props> = ({
    children,
    className,
    title,
    reference,
    subtitle,
    link,
    vertical = false,
    gradientSubtitle = false,
    titleLinked = false,
    togglePopup = () => {}
}) => {

    const renderListTitle = title?.map((el, index) => {
        return (
            <li
                key={index}
                className="SessionHeader__Title"
            >
                {el}
            </li>
        )
    })

    return (
        <div className={classNames(className, {
            "SessionHeader": true,
            "SessionHeader--vertical": vertical,
        })}>
            {subtitle && <p className={classNames({
                "SessionHeader__Subtitle": true,
                "SessionHeader__Subtitle--gradient": gradientSubtitle,
            })}
            >
                {subtitle}
            </p>
            }
            <div className="SessionHeader__Info">
                <div>
                    {reference &&
                        <Headline
                            className="SessionHeader__Reference"
                            level="h3"
                            theme="dark"
                        >
                            {reference}
                        </Headline>
                    }
                    {
                        title.length > 1 ?
                            <ul className="SessionHeader__Title-list">
                                {renderListTitle}
                            </ul>
                            :
                            <div
                                className={classNames({
                                    "SessionHeader__Title-wrapper": titleLinked
                                })}
                                onClick={togglePopup}
                            >
                                <Headline
                                    className="SessionHeader__Title"
                                    level="h3"
                                    theme="dark"
                                >
                                    {title[0]}
                                </Headline>
                            </div>
                    }
                </div>
                {children}
            </div>
            {
                link &&
                <Button
                    className="SessionHeader__Link"
                    text="по записи"
                    icon={<LinkIcon />}
                    href={"#consultation"}
                    tag="a"
                    type="transparent"
                    iconRight
                />
            }
        </div >
    )
}

export default SessionHeader;