import React from "react";
import Button from "@/components/Button";
import RefreshPasswordImage from "../../icons/icon-popup.png";
import "./index.scss";

const IsRefreshPasword = ({ disabled, onRecoveryPassword }) => {
    return (
        <>
            <div className="PopupRefreshPassword__Image">
                <img src={RefreshPasswordImage} alt="Восстановление" />
            </div>
            <div className="PopupRefreshPassword__Title">Восстановление</div>
            <p className="PopupRefreshPassword__Text">
                На вашу почту будет отправлено письмо с ссылкой для восстановления доступа
            </p>
            <Button
                className="ButtonRefreshPassword"
                type="colored"
                gradient={true}
                text="Отправить письмо"
                disabled={disabled}
                onClick={onRecoveryPassword}
            />
        </>
    );
};

export default IsRefreshPasword;
