import { PicturesArchiveType } from "@/types/interfaces";
import Img01_original from "@/assets/picturesArchive/1.jpg";
import Img01_original_webp from "@/assets/picturesArchive/1.webp";
import Img01_mobile from "@/assets/picturesArchive/1-mobile.png";
import Img01_small from "@/assets/picturesArchive/1-thumbnail.png";

import Img02_original from "@/assets/picturesArchive/2.jpg";
import Img02_original_webp from "@/assets/picturesArchive/2.webp";
import Img02_mobile from "@/assets/picturesArchive/2-mobile.png";
import Img02_small from "@/assets/picturesArchive/2-thumbnail.png";

import Img03_original from "@/assets/picturesArchive/3.jpg";
import Img03_original_webp from "@/assets/picturesArchive/3.webp";
import Img03_mobile from "@/assets/picturesArchive/3-mobile.png";
import Img03_small from "@/assets/picturesArchive/3-thumbnail.png";

import Img04_original from "@/assets/picturesArchive/4.jpg";
import Img04_original_webp from "@/assets/picturesArchive/4.webp";
import Img04_mobile from "@/assets/picturesArchive/4-mobile.png";
import Img04_small from "@/assets/picturesArchive/4-thumbnail.png";

import Img05_original from "@/assets/picturesArchive/5.jpg";
import Img05_original_webp from "@/assets/picturesArchive/5.webp";
import Img05_mobile from "@/assets/picturesArchive/5-mobile.png";
import Img05_small from "@/assets/picturesArchive/5-thumbnail.png";

import Img06_original from "@/assets/picturesArchive/6.jpg";
import Img06_original_webp from "@/assets/picturesArchive/6.webp";
import Img06_mobile from "@/assets/picturesArchive/6-mobile.png";
import Img06_small from "@/assets/picturesArchive/6-thumbnail.png";

import Img07_original from "@/assets/picturesArchive/7.jpg";
import Img07_original_webp from "@/assets/picturesArchive/7.webp";
import Img07_mobile from "@/assets/picturesArchive/7-mobile.png";
import Img07_small from "@/assets/picturesArchive/7-thumbnail.png";

import Img08_original from "@/assets/picturesArchive/8.jpg";
import Img08_original_webp from "@/assets/picturesArchive/8.webp";
import Img08_mobile from "@/assets/picturesArchive/8-mobile.png";
import Img08_small from "@/assets/picturesArchive/8-thumbnail.png";

import Img09_original from "@/assets/picturesArchive/9.jpg";
import Img09_original_webp from "@/assets/picturesArchive/9.webp";
import Img09_mobile from "@/assets/picturesArchive/9-mobile.png";
import Img09_small from "@/assets/picturesArchive/9-thumbnail.png";

import Img10_original from "@/assets/picturesArchive/10.jpg";
import Img10_original_webp from "@/assets/picturesArchive/10.webp";
import Img10_mobile from "@/assets/picturesArchive/10-mobile.png";
import Img10_small from "@/assets/picturesArchive/10-thumbnail.png";

import Img11_original from "@/assets/picturesArchive/11.jpg";
import Img11_original_webp from "@/assets/picturesArchive/11.webp";
import Img11_mobile from "@/assets/picturesArchive/11-mobile.png";
import Img11_small from "@/assets/picturesArchive/11-thumbnail.png";

import Img12_original from "@/assets/picturesArchive/12.jpg";
import Img12_original_webp from "@/assets/picturesArchive/12.webp";
import Img12_mobile from "@/assets/picturesArchive/12-mobile.png";
import Img12_small from "@/assets/picturesArchive/12-thumbnail.png";

import Img13_original from "@/assets/picturesArchive/13.jpg";
import Img13_original_webp from "@/assets/picturesArchive/13.webp";
import Img13_mobile from "@/assets/picturesArchive/13-mobile.png";
import Img13_small from "@/assets/picturesArchive/13-thumbnail.png";

import Img14_original from "@/assets/picturesArchive/14.jpg";
import Img14_original_webp from "@/assets/picturesArchive/14.webp";
import Img14_mobile from "@/assets/picturesArchive/14-mobile.png";
import Img14_small from "@/assets/picturesArchive/14-thumbnail.png";

import Img15_original from "@/assets/picturesArchive/15.jpg";
import Img15_original_webp from "@/assets/picturesArchive/15.webp";
import Img15_mobile from "@/assets/picturesArchive/15-mobile.png";
import Img15_small from "@/assets/picturesArchive/15-thumbnail.png";

import Img16_original from "@/assets/picturesArchive/16.jpg";
import Img16_original_webp from "@/assets/picturesArchive/16.webp";
import Img16_mobile from "@/assets/picturesArchive/16-mobile.png";
import Img16_small from "@/assets/picturesArchive/16-thumbnail.png";

import Img17_original from "@/assets/picturesArchive/17.jpg";
import Img17_original_webp from "@/assets/picturesArchive/17.webp";
import Img17_mobile from "@/assets/picturesArchive/17-mobile.png";
import Img17_small from "@/assets/picturesArchive/17-thumbnail.png";

import Img18_original from "@/assets/picturesArchive/18.jpg";
import Img18_original_webp from "@/assets/picturesArchive/18.webp";
import Img18_mobile from "@/assets/picturesArchive/18-mobile.png";
import Img18_small from "@/assets/picturesArchive/18-thumbnail.png";

import Img19_original from "@/assets/picturesArchive/19.jpg";
import Img19_original_webp from "@/assets/picturesArchive/19.webp";
import Img19_mobile from "@/assets/picturesArchive/19-mobile.png";
import Img19_small from "@/assets/picturesArchive/19-thumbnail.png";

import Img20_original from "@/assets/picturesArchive/20.jpg";
import Img20_original_webp from "@/assets/picturesArchive/20.webp";
import Img20_mobile from "@/assets/picturesArchive/20-mobile.png";
import Img20_small from "@/assets/picturesArchive/20-thumbnail.png";

import Img21_original from "@/assets/picturesArchive/21.jpg";
import Img21_original_webp from "@/assets/picturesArchive/21.webp";
import Img21_mobile from "@/assets/picturesArchive/21-mobile.png";
import Img21_small from "@/assets/picturesArchive/21-thumbnail.png";


export const PICTURES_ARCHIVE: PicturesArchiveType[] = [
    {
        src_original: Img01_original,
        src_original_webp: Img01_original_webp,
        src_for_mobile: Img01_mobile,
        src_small: Img01_small,
    },
    {
        src_original: Img02_original,
        src_original_webp: Img02_original_webp,
        src_for_mobile: Img02_mobile,
        src_small: Img02_small,
    },
    {
        src_original: Img03_original,
        src_original_webp: Img03_original_webp,
        src_for_mobile: Img03_mobile,
        src_small: Img03_small,
    },
    {
        src_original: Img04_original,
        src_original_webp: Img04_original_webp,
        src_for_mobile: Img04_mobile,
        src_small: Img04_small,
    },
    {
        src_original: Img05_original,
        src_original_webp: Img05_original_webp,
        src_for_mobile: Img05_mobile,
        src_small: Img05_small,
    },
    {
        src_original: Img06_original,
        src_original_webp: Img06_original_webp,
        src_for_mobile: Img06_mobile,
        src_small: Img06_small,
    },
    {
        src_original: Img07_original,
        src_original_webp: Img07_original_webp,
        src_for_mobile: Img07_mobile,
        src_small: Img07_small,
    },
    {
        src_original: Img08_original,
        src_original_webp: Img08_original_webp,
        src_for_mobile: Img08_mobile,
        src_small: Img08_small,
    },
    {
        src_original: Img09_original,
        src_original_webp: Img09_original_webp,
        src_for_mobile: Img09_mobile,
        src_small: Img09_small,
    },
    {
        src_original: Img10_original,
        src_original_webp: Img10_original_webp,
        src_for_mobile: Img10_mobile,
        src_small: Img10_small,
    },
    {
        src_original: Img11_original,
        src_original_webp: Img11_original_webp,
        src_for_mobile: Img11_mobile,
        src_small: Img11_small,
    },
    {
        src_original: Img12_original,
        src_original_webp: Img12_original_webp,
        src_for_mobile: Img12_mobile,
        src_small: Img12_small,
    },
    {
        src_original: Img13_original,
        src_original_webp: Img13_original_webp,
        src_for_mobile: Img13_mobile,
        src_small: Img13_small,
    },
    {
        src_original: Img14_original,
        src_original_webp: Img14_original_webp,
        src_for_mobile: Img14_mobile,
        src_small: Img14_small,
    },
    {
        src_original: Img15_original,
        src_original_webp: Img15_original_webp,
        src_for_mobile: Img15_mobile,
        src_small: Img15_small,
    },
    {
        src_original: Img16_original,
        src_original_webp: Img16_original_webp,
        src_for_mobile: Img16_mobile,
        src_small: Img16_small,
    },
    {
        src_original: Img17_original,
        src_original_webp: Img17_original_webp,
        src_for_mobile: Img17_mobile,
        src_small: Img17_small,
    },
    {
        src_original: Img18_original,
        src_original_webp: Img18_original_webp,
        src_for_mobile: Img18_mobile,
        src_small: Img18_small,
    },
    {
        src_original: Img19_original,
        src_original_webp: Img19_original_webp,
        src_for_mobile: Img19_mobile,
        src_small: Img19_small,
    },
    {
        src_original: Img20_original,
        src_original_webp: Img20_original_webp,
        src_for_mobile: Img20_mobile,
        src_small: Img20_small,
    },
    {
        src_original: Img21_original,
        src_original_webp: Img21_original_webp,
        src_for_mobile: Img21_mobile,
        src_small: Img21_small,
    },
]