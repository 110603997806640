import FeatureCard from "../FeatureCard";
import classNames from "classnames";
import { ReactComponent as ArrowRightLight } from "./assets/arrow-right-white.svg";
import { ReactComponent as ArrowRightDark } from "./assets/arrow-right-black.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import useMedia from "@/hooks/useMedia";
import "swiper/css";
import "swiper/css/navigation";
import "./index.scss";

const PlanFeatures = ({ features, theme = "light" }) => {
    const { isMobile } = useMedia();

    const swiperParams = {
        spaceBetween: 8,
        slidesPerView: "auto",
        slidesOffsetBefore: isMobile ? 16 : 24,
        grabCursor: true,
        modules: [Navigation],
        navigation: {
            prevEl: ".PlanFeatures__Slider-prev",
            nextEl: ".PlanFeatures__Slider-next",
        },
    };

    const renderFeatures = features?.map((el, index) => {
        return (
            <SwiperSlide key={index} className="PlanFeatures__swiper-slide">
                <FeatureCard title={el.title} description={el.description} theme={theme} />
            </SwiperSlide>
        );
    });

    return (
        <div
            className={classNames({
                PlanFeatures: true,
                "PlanFeatures--light": theme === "light",
                "PlanFeatures--dark": theme === "dark",
            })}
        >
            <p className="PlanFeatures__Title">Что входит в тариф</p>
            <div className="PlanFeatures__Slider-Wrapper">
                <Swiper {...swiperParams}>{renderFeatures}</Swiper>
                <div className="PlanFeatures__Slider-prev">
                    {theme === "light" ? <ArrowRightDark /> : <ArrowRightLight />}
                </div>
                <div className="PlanFeatures__Slider-next">
                    {theme === "light" ? <ArrowRightDark /> : <ArrowRightLight />}
                </div>
            </div>
        </div>
    );
};

export default PlanFeatures;
