import { useNavigate } from 'react-router-dom';
import GreenLightLeft from './assets/green-light-left.png';
import BlueLightRight from './assets/blue-light-right.png';
import WhiteLightTopLarge from './assets/white-light-top-large.png';
import WhiteLightTopSmall from './assets/white-light-top-small.png';
import { ReactComponent as CloseIcon } from './assets/close-icon.svg';
import './index.scss';

const AccessGateway = ({ children, onCloseNavigateTo = '/' }) => {

    const navigate = useNavigate();

    const handleClose = () => {
        navigate(onCloseNavigateTo)
    }

    return (
        <main className="AccessGateway">
            <picture>
                <source srcSet={WhiteLightTopSmall} media='(max-width: 575px)' />
                <img className="AccessGateway__Decoration01" src={WhiteLightTopLarge} alt='decoration light' />
            </picture>
            <img className="AccessGateway__Decoration02" src={BlueLightRight} alt='decoration light' />
            <img className="AccessGateway__Decoration03" src={GreenLightLeft} alt='decoration light' />

            <div className='AccessGateway__Container'>
                <button
                    className='AccessGateway__Close-btn'
                    onClick={handleClose}
                >
                    <CloseIcon />
                </button>
                {children}
            </div>
        </main>
    )
}

export default AccessGateway;