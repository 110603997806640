import React from 'react';
import './index.scss';
import Typography  from "../Typography";
import {ReactComponent as Check} from './check.svg';
import classNames from "classnames";

/**
 *
 * @param text
 * @param icon
 * @param theme 'light' | 'dark'
 */

const Advantage = ({
    text,
    icon,
    theme = 'light'
}) => {
    return (
        <div className={classNames(({
            'Advantage': true,
            [`Advantage--${theme}`]: theme
        }))}>
            <div className="Advantage__Icon">
                {
                    icon ? icon : (<Check/>)
                }
            </div>
            <Typography 
                color={theme === 'dark' ? 'light' : 'dark'}
                size={theme === 'dark' ? 'lg' : 'md'}
            >
                <span dangerouslySetInnerHTML={{__html: text}}></span>
            </Typography>
        </div>
    );
};

export default Advantage;