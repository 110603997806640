import { SPEAKERS_DATA } from '@/constants/speakersData';
import { SessionDataType } from '@/types/interfaces';
import sokolov_presentation from '@/assets/presentations/sokolov_presentation.pdf';
import dmitrov_presentation from '@/assets/presentations/dmitrov_presentation.pdf';
import sukhikh_presentation from '@/assets/presentations/sukhikh_presentation.pdf';
import uykhazi_presentation from '@/assets/presentations/uykhazi_presentation.pdf';
import javadova_presentation from '@/assets/presentations/javadova_presentation.pdf';
import korneva_presentation from '@/assets/presentations/korneva_presentation.pdf';
import fedyakov_presentation from '@/assets/presentations/fedyakov_presentation.pdf';
import lukashkin_presentation from '@/assets/presentations/lukashkin_presentation.pdf';
import mihaulyk_presentation from '@/assets/presentations/mihaulyk_presentation.pdf';
import bykova_presentation from '@/assets/presentations/bykova_presentation.pdf';
import markina_presentation from '@/assets/presentations/markina_presentation.pdf';
import kalashnikov_presentation from '@/assets/presentations/kalashnikov_presentation.pdf';
import zhuravleva_presentation from '@/assets/presentations/zhuravleva_presentation.pdf';

type PROGRAM_TYPE = {
    onCampus: SessionDataType[],
    online: SessionDataType[]
}

const multiple_sessions_01: SessionDataType[] = [
    {
        type: "multiple_sessions_01",
        streamingStatus: "notStarted",
        startTime: "12:30",
        endTime: "14:00",
        streamNum: 1,
        subtitle: "Дискуссия",
        reference: "Туманность Андромеды",
        title: ["Человек будущего и чему его учить: миссия корпоративных университетов"],
        sessionDescription: "<p>На сессии мы обсудим будущее корпоративных университетов. Как корпоративным университетам использовать динамику развития технологий? Как реагировать на «поколенческий сдвиг»? Как быть в роли доверенного советника бизнеса, а не исполнителя заказов на обучение? Как формировать свое ценностное предложение — следовать за потребностями заказчиков или развивать зрелость клиентов, формируя культуру потребления образовательного контента? Как объединить усилия корпоративных университетов разных компаний и отраслей для поиска синергических эффектов?</p>",
        sessionReference: "*Научно-фантастический роман Ивана Ефремова о воображаемом мире, населенном смелыми людьми, совершающими рискованные научные эксперименты и опасные межпланетные путешествия. В этом мире счастье — в поиске, в ошибках, в сомнениях, в мечтах.",
        badgeColor: "grey",
        badgeText: "Мастерская развития человека",
        moderator: {
            fullName: "Александр Шаталов",
            title: "модератор",
            ...SPEAKERS_DATA.speaker_Shatalov
        },
        mediaLink: "https://vk.com/video_ext.php?oid=-184379939&id=456239470&hd=2",
        speakers: [
            {
                fullName: "Андрей Афонин",
                title: "спикер",
                ...SPEAKERS_DATA.speaker_Afonin
            },
            {
                fullName: "Андрей Шобанов",
                title: "спикер",
                ...SPEAKERS_DATA.speaker_Shobanov
            },
            {
                fullName: "Полина Колесова",
                title: "спикер",
                ...SPEAKERS_DATA.speaker_Kolesova
            },
        ]
    },
    {
        type: "multiple_sessions_01",
        streamingStatus: "notStarted",
        startTime: "12:30",
        endTime: "14:00",
        streamNum: 2,
        subtitle: "Дискуссия",
        reference: "Совершенный робот",
        title: ["Главный вопрос жизни и вселенной: какое будущее у генеративных моделей?"],
        sessionDescription: "<p>На сессии мы обсудим, что из себя представляют генеративные модели – модели, способные создавать новые данные, такие как тексты, изображения, видео, звук.</p><p>Рассмотрим, в каких сферах используются генеративные модели (успешно и не очень). Затронем социальный аспект, ведь многие переживают, что уже завтра останутся без работы из-за генеративных моделей, а студенты и школьники начнут их использовать постоянно, что негативно скажется на их образовании. И, конечно, поразмышляем о будущем генеративных моделей.</p>",
        sessionReference: "*Научно-фантастический сборник рассказов Айзека Азимова о создании продвинутого робота.",
        badgeColor: "green",
        badgeText: "Лаборатория человекоцентричных технологий",
        moderator: {
            fullName: "Дмитрий Зубцов",
            title: "модератор",
            ...SPEAKERS_DATA.speaker_Zubtsov
        },
        mediaLink: "https://vk.com/video_ext.php?oid=-184379939&id=456239475&hd=2",
        presentationLinks: [
            {
                text: "Презентация Евгения Соколова",
                link: sokolov_presentation
            },
            {
                text: "Презентация Дениса Димитрова",
                link: dmitrov_presentation
            },
        ],
        speakers: [
            {
                fullName: "Денис Димитров",
                title: "спикер",
                ...SPEAKERS_DATA.speaker_Dmitrov
            },
            {
                fullName: "Яна Чаруйская",
                title: "спикер",
                ...SPEAKERS_DATA.speaker_Charuyskaya
            },
            {
                fullName: "Евгений Соколов",
                title: "спикер",
                ...SPEAKERS_DATA.speaker_Sokolov
            },
            {
                fullName: "Илья Иванов",
                title: "спикер",
                ...SPEAKERS_DATA.speaker_Ivanov
            },
        ]
    },
    {
        type: "multiple_sessions_01",
        streamingStatus: "notStarted",
        startTime: "12:30",
        endTime: "14:00",
        streamNum: 3,
        subtitle: "Дискуссия",
        reference: "Понедельник начинается в субботу",
        title: ["Инновации: практика развития технологического предпринимательства в вузах"],
        sessionDescription: "<p>Ведущей движущей силой на пути к прогрессу являются инновации и высокие технологии. Сегодня цель образовательной системы не ограничивается лишь передачей знаний. Основная задача заключается в том, чтобы преобразовывать эти знания в новаторские продукты и передовые технологии. На сессии обсудим, почему вузам уже сейчас важно готовить технологических предпринимателей, обучая созданию инноваций, и как это соотносится с общим развитием страны. Особое внимание уделим лучшим практикам разработки подобных образовательных программ в регионах и столице.</p>",
        sessionReference: "*Фантастическая юмористическая повесть братьев Стругацких о научно-исследовательском институте НИИЧАВО и инновациях, которые происходят в рамках этого учреждения.",
        badgeColor: "blue",
        badgeText: "Центр внедрения EdTech",
        moderator: {
            fullName: "Дмитрий Коваленко",
            title: "модератор",
            ...SPEAKERS_DATA.speaker_Kovalenko
        },
        mediaLink: "https://vk.com/video_ext.php?oid=-184379939&id=456239469&hd=2",
        presentationLinks: [
            {
                text: "Презентация Леонида Сухих",
                link: sukhikh_presentation
            }
        ],
        speakers: [
            {
                fullName: "Илья Обабков",
                title: "спикер",
                ...SPEAKERS_DATA.speaker_Obabkov
            },
            {
                fullName: "Ольга Цуканова",
                title: "спикер",
                ...SPEAKERS_DATA.speaker_Tsukanova
            },
            {
                fullName: "Дмитрий Кулиш",
                title: "спикер",
                ...SPEAKERS_DATA.speaker_Kulish
            },
            {
                fullName: "Леонид Сухих",
                title: "спикер",
                ...SPEAKERS_DATA.speaker_Sukhikh
            },
            {
                fullName: "Андрей Иващенко",
                title: "спикер",
                ...SPEAKERS_DATA.speaker_Ivashchenko
            },
        ]
    },
]

const multiple_sessions_02: SessionDataType[] = [
    {
        type: "multiple_sessions_02",
        streamingStatus: "notStarted",
        startTime: "15:00",
        endTime: "16:30",
        streamNum: 1,
        subtitle: "Кейс-сессия",
        reference: "Больше, чем люди",
        title: ["Трансформация культуры, процессов и подходов с фокусом на человека"],
        sessionDescription: "<p>Бизнес ориентирован на достижение конкретных финансовых показателей, и они же могут служить основой для оценки эффективности работы сотрудников. Такой подход может стимулировать краткосрочные результаты, однако, может ли он стать основой для успешности и устойчивости в турбулентном будущем? Мы видим, как меняются тренды в эпоху неопределенности. Бизнес, общество, образование все чаще ориентируются на человекоцентричный подход, где человек во всех его проявлениях становится в центре внимания, а его опыт и потребности создают новые подходы и форматы диалога. Трансформация культуры, процессов и подходов с ориентацией на человека помогают повышать эффективность, выполнять стратегические цели и получать выгоду на длинной дистанции. </p>\r\n<p>Дискуссия будет направлена на обсуждение тенденций и проблематики развития человеческого потенциала в корпоративной среде и обществе, а также на выявление возможных конфликтов при внедрении подхода.</p>",
        sessionReference: "*Научно-фантастический роман Теодора Стерджена о детях с необычными способностями. Однажды ребята объединяются, чтобы создать сверхорганизм для выживания в мире и противостояния ему.",
        badgeColor: "grey",
        badgeText: "Мастерская развития человека",
        moderator: {
            fullName: "Иван Шафранов",
            title: "модератор",
            ...SPEAKERS_DATA.speaker_Shafranov
        },
        mediaLink: "https://vk.com/video_ext.php?oid=-184379939&id=456239464&hd=2",
        presentationLinks: [
            {
                text: "Презентация Евгения Уйхази",
                link: uykhazi_presentation
            },
            {
                text: "Презентация Лейлы Джавадовы",
                link: javadova_presentation
            },
            {
                text: "Презентация Татьяны Корневой",
                link: korneva_presentation
            },
        ],
        speakers: [
            {
                fullName: "Татьяна Корнева",
                title: "спикер",
                ...SPEAKERS_DATA.speaker_korneva
            },
            {
                fullName: "Евгений Уйхази",
                title: "спикер",
                ...SPEAKERS_DATA.speaker_Uykhazi
            },
            {
                fullName: "Марк Кукушкин",
                title: "спикер",
                ...SPEAKERS_DATA.speaker_Kukushkin
            },
            {
                fullName: "Лейла Джавадова",
                title: "спикер",
                ...SPEAKERS_DATA.speaker_Javadova
            },
        ]
    },
    {
        type: "multiple_sessions_02",
        streamingStatus: "notStarted",
        startTime: "15:00",
        endTime: "16:30",
        streamNum: 2,
        subtitle: "Дискуссия",
        reference: "Ложная слепота",
        title: ["Инструкция по применению: как пользоваться технологиями, чтобы не терять, а наращивать интеллектуальные возможности?"],
        sessionDescription: "<p>По данным статистики средний россиянин проводит около 8 часов в сутки перед экраном телефона или компьютера. Технологии позволяют людям решать такие задачи, к которым без них мы не смогли бы и подступиться. Однако мы все чаще слышим, что это происходит за счет ухудшения когнитивных способностей человека: мы не помним количество телефонных номеров как раньше, не ориентируемся в городе без карты, жалуемся на рассеянность, нам кажется, что мы меньше читаем книг, чем наши родители. И даже для длинных видео нам порой не хватает сосредоточенности. Кое-кто даже говорит о «цифровом кретинизме».</p>\r\n<p>Правда ли, что гаджеты делают нас глупее? Или это удобное оправдание? Какие на самом деле произошли изменения в интеллекте человека за последние 20–30 лет? Нужен ли взрослому человеку сознательно ограничивать использование гаджетов и даже устроить себе  «цифровой детокс» для поддержания интеллектуальной формы?</p>",
        sessionReference: "*Научно-фантастический роман канадского писателя-фантаста Питера Уоттса о столкновении землян с неземной цивилизацией. В романе исследуются темы личности, искусственного интеллекта, нейробиологии и эволюции человека",
        badgeColor: "green",
        badgeText: "Лаборатория человекоцентричных технологий",
        moderator: {
            fullName: "Наталья Журавлева",
            title: "модератор",
            ...SPEAKERS_DATA.speaker_Zhuravleva
        },
        mediaLink: "https://vk.com/video_ext.php?oid=-184379939&id=456239474&hd=2",
        presentationLinks: [
            {
                text: "Презентация Натальи Журавлевой",
                link: zhuravleva_presentation
            }
        ],
        speakers: [
            {
                fullName: "Вячеслав Дубынин",
                title: "спикер",
                ...SPEAKERS_DATA.speaker_Dubynin
            },
            {
                fullName: "Максим Козлов",
                title: "спикер",
                ...SPEAKERS_DATA.speaker_Kozlov
            },
            {
                fullName: "Андрей Пуртов",
                title: "спикер",
                ...SPEAKERS_DATA.speaker_Purtov
            },
            {
                fullName: "Владимир Синицын",
                title: "спикер",
                ...SPEAKERS_DATA.speaker_Sinitsyn
            },
        ]
    },
    {
        type: "multiple_sessions_02",
        streamingStatus: "notStarted",
        startTime: "15:00",
        endTime: "16:30",
        streamNum: 3,
        subtitle: "Кейс-сессия",
        reference: "Автостопом по галактике",
        title: ["Тенденции развития образования: как EdTech делает обучение человекоцентричным?"],
        sessionDescription: "<p>В последние годы рынок EdTech находится в центре внимания и ни года не стоит на месте. Продвинутые технологии, такие как гибридное обучение и AR/VR, давно уже не являются просто модными словами — они уверенно заняли место в медиаобсуждениях рядом с устойчивыми тенденциями, такими как персонализация, геймификация, социальное обучение. Пандемия усилила этот процесс, а появление генеративных технологий добавило новый вызов: адаптацию образования к меняющимся запросам обучающихся.</p>\r\n<p>Какие изменения происходят в процессе разработки образовательных программ в условиях стремительного технологического прогресса? Как эффективно интегрировать технологии в образовательные решения, ориентируясь на реальные потребности, а не на модные тенденции? А главное — как с помощью технологий сделать обучение человекоцентричным?</p>",
        sessionReference: "*«Автостопом по галактике» — серия юмористических романов Дугласа Адамса, в которой землянин отправляется исследовать самые загадочные и опасные уголки Галактики, чтобы найти ответ на «Главный вопрос жизни, вселенной и всего остального.",
        badgeColor: "blue",
        badgeText: "Центр внедрения EdTech",
        moderator: {
            fullName: "Вячеслав Юрченков",
            title: "модератор",
            ...SPEAKERS_DATA.speaker_Yurchenkov
        },
        mediaLink: "https://vk.com/video_ext.php?oid=-184379939&id=456239472&hd=2",
        presentationLinks: [
            {
                text: "Презентация Александра Федякова",
                link: fedyakov_presentation,
            },
            {
                text: "Презентация Сергея Лукашкина",
                link: lukashkin_presentation,
            },
            {
                text: "Презентация Марии Михайлюк",
                link: mihaulyk_presentation
            },
        ],
        speakers: [
            {
                fullName: "Марианна Снигирева",
                title: "спикер",
                ...SPEAKERS_DATA.speaker_Snigireva
            },
            {
                fullName: "Мария Михайлюк",
                title: "спикер",
                ...SPEAKERS_DATA.speaker_Mihaulyk
            },
            {
                fullName: "Александр Федяков",
                title: "спикер",
                ...SPEAKERS_DATA.speaker_Fedyakov
            },
            {
                fullName: "Сергей Лукашкин",
                title: "спикер",
                ...SPEAKERS_DATA.speaker_Lukashkin
            },
        ]
    },
]

const multiple_sessions_03: SessionDataType[] = [
    {
        type: "multiple_sessions_03",
        streamingStatus: "notStarted",
        startTime: "17:00",
        endTime: "18:30",
        streamNum: 1,
        subtitle: "Дискуссия",
        reference: "Игра в бисер",
        title: ["Развитие интеллектуального потенциала организации: роль профессиональных сообществ"],
        sessionDescription: "<p>В современной эпохе технологической революции, где сливаются физический, цифровой и биологический миры, важно, чтобы компании не только заменяли людей машинами, но и вдохновляли их на творчество, риск и инновации. Объединение в сообщества, подобно живым организмам, помогает эффективнее осваивать мир и создавать новое. Как именно сообщества влияют на развитие человечности, сохраняя умение работать в коллективе, и как лучше всего обеспечить эффективное взаимодействие сотрудников в этих сообществах, учитывая влияние технологий? Какие аспекты руководители должны учесть при организации этого процесса? Как сообщества влияют на эффективность бизнеса и культуру инноваций?</p>",
        sessionReference: "*Фантастический роман Германа Гессе о стране, в которую попадают самые талантливые ученые и целеустремленные люди. Все институты этой страны подчинены Игре, собирающей в единое целое наиболее совершенные творения человеческой мысли.",
        badgeColor: "grey",
        badgeText: "Мастерская развития человека",
        moderator: {
            fullName: "Мария Провкина",
            title: "модератор",
            ...SPEAKERS_DATA.speaker_Provkina
        },
        mediaLink: "https://vk.com/video_ext.php?oid=-184379939&id=456239465&hd=2",
        presentationLinks: [
            {
                text: "Презентация Анастасии Быковой",
                link: bykova_presentation,
            },
            {
                text: "Презентация Светланы Маркиной",
                link: markina_presentation,
            },
            {
                text: "Презентация Виктории Кашниковой",
                link: kalashnikov_presentation,
            }
        ],
        speakers: [
            {
                fullName: "Анастасия Быкова",
                title: "спикер",
                ...SPEAKERS_DATA.speaker_Bykova
            },
            {
                fullName: "Виктория Кашникова",
                title: "спикер",
                ...SPEAKERS_DATA.speaker_Kalashnikov
            },
            {
                fullName: "Светлана Маркина",
                title: "спикер",
                ...SPEAKERS_DATA.speaker_Markina
            },
            {
                fullName: "Иоанна Петш",
                title: "спикер",
                ...SPEAKERS_DATA.speaker_Petsch
            },
        ]
    },
    {
        type: "multiple_sessions_03",
        streamingStatus: "notStarted",
        startTime: "17:00",
        endTime: "18:30",
        streamNum: 2,
        subtitle: "Дискуссия",
        reference: "Задача трех тел",
        title: ["Забота о будущем: китайская фантастика и мечта"],
        sessionDescription: "<p>Технологии, формирующие будущее, не только радикально преображают мир, но и оказывают мощное культурное влияние, вдохновляя фантастов на конструирование вариантов развития человечества. На стыке науки и литературы рождаются смелые идеи и концепции, отражающие наши мечты и страхи. Это происходит и в Китае, совершившем в последние десятилетия технологический рывок, которому сопутствовало возрождение китайской фантастики. «Задача трех тел» Лю Цысиня открыла миру неизвестную до недавнего времени сторону современной культуры КНР, а ведущий китайский венчурный инвестор Ли Кай-Фу в соавторстве с фантастом Чэнь Цюфанем предложил смелое видение будущего цифровых технологий в книге «Искусственный интеллект 2041».</p>\r\n<p>Цель дискуссии – обсудить влияние научной фантастики на общество. Литература как отражение современных технологий, или напротив, фантастика стала важным для истории культурным фактором, стимулирующим прогресс в Китае. И как следствие, в чем научная фантастика может помочь нам развивать науку и создавать технологии здесь и сейчас.</p>",
        sessionReference: "*Фантастический роман Лю Цысиня о преодолении груза прошлого, хрупкости человеческой цивилизации и ценности научного прогресса.",
        badgeColor: "green",
        badgeText: "Лаборатория человекоцентричных технологий",
        moderator: {
            fullName: "Альберт Ефимов",
            title: "модератор",
            ...SPEAKERS_DATA.speaker_Efimov
        },
        mediaLink: "https://vk.com/video_ext.php?oid=-184379939&id=456239473&hd=2",
        speakers: [
            {
                fullName: "Константин Фрумкин",
                title: "спикер",
                ...SPEAKERS_DATA.speaker_Frumkin
            },
            {
                fullName: "Николай Трябин",
                title: "спикер",
                ...SPEAKERS_DATA.speaker_Tryabin
            },
            {
                fullName: "Сергей Переслегин",
                title: "спикер",
                ...SPEAKERS_DATA.speaker_Pereslegin
            },
        ]
    },
    {
        type: "multiple_sessions_03",
        streamingStatus: "notStarted",
        startTime: "17:00",
        endTime: "18:30",
        streamNum: 3,
        subtitle: "Интерактив с аудиторией",
        reference: "Футурологический конгресс",
        title: ["Стратегии и «лайфхаки» непрерывного обучения: методики, технологии, приемы"],
        sessionDescription: "<p>Раньше нормой считалось обновление своих знаний и навыков раз в 10 лет. Но сегодня такой подход может привести к отставанию от быстро меняющегося рынка труда и даже поставить под угрозу карьеру. Сегодня необходимо выделять не меньше 3-4 часов в неделю для профессионального роста.</p>\r\n<p>Но как держать такой темп обучения, не снижая качества? Как не упустить возможности? И как избежать «подводных камней» онлайн-обучения, которое становится таким популярным?</p>\r\n<p>Чтобы разобраться в этих вопросах, мы проведем уникальную сессию: объединим интерактивное ток-шоу и исследовательский подход к современным методам обучения.</p>\r\n<p>Каждый участник сессии сможет поделиться своим опытом обучения в режиме реального времени, а приглашенные эксперты не только расскажут о своих личных историях обучения (подходах, привычках, успехах и неудачах), но и прокомментируют ответы зрителей.</p>",
        sessionReference: "*«Футурологический конгресс» — роман Станислава Лема,в котором происходит обсуждение самых последних достижений человечества, высказывание футурологических прогнозов и предложение новых изобретений на всеобщее обозрение.",
        badgeColor: "blue",
        badgeText: "Центр внедрения EdTech",
        moderator: {
            fullName: "Максим Киселев",
            title: "модератор",
            ...SPEAKERS_DATA.speaker_Kiselev
        },
        mediaLink: "https://vk.com/video_ext.php?oid=-184379939&id=456239471&hd=2",
        speakers: [
            {
                fullName: "Лоуренс Штайн",
                title: "спикер",
                ...SPEAKERS_DATA.speaker_Stein
            },
            {
                fullName: "Роберт Эйджи ",
                title: "спикер",
                ...SPEAKERS_DATA.speaker_Agee
            },
            {
                fullName: "Андрей Латышев",
                title: "спикер",
                ...SPEAKERS_DATA.speaker_Latvians
            },
            {
                fullName: "Константин Паршин",
                title: "спикер",
                ...SPEAKERS_DATA.speaker_Parshin
            },
            {
                fullName: "Анастасия Мануйлова",
                title: "спикер",
                ...SPEAKERS_DATA.speaker_Manuilova
            },
        ]
    },
]

const main_session: SessionDataType = {
    type: "discussion",
    streamingStatus: "notStarted",
    startTime: "10:00",
    endTime: "12:00",
    streamNum: 1,
    subtitle: "Дискуссия",
    sessionDescription: "<p>На главной сессии конференции обсудим 3 ключевых вопроса:</p>\r\n<ul>\r\n<li>Вызов технологий человеку: как сохранить способность думать и не допустить интеллектуальной деградации, сохранить мыслительные и креативные способности?</li>\r\n<li>Сотворчество технологий и человека: как гармонично объединить потенциал естественного и искусственного интеллектов? Какие процессы и задачи следует &laquo;отдать&raquo; алгоритмам, а какие сохранить за человеком?</li>\r\n<li>Кто он &laquo;человек будущего&raquo;? Как учить и учиться, чтобы стать человеком будущего уже сегодня.</li>\r\n</ul>",
    title: ["Естественный и искусственный разум: соперничество или сотрудничество"],
    moderator: {
        fullName: "Герман Греф",
        title: "модератор",
        ...SPEAKERS_DATA.speaker_HermanGref
    },
    mediaLink: "https://vk.com/video_ext.php?oid=-184379939&id=456239468&hd=2",
    speakers: [
        {
            fullName: "Александр Аузан",
            title: "спикер",
            ...SPEAKERS_DATA.speaker_Auzan
        },
        {
            fullName: "Александр Каплан",
            title: "спикер",
            ...SPEAKERS_DATA.speaker_Caplan
        },
        {
            fullName: "Сергей Лукьяненко",
            title: "спикер",
            ...SPEAKERS_DATA.speaker_Lukyanenko
        },
        {
            fullName: "Мария Ситковская",
            title: "спикер",
            ...SPEAKERS_DATA.speaker_Sitkovskaya
        },
    ]
}

export const PROGRAM: PROGRAM_TYPE = {
    onCampus: [
        {
            type: "opening",
            streamingStatus: "notStarted",
            startTime: "08:30",
            endTime: "10:00",
            subtitle: "Открытие",
            title: ["Регистрация очных участников", "Приветственный кофе", "Аудиоэкскурсии по территории СберУниверситета"]
        },
        main_session,
        {
            type: "coffee_break",
            streamingStatus: "notStarted",
            startTime: "12:00",
            endTime: "12:30",
            subtitle: "Кофе-брейк",
            title: ["Выставка EdTech-компаний и технологических сервисов Сбера", "Менторские консультации с методологами СберУниверситета"],
            link: "#",
        },
        ...multiple_sessions_01,
        {
            type: "lunch",
            streamingStatus: "notStarted",
            startTime: "14:00",
            endTime: "15:00",
            subtitle: "Обед",
            title: ["Выставки", "Активности на территории кампуса", "Менторские консультации"],
        },
        ...multiple_sessions_02,
        {
            type: "practice_01",
            streamingStatus: "notStarted",
            startTime: "15:00",
            endTime: "16:30",
            subtitle: "Практика",
            reference: "Сказки роботов",
            title: ["Как использовать генеративные модели в повседневной жизни и образовании"],
            sessionDescription: "<p>В современном мире, где технологический прогресс и цифровые инновации играют ключевую роль, мы постоянно сталкиваемся с новостями о появлении моделей для генерации текстов, изображений, презентаций и даже музыки. На мастер-классе обсудим, как именно эти технологии могут стать полезным инструментом для руководителей, преподавателей и студентов. Рассмотрим примеры, как генеративные модели могут быть интегрированы в учебный процесс для создания уникальных и креативных материалов.</p>\r\n<p>На мастер-классе попробуем ввести промпты в GigaChat, Kandinsky и ChatGPT. Это позволит вам на практике ощутить, как работают генеративные модели, и использовать их для решения разнообразных задач. </p>",
            sessionReference: "*Серия сатирико-философских рассказов Станислава Лема о роботах и их взаимодействии между собой в воображаемой псевдосредневековой вселенной.</p>",
            speakers: [
                {
                    fullName: "Дмитрий Зубцов",
                    title: "спикер",
                    ...SPEAKERS_DATA.speaker_Zubtsov
                },
                {
                    fullName: "Борис Шрайнер",
                    title: "спикер",
                    ...SPEAKERS_DATA.speaker_Shriner
                },
            ],
        },
        {
            type: "break",
            streamingStatus: "notStarted",
            startTime: "16:40",
            endTime: "17:00",
            subtitle: "Перерыв",
            title: ["Награждение победителей конкурса EdTech-компаний", "Выставки", "Менторские консультации"],

        },
        ...multiple_sessions_03,
        {
            type: "practice_02",
            streamingStatus: "notStarted",
            startTime: "17:00",
            endTime: "18:30",
            subtitle: "Практика",
            reference: "Капсула в барьере",
            title: ["Живое слово: как научиться находить, проживать и выражать смыслы в своих выступлениях"],
            sessionDescription: "<p>(по предварительной записи, 15 мест)</p><p>Наши слова способны навсегда изменить чье-то мнение о мире, определив поступки как в настоящем, так и в будущем. Но часто люди не чувствуют настоящей силы живой речи, а говорят механически, шаблонно или чрезмерно много в попытке придать больший вес сказанному. И это убивает слово, делает его пустым. Поэтому обучение живому слову – это обучение постижению, проживанию и выражению смысла.</p>\r\n<p>Участники практикума начнут оттачивать мастерство речи с его глубинного содержания, которое рождается внутри человека. Разберут, как осмыслить то, что важно сказать, и транслировать в выступлениях собственные идеи. А также — пройдут практику с тренером спикеров в небольшой группе из 15 человек (запись доступна только участникам очного формата через направленное письмо на электронную почту).</p>",
            sessionReference: "*Научно-фантастический роман Теодора Стерджена о группе людей на космическом корабле, которые пытаются преодолеть Барьер. Это материя, которую можно пролететь насквозь, но внутри Барьера любой объект превращается в антиматерию. Несмотря на фантастический сюжет, разгадка оказывается в искренних человеческих отношениях и способности верить. ",
            speakers: [
                {
                    fullName: "Елена Полякова",
                    title: "спикер",
                    ...SPEAKERS_DATA.speaker_Polyakova
                },

            ],
        },
        {
            type: "networking",
            streamingStatus: "notStarted",
            startTime: "18:30",
            endTime: "19:30",
            subtitle: "Нетворкинг",
            title: ["Время для общения, новых знакомств и обмена опытом"],
        },
        {
            type: "finish",
            streamingStatus: "notStarted",
            startTime: "18:45 19:00 19:15 19:30",
            endTime: "",
            title: ["Трансфер в Москву"],
        },
    ],

    online: [
        {
            type: "opening",
            streamingStatus: "notStarted",
            startTime: "09:30",
            endTime: "10:00",
            streamNum: 1,
            subtitle: "Открытие",
            title: ["Открытие онлайн-трансляции. Знакомство с планами на день"],
            mediaLink: "https://vk.com/video_ext.php?oid=-184379939&id=456239458&hd=2",
            speakers: [
                {
                    fullName: "Юлия Стрельцова",
                    title: "спикер",
                    ...SPEAKERS_DATA.speaker_Streltsova
                },
                {
                    fullName: "Максим Киселев",
                    title: "спикер",
                    ...SPEAKERS_DATA.speaker_Kiselev
                },
                {
                    fullName: "СберКот",
                    title: "спикер",
                    ...SPEAKERS_DATA.speaker_Cat
                },
            ]
        },
        main_session,
        {
            type: "coffee_break_01",
            streamingStatus: "notStarted",
            startTime: "12:00",
            endTime: "12:30",
            streamNum: 1,
            subtitle: "Кофе-брейк",
            title: ["Кофе-брейк с пользой: камерные беседы с представителями EdTech-компаний и экспертами"],
            mediaLink: "https://vk.com/video_ext.php?oid=-184379939&id=456239480&hd=2",
            speakers: [
                {
                    fullName: "Юлия Стрельцова",
                    title: "спикер",
                    ...SPEAKERS_DATA.speaker_Streltsova
                },
                {
                    fullName: "Максим Киселев",
                    title: "спикер",
                    ...SPEAKERS_DATA.speaker_Kiselev
                },
                {
                    fullName: "СберКот",
                    title: "спикер",
                    ...SPEAKERS_DATA.speaker_Cat
                },
                {
                    fullName: "Илья Чиркин",
                    title: "спикер",
                    ...SPEAKERS_DATA.speaker_Chirkin
                },
                {
                    fullName: "Виктория Сладких",
                    title: "спикер",
                    ...SPEAKERS_DATA.speaker_Sladkikh
                },
            ]
        },
        ...multiple_sessions_01,
        {
            type: "coffee_break_02",
            streamingStatus: "notStarted",
            startTime: "14:00",
            endTime: "15:00",
            streamNum: 1,
            subtitle: "Кофе-брейк",
            title: ["Кофе-брейк с пользой: камерные беседы с представителями EdTech-компаний и экспертами"],
            mediaLink: "https://vk.com/video_ext.php?oid=-184379939&id=456239459&hd=2",
            speakers: [
                {
                    fullName: "Юлия Стрельцова",
                    title: "спикер",
                    ...SPEAKERS_DATA.speaker_Streltsova
                },
                {
                    fullName: "Максим Киселев",
                    title: "спикер",
                    ...SPEAKERS_DATA.speaker_Kiselev
                },
                {
                    fullName: "СберКот",
                    title: "спикер",
                    ...SPEAKERS_DATA.speaker_Cat
                },
                {
                    fullName: "Василий Алексеев",
                    title: "спикер",
                    ...SPEAKERS_DATA.speaker_Alekseev
                },
                {
                    fullName: "Ася Фурсова",
                    title: "спикер",
                    ...SPEAKERS_DATA.speaker_Fursova
                },
                {
                    fullName: "Николай Писаренко",
                    title: "спикер",
                    ...SPEAKERS_DATA.speaker_Pisarenko
                },
            ]
        },
        ...multiple_sessions_02,
        {
            type: "coffee_break_03",
            streamingStatus: "notStarted",
            startTime: "16:30",
            endTime: "17:00",
            streamNum: 1,
            subtitle: "Кофе-брейк",
            title: ["Кофе-брейк с пользой: камерные беседы с победителями конкурса EdTech-компаний "],
            mediaLink: "https://vk.com/video_ext.php?oid=-184379939&id=456239460&hd=2",
            speakers: [
                {
                    fullName: "Юлия Стрельцова",
                    title: "спикер",
                    ...SPEAKERS_DATA.speaker_Streltsova
                },
                {
                    fullName: "Максим Киселев",
                    title: "спикер",
                    ...SPEAKERS_DATA.speaker_Kiselev
                },
                {
                    fullName: "СберКот",
                    title: "спикер",
                    ...SPEAKERS_DATA.speaker_Cat
                },
            ]
        },
        ...multiple_sessions_03,
        {
            type: "finish",
            streamingStatus: "notStarted",
            startTime: "18:30",
            endTime: "18:40",
            streamNum: 1,
            title: ["Завершение трансляции"],
            mediaLink: "https://vk.com/video_ext.php?oid=-184379939&id=456239462&hd=2",
            speakers: [
                {
                    fullName: "Юлия Стрельцова",
                    title: "спикер",
                    ...SPEAKERS_DATA.speaker_Streltsova
                },
                {
                    fullName: "Максим Киселев",
                    title: "спикер",
                    ...SPEAKERS_DATA.speaker_Kiselev
                },
                {
                    fullName: "СберКот",
                    title: "спикер",
                    ...SPEAKERS_DATA.speaker_Cat
                },
            ]
        },
    ]
}