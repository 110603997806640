import { AuthProvider } from "react-auth-kit";
import { Navigate, Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import * as Sentry from "@sentry/react";

import routes from "@/routes";

import ProfileLayout from "@/layouts/ProfileLayout/";
import RootLayout from "@/layouts/RootLayout";

import Conference from "@/pages/Conference";
import Competition from "@/pages/Competition";
import SignIn from "@/pages/SignIn";
import SignUp from "@/pages/SignUp";
import ResetPassword from "@/pages/ResetPassword";
import SignInBox from "@/pages/SignIn/SignInBox";
import ForgetPassword from "@/pages/SignIn/ForgetPassword";
import Profile from "@pages/Profile";
import ChangePassword from "@pages/ChangePassword";
import Refund from "@pages/Refund";
import Certificates from "@pages/Certificates";
import StudyOnline from "@pages/SignUp/StudyOnline";
import StudyOnCampus from "@/pages/SignUp/StudyOnCampus";
import VipAccount from "@pages/SignUp/VipAccount";
import Tariffs from "@/pages/Tariffs";
import FromEducation from "@pages/SignUp/FromEducation";
import Broadcast from "@/pages/Broadcast";
import FromSber from "@pages/SignUp/FromSber";
import { AxiosToken } from "@/providers/AxiosToken";
import { UserProvider } from "@/context/UserContext";
import { NavProvider } from "@/context/NavContext";
import { BroadcastProvider } from "@/context/BroadcastContext";
import { ScheduleProvider } from "@/context/ScheduleContext";
import ProtectedRoute from "@components/ProtectedRoute";
import ModeratorRoute from "@components/ModeratorRoute";
import { RequireNotAuth } from "@/components/RequireNotAuth";
import { TariffCommon } from "@pages/ChangeTariff/TariffCommon";
import NotFound from "@pages/NotFound";
import { TariffEducation } from "@pages/ChangeTariff/TariffEducation";
import { TariffSber } from "@pages/ChangeTariff/TariffSber";
import { ChangeTariff } from "@pages/ChangeTariff";
import CommenChatModeration from "@pages/ChatModeration/CommenChatModeration";
import QuestionsModeration from "@pages/ChatModeration/QuestionsModeration";
import ModeratedQuestions from "@pages/ModeratedQuestions";
import "./index.scss";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<RootLayout />}>
            <Route index element={<Conference />} />

            <Route path={routes.contest} element={<Competition />} />
            {/* Роут демо трансляции VK через iframe */}
            <Route path={routes.broadcast} element={<Broadcast />} />

            <Route element={<RequireNotAuth redirectTo={routes.profile} />}>
                <Route path={routes.signin} element={<SignIn />}>
                    <Route index element={<SignInBox />} />
                    <Route path={routes.forgetPassword} element={<ForgetPassword />} />
                </Route>

                <Route path={routes.resetPassword} element={<ResetPassword />} />
            </Route>

            {/* removed and closed after 25.10.2023 */}
            {/* <Route path={routes.signup} element={<SignUp />}>
                <Route index element={<Navigate to={routes.onlineSignup} replace />} /> */}
            {/* without payment*/}
            {/* <Route element={<RequireNotAuth redirectTo={routes.profile} />}>
                    <Route path={routes.onlineSignup} element={<StudyOnline />} />
                    <Route path={routes.fromSberSignup} element={<FromSber />} />
                    <Route path={routes.vipSignup} element={<VipAccount />} />
                </Route> */}
            {/* with payment */}
            {/* <Route path={routes.onCampusSignup} element={<StudyOnCampus />} />
                <Route path={routes.fromEducationSignup} element={<FromEducation />} />
            </Route> */}

            <Route element={<ProtectedRoute loginPath={routes.signin} />}>
                <Route element={<ProfileLayout />}>
                    <Route path={routes.profile} element={<Profile />} />
                    <Route path={routes.profileChangePassword} element={<ChangePassword />} />
                    <Route path={routes.profileTariffs} element={<Tariffs />} />
                    <Route path={routes.profileRefund} element={<Refund />} />
                    <Route path={routes.profileCertificates} element={<Certificates />} />
                </Route>
                <Route element={<ChangeTariff />}>
                    <Route path={routes.profileChangeTariffCommon} element={<TariffCommon />} />
                    <Route path={routes.profileChangeTariffEducation} element={<TariffEducation />} />
                    <Route path={routes.profileChangeTariffSber} element={<TariffSber />} />
                </Route>
            </Route>

            <Route element={<ModeratorRoute />}>
                <Route index path={routes.questionsModeration} element={<QuestionsModeration />} />
                <Route path={routes.chatModeration} element={<CommenChatModeration />} />
            </Route>

            <Route path={routes.moderatedQuestions} element={<ModeratedQuestions />} />

            <Route path="*" element={<NotFound />} />
        </Route>,
    ),
    {
        basename: process.env.PUBLIC_URL,
    },
);

const App = () => {
    return (
        <div className="App">
            <AuthProvider authType={"localstorage"} authName={"_auth"}>
                <AxiosToken>
                    <QueryClientProvider client={queryClient}>
                        <UserProvider>
                            <NavProvider>
                                <ScheduleProvider>
                                    <BroadcastProvider>
                                        <RouterProvider router={router} />
                                    </BroadcastProvider>
                                </ScheduleProvider>
                            </NavProvider>
                        </UserProvider>
                    </QueryClientProvider>
                </AxiosToken>
            </AuthProvider>
        </div>
    );
};

export default App;
