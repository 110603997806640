import { useLayoutEffect } from "react";
import { useAuthHeader } from "react-auth-kit";

import { clientApi } from "@/api/client";

export const AxiosToken = ({ children }) => {
    const authHeaders = useAuthHeader();

    const token = authHeaders();

    useLayoutEffect(() => {
        const idIntercept = clientApi.interceptors.request.use(function (config) {
            const token = authHeaders();

            if (token) {
                config.headers["X-Auth-Token"] = token.split(" ")[1];
            }

            return config;
        });
        return () => {
            clientApi.interceptors.request.eject(idIntercept);
        };
    }, [token]);

    return <>{children}</>;
};
