import { useState } from "react";
import { useFormik } from "formik";

import { checkPromoCode } from "@/api";
import { promoCodeSchema } from "@/Schema/signUpSchema";
import RegistrationInput from "@/components/RegistrationInput";
import Button from "@/components/Button";
import Tooltip from "@/components/Tooltip";
import { toastNotifications } from "@/components/Toastify";
import { FormikErrorWrap } from "@/components/FormikErrorWrap";
import { hasError } from "@/utils/formik";
import AttemptsTimeout from "./AttemptsTimeout";
import "./index.scss";

export type PromoCodeProps = {
    tariff: string;
    onAcceptCode: (code: string) => void;
};

const PromoCode = ({ tariff, onAcceptCode }: PromoCodeProps) => {
    const [attempts, setAttempts] = useState(0);

    const formik = useFormik({
        initialValues: {
            promoCode: "",
        },
        validateOnMount: true,
        validationSchema: promoCodeSchema,
        onSubmit: async (values, formikHelpers) => {
            try {
                const data = {
                    tariff: tariff,
                    code: values.promoCode,
                };
                const response = await checkPromoCode(data);
                const resDoc = response.data;

                if (resDoc.success && resDoc.data.is_used === 0) {
                    onAcceptCode(resDoc.data.id);
                } else if (resDoc.success && resDoc.data.is_used > 0) {
                    toastNotifications.error("Промокод уже был использован!");
                    formikHelpers.resetForm();
                }
            } catch {
                toastNotifications.error("Неправильный промокод!");
                setAttempts((prev) => prev + 1);
            }
        },
    });

    const handleCompleteTimeout = () => {
        setAttempts(0);
    };

    return (
        <>
            {attempts > 5 ? (
                <AttemptsTimeout onComplete={handleCompleteTimeout} />
            ) : (
                <form onSubmit={formik.handleSubmit} className="PromoCode" autoComplete="off">
                    <FormikErrorWrap formik={formik} name="promoCode">
                        <RegistrationInput
                            className="PromoCode__Input"
                            type="text"
                            id="promoCode"
                            name="promoCode"
                            placeholder="Введите код"
                            label="Промокод"
                            error={hasError(formik, "promoCode")}
                            value={formik.values.promoCode}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                    </FormikErrorWrap>

                    <Tooltip
                        className="PromoCode__Tooltip"
                        text={"Необходимо заполнить все поля"}
                        diraction="top"
                        disabled={formik.isValid}
                    >
                        <Button
                            className="PromoCode__Btn"
                            tag="button"
                            BtnType="submit"
                            // onClick={nextStep}
                            text="Далее"
                            size="lg"
                            type="colored"
                            isWide
                            gradient
                            disabled={!formik.isValid || formik.isSubmitting}
                        />
                    </Tooltip>
                </form>
            )}
        </>
    );
};

export default PromoCode;
