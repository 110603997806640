import React from 'react';
import './index.scss';
import classNames from "classnames";

const UnderLiner = ({
    children,
    className,
}) => {
    return (
        <span className={classNames({
            'UnderLiner': true,
            [className]: className
        })}>
            {children}
        </span>
    );
};

export default UnderLiner;