import Popup from "@/components/Popup";
import { useToggle } from "react-use";
import "./index.scss";

type Props = {
    srcPng: string,
    srcWebp: string,
    onClick?: () => void;
}

const altText = "Атмосфера мероприятия"

const PhotoCard: React.FC<Props> = ({ srcPng, srcWebp, onClick }) => {
    return (
        <>
            <picture>
                <source srcSet={srcWebp} type="image/webp" />
                <img
                    className="PhotoCard__Img"
                    src={srcPng}
                    alt={altText}
                    loading="lazy"
                    onClick={onClick}
                />
            </picture>
        </>
    )
}

export default PhotoCard;