import ErrorIcon from "./assets/error-icon.png";
import "./index.scss";

const LiveChatFallback = () => {

    return (
        <div className='LiveChatFallback'>
            <img src={ErrorIcon} alt="error" width={50} height={50} />
            <p>Что-то пошло не так.</p>
            <p>Пожалуйста, перезагрузите страницу.</p>
        </div>
    );
}

export default LiveChatFallback;
