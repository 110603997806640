import Button from "@components/Button";
import Headline from "@components/Headline";
import StarIcon01 from './icons/start01.png';
import StarIcon02 from './icons/start02.png';
import Dekor from './icons/dekor.png';
import { ReactComponent as Shape } from './icons/shape.svg';
import './index.scss';

const titleText = "Критерии, по которым\nэксперты оценивали продукты"
const EvaluateProjects = () => {
    return (
        <section className="EvaluateProjects">
                <img className="EvaluateProjects__Decoration04" src={StarIcon01} alt="star icon" />
                <img className="EvaluateProjects__Decoration05" src={StarIcon02} alt="star icon" />
                <img className="EvaluateProjects__Decoration06" src={Dekor} alt="star icon" />

                <Headline className="EvaluateProjects__Title">{titleText}</Headline>
                <ul className="EvaluateProjects__List">
                    <li className="EvaluateProjects__List-Item EvaluateProjects__List-Item--1">Результативность</li>
                    <li className="EvaluateProjects__List-Item EvaluateProjects__List-Item--1">Инновационность</li>
                    <li className="EvaluateProjects__List-Item EvaluateProjects__List-Item--1">Востребованность</li>
                    <li className="EvaluateProjects__List-Item EvaluateProjects__List-Item--1">Удобство использования</li>
                    <li className="EvaluateProjects__List-Item EvaluateProjects__List-Item--1">Масштабируемость</li>
                </ul>
                <div className="EvaluateProjects__Action">
                    <Button
                        className="EvaluateProjects__Button"
                        text="Узнать больше о критериях"
                        icon={<Shape />}
                        tag='a'
                        href="https://media.sberuniversity.online/media-link/public/RZMjZ9pQOor9YwXqSKpJQ1nB5w7Z_q_Ex5uBtzRFAKsoEwSKrvqYnvuHl-s="
                        target="_blank"
                        gradient
                    />
                    <p className="EvaluateProjects__Action-text">Изучите подробные критерии, чтобы понимать, <br /> что было важно указать в заявке на участие</p>
                </div>
        </section>
    )
}

export default EvaluateProjects;