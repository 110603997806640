import React from "react";
import classNames from "classnames";

import { ReactComponent as IconInfo } from "./ic_filled_round_speech_bubble_info_fill.svg";

import "./index.scss";

export const FullTimePlanInfo: React.FC<
    React.PropsWithChildren<{
        className?: string;
    }>
> = ({ className, children }) => {
    return (
        <div className={classNames(className, "FullTimePlanInfo")}>
            <div className="FullTimePlanInfo__Icon">
                <IconInfo />
            </div>
            <div className="FullTimePlanInfo__Text">{children}</div>
        </div>
    );
};
