import {useInfiniteQuery} from 'react-query';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';

import { getExperts } from '@/api';
import Button from "@components/Button";
import Headline from "@components/Headline";
import useMedia from "@/hooks/useMedia";
import Container from '@/components/Container';
import ExpertCard from './components/ExpertCard';
import {ReactComponent as PlusIcon} from './icons/plus-icon.svg';
import './index.scss';

const fetchExpertsData = async ({pageParam = 1}) => {
    const response = await getExperts({ page: pageParam });
    return response.data;
};

const swiperParams = {
    slidesOffsetBefore: 8,
    spaceBetween: 14,
    slidesPerView: 1.2,
    centeredSlides: true,
    centeredSlidesBounds: true,
};

const Experts = () => {
    const {isMobile} = useMedia();

    const {
        data,
        isLoading,
        isFetchingNextPage,
        fetchNextPage,
        hasNextPage
    } = useInfiniteQuery(['experts'], fetchExpertsData, {
        getNextPageParam: (lastPage) => {
            if (lastPage.meta.current_page < lastPage.meta.last_page) {
                return lastPage.meta.current_page + 1;
            } else {
                return undefined;
            }
        },
    });

    const experts = data?.pages.map(page => page.data).flat();

    const renderCards = () => experts?.map((el, index) => {
        const card = (
            <ExpertCard
                key={index}
                photo_url={el.photo_url}
                name={el.name}
                surname={el.surname}
                description={el.description}
            />
        )
        if (isMobile) {
            return (
                <SwiperSlide key={index}>
                    {card}
                </SwiperSlide>
            )
        }
        return card
    });

    return (
        <section className="Experts">
            <Container className="Experts__Container">
                <Headline className="Experts__Title">Эксперты конкурса</Headline>
                {isMobile ?
                    <>{!isLoading &&
                        <Swiper
                            {...swiperParams}
                            onSlideChange={(swiper) => {
                                if (experts && swiper.realIndex > experts.length - 3 && hasNextPage && !isFetchingNextPage) {
                                    fetchNextPage()
                                }
                            }}
                        >
                            {renderCards()}
                        </Swiper>}
                    </>
                    :
                    <>
                        <div className='Experts__cards'>
                            {renderCards()}
                        </div>
                        {hasNextPage && (
                            <Button
                                className="Experts__Button"
                                text="Показать еще"
                                icon={<PlusIcon />}
                                tag='button'
                                onClick={fetchNextPage}
                                disabled={isFetchingNextPage}
                            />
                        )}
                    </>
                }
            </Container>
        </section>
    )
};

export default Experts;