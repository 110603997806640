import { NavLink } from 'react-router-dom';
import { HashLink } from "react-router-hash-link";
import { ReactComponent as InfoIcon } from "./assets/info-icon.svg";
import routes from '@/routes';
import { UserStatus } from '@/types/chatTypes';
import './index.scss';

type Props = {
    userStatus: UserStatus
}

const InputWarnings: React.FC<Props> = ({
    userStatus
}) => {
    return (
        <div className="InputWarnings__NotAuth">
            <InfoIcon />
            {userStatus === "notAuth" ?
                <p className="InputWarnings__NotAuth-Text">
                    <NavLink className="InputWarnings__NotAuth-link" to={routes.signin}>Войдите</NavLink> или&nbsp;
                    <NavLink className="InputWarnings__NotAuth-link" to={routes.onlineSignup}>
                        зарегистрируйтесь
                    </NavLink>, чтобы написать сообщение.
                </p>
                :
                <p className="InputWarnings__Blocked">Ваш аккаунт заблокирован, вы больше не можете писать в чат.</p>
            }
        </div>
    )
}

export default InputWarnings;