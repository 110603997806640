import React from "react";

import { signUpOnCampusSchema } from "@/Schema/signUpSchema";
import useStepper from "@/hooks/useStepper";
import AuthorizationFormHeader from "@/components/AuthorizationFormHeader";
import RegistrationPrevBtn from "@/components/RegistrationPrevBtn";

import BasicInformation from "../common/BasicInformation";
import ActivityInformation from "../common/ActivityInformation";
import TransportInformation from "../common/TransportInformation";
import AccommodationChoice from "../common/AccommodationChoice";
import CreatePassword from "../common/CreatePassword";
import LocationInformation from "../common/LocationInformation";
import FormStepper from "../common/FormStepper";
import TicketPayment from "../common/TicketPayment";
import FormBadge from "../common/FormBadge";

import "./index.scss";
import { openPaymentAcquire, useRegistrations } from "@/pages/SignUp/registrations";
import routes from "@/routes";
import { RequireNotAuth } from "@/components/RequireNotAuth";
import { toastNotifications } from "@/components/Toastify";

export const Steps = {
    BASE_INFO: 1,
    LOCATION_INFO: 2,
    ACTIVITY_INFO: 3,
    TRANSPORT_INFO: 4,
    ACCOMODATION_INFO: 5,
    PASSWORD_INFO: 6,
    PAYMENT_INFO: 7,
};

export const STEPS_CHAIN = [
    Steps.BASE_INFO,
    Steps.LOCATION_INFO,
    Steps.ACTIVITY_INFO,
    Steps.TRANSPORT_INFO,
    Steps.ACCOMODATION_INFO,
    Steps.PASSWORD_INFO,
    Steps.PAYMENT_INFO,
];

export const formHeaderTitles = {
    [Steps.BASE_INFO]: "Создайте свой профиль для\nпосещения конференции",
    [Steps.LOCATION_INFO]: "Укажите своё\nместонахождение",
    [Steps.ACTIVITY_INFO]: "Укажите свой\nрод деятельности",
    [Steps.TRANSPORT_INFO]: "Информация о вашем\nтранспорте",
    [Steps.ACCOMODATION_INFO]: "Планируете ли вы\nостаться в гостинице\nСберУниверситета?",
    [Steps.PASSWORD_INFO]: "Придумайте пароль\nдля вашего аккаунта",
    [Steps.PAYMENT_INFO]: "Выберите тип оплаты\nбилета",
};

const StudyOnCampus = () => {
    const formik = useRegistrations({
        values: {
            format: "offline",
            tariff: "common",
        },
        validationScheme: signUpOnCampusSchema,
        onRegistered: async (values) => {
            if (values.legal_type === "individual") {
                await openPaymentAcquire();
            } else {
                toastNotifications.payment("accepted");
            }
        },
    });

    const { currStep, nextStep, prevStep } = useStepper(STEPS_CHAIN);
    const showSubtitle = Boolean(currStep === Steps.BASE_INFO);
    const title = formHeaderTitles[currStep];

    return (
        <>
            {(currStep !== Steps.PAYMENT_INFO || formik.values.legal_type === "legal") && (
                <RequireNotAuth redirectTo={routes.profile} />
            )}

            {currStep !== Steps.BASE_INFO && <RegistrationPrevBtn toPrev={prevStep} />}

            {/* @ts-ignore */}
            <FormBadge text="Очное участие" />

            {/* @ts-ignore */}
            <AuthorizationFormHeader
                className="StudyOnCampus__Header"
                title={title}
                subtitle={showSubtitle && "Здравствуйте!"}
                subtitleOnTop
            />

            {currStep === Steps.ACCOMODATION_INFO && (
                <p className="StudyOnCampus__Accommodation-note">Проживание в кампусе оплачивается дополнительно</p>
            )}

            {/* @ts-ignore */}
            <FormStepper steps={6} startFrom={1} currentStep={currStep} />

            <form className="StudyOnCampus__Form" autoComplete="off" onSubmit={formik.handleSubmit}>
                {currStep === Steps.BASE_INFO && (
                    <BasicInformation formik={formik} withPhoneNumber nextStep={nextStep} />
                )}

                {currStep === Steps.LOCATION_INFO && <LocationInformation formik={formik} nextStep={nextStep} />}

                {currStep === Steps.ACTIVITY_INFO && <ActivityInformation formik={formik} nextStep={nextStep} />}

                {currStep === Steps.TRANSPORT_INFO && <TransportInformation formik={formik} nextStep={nextStep} />}

                {currStep === Steps.ACCOMODATION_INFO && <AccommodationChoice formik={formik} nextStep={nextStep} />}

                {currStep === Steps.PASSWORD_INFO && <CreatePassword formik={formik} nextStep={nextStep} />}

                {currStep === Steps.PAYMENT_INFO && <TicketPayment formik={formik} />}
            </form>
        </>
    );
};

export default StudyOnCampus;
