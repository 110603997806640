import { useState } from "react"
import { Blurhash } from "react-blurhash";
import "./index.scss";
import classNames from "classnames";

type Props = {
    className?: string;
    videoLink: string;
    isWithHash?: boolean;
}

const VideoPlayer: React.FC<Props> = ({ videoLink, isWithHash = true, className }) => {
    const [isLoading, setIsloading] = useState(true);

    const handleLoad = () => {
        setTimeout(() => {
            setIsloading(false)
        }, 200);
    }

    return (
        <div className={classNames('VideoPlayer', className)}>
            {isLoading && isWithHash && < Blurhash hash="LEHLk~WB2yk8pyo0adR*.7kCMdnj" width={"100%"} height={"100%"} />}
            <iframe
                className="VideoPlayer__Video"
                src={videoLink}
                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                frameBorder="0"
                onLoad={handleLoad}
            />
        </div>
    )
}

export default VideoPlayer;