import { useEffect, useState } from "react";

const useAnimate = () => {
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => setAnimate(true), 100);
        return () => clearInterval(timer);
    }, [])

    return [animate];
}

export default useAnimate;