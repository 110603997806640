import { NavLink } from "react-router-dom";
import { ReactComponent as IconArrow } from "./icons/arrow.svg";
import { ReactComponent as IconGoOut } from "./icons/go-out.svg";
import { routesNavigationProfile } from "@/constants/navigations";
import { useSignOut } from "react-auth-kit";
import useDownloadCertificate from "@/hooks/useDownloadCertificate";
import classNames from "classnames";
import "./index.scss";


const NavbarList: React.FC = () => {
    const signOut = useSignOut();
    const { isDownloadActive, showWarning } = useDownloadCertificate();

    const renderNav = routesNavigationProfile.map((link) => {
        const isActive = link.navType === "certificates" ? isDownloadActive : true;
        const TagElement = isActive ? NavLink : "span";

        return (
            <li className="NavbarList__Item" key={link.id}>
                <TagElement
                    className={classNames({
                        "NavbarList__Link": true,
                        "NavbarList__Link--disabled": !isActive,
                    })}
                    to={link.route}
                    end={isActive ? true : undefined}
                    onClick={isActive ? undefined : showWarning}
                >
                    <div className="NavbarList__LinkIcon">{<link.icon />}</div>
                    <span>{link.label}</span>
                    <div className="NavbarList__LinkIconArrow">
                        <IconArrow />
                    </div>
                </TagElement>
            </li >
        );
    })

    return (
        <>
            <ul className="NavbarList">
                {renderNav}
            </ul>
            <button
                className="NavbarList__Link NavbarList__Link--Logout"
                onClick={() => {
                    signOut();
                }}
            >
                <div className="NavbarList__LinkIcon">
                    <IconGoOut />
                </div>
                <span>Выйти</span>
            </button>
        </>
    );
};

export default NavbarList;