import useDownloadCertificate from "@/hooks/useDownloadCertificate";
import { ReactComponent as CertificateIcon } from "./assets/certificate-icon.svg";
import { ReactComponent as DownloadIcon } from "./assets/download-icon.svg";
import Loader from "@/components/Loader";
import classNames from "classnames";
import "./index.scss";

const DownloadCertificate: React.FC = () => {
    const { downloadCertificate, isDownloading } = useDownloadCertificate();

    return (
        <div className="DownloadCertificate">
            <CertificateIcon />
            <div className="DownloadCertificate__Body">
                <span className="DownloadCertificate__Text">Сертификат об участии</span>
                <button
                    className={classNames({
                        "DownloadCertificate__Btn": true,
                        "DownloadCertificate__Btn--disabled": isDownloading,
                    })}
                    onClick={downloadCertificate}
                    disabled={isDownloading}
                >
                    {isDownloading ? <Loader size={20} /> : <DownloadIcon />}
                </button>
            </div>
        </div>
    )
}

export default DownloadCertificate;