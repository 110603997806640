import React, { useEffect, PropsWithChildren } from "react";
import { useLocation } from "react-router";

import { hashLinkScroll } from "./hashLinkScroll";

export type ScrollToViewProps = {};

export const ScrollToView: React.FC<PropsWithChildren> = ({ children }) => {
    const location = useLocation();

    useEffect(() => {
        const { hash } = location;
        if (hash) {
            hashLinkScroll(hash);
        }
    }, [location]);

    return <>{children}</>;
};

export default ScrollToView;
