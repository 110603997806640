import React, { useMemo } from "react";
import { useFormik } from "formik";

import { toastNotifications } from "@/components/Toastify";
import TitlePageAdmin from "@/components/TitlePageAdmin";
import { profileSchema } from "@/Schema/profileSchema";
import RegistrationInput from "@/components/RegistrationInput";
import Typography from "@/components/Typography";
import Checkbox from "@/components/Checkbox";
import Button from "@/components/Button";
import Tooltip from "@/components/Tooltip";
import { useUser } from "@/context/UserContext";
import { hasError } from "@/utils/formik";
import { FormikErrorWrap } from "@/components/FormikErrorWrap";

import "./index.scss";
import { ActivityType } from "@/types/registration";
import { AvatarInput } from "@/components/AvatarInput";
import { updateUserInformations } from "@/api";
import InputSelectCountries from "@/components/InputSelectCountries";
import InputSelectCity from "@/components/InputSelectCity";
import { useFormikDebounceEmail } from "@/hooks/useFormikDebounceEmail";
import { useActivityControl } from "@/pages/SignUp/registrations";
import { TARIFF_WITH_REQUIRED_PHONE, TARIFF_WITH_REQUIRED_TRANSPORT } from "@/constants/validations";
import PhoneInput from "@/components/PhoneInput";
import Radio from "@/components/Radio";
import { ProfilePaymentInfo } from "./ProfilePaymentInfo";
import { Helmet } from "react-helmet";

const Profile = () => {
    const { userInfo, setUserInfo } = useUser();

    const initialValues = useMemo(() => {
        const { name, surname, email, info } = userInfo ?? {};
        const {
            country_id,
            city,
            tariff,
            format,
            place_of_work,
            position,
            place_of_study,
            speciality,
            phone,
            transport,
            car_brand,
            car_state_number,
        } = info ?? {};
        return {
            tariff: tariff ?? "",
            format: format ?? "",
            name: name ?? "",
            surname: surname ?? "",
            avatar: null as File | null,
            email: email ?? "",
            phone: phone ?? "",
            country_id: country_id ?? "",
            city: city ?? "",
            place_of_work: place_of_work ?? "",
            position: position ?? "",
            place_of_study: place_of_study ?? "",
            speciality: speciality ?? "",
            transport: transport ?? "",
            car_brand: car_brand ?? "",
            car_state_number: car_state_number ?? "",
            activity: [
                ...(place_of_work ? ["work"] : []),
                ...(place_of_study ? ["study"] : []),
                ...(!place_of_work && !place_of_study ? ["no_activity"] : []),
            ] as Array<ActivityType>,
        };
    }, [userInfo]);

    const validationSchema = useMemo(() => {
        return profileSchema(initialValues);
    }, [initialValues]);

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validateOnMount: true,
        validationSchema,
        onSubmit: async (formValues) => {
            try {
                const { activity, ...restFormValues } = formValues;
                const newUserData = await updateUserInformations(restFormValues);
                setUserInfo(newUserData);
                toastNotifications.info("Изменения сохранены");
            } catch (e) {
                toastNotifications.error(`Ошибка!`);
            }
        },
    });

    useActivityControl(formik);

    const [email, setEmail] = useFormikDebounceEmail(formik);

    return (
        <>
            <Helmet>
                <title>Персональная информация | СберУниверситет</title>
                <meta name="description" content="Персональная информация" />
            </Helmet>
            <form className="Profile" autoComplete="off" onSubmit={formik.handleSubmit}>
                <fieldset disabled={formik.isSubmitting}>
                    <div className="Profile__FormWrapBlock Profile__FormWrapBlock--Head">
                        <TitlePageAdmin textDesktop="Персональная информация" textMobile="Мои данные" />
                        <ProfilePaymentInfo />
                        <div className="Profile__FormRowBlock">
                            <AvatarInput
                                src={userInfo?.avatar_url}
                                userName={`${initialValues.surname} ${initialValues.name}`}
                                onChange={(file) => {
                                    formik.setFieldValue("avatar", file);
                                }}
                            />
                        </div>
                    </div>
                    <div className="Profile__FormWrapBlock">
                        <Typography className="Profile__TitleLabel" color="dark" size="sm" isBold>
                            Имя и фамилия
                        </Typography>
                        <div className="Profile__InputWrap">
                            <FormikErrorWrap formik={formik} name="surname">
                                <RegistrationInput
                                    className="Profile__Input"
                                    label="Фамилия"
                                    name="surname"
                                    value={formik.values.surname}
                                    error={hasError(formik, "surname")}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                />
                            </FormikErrorWrap>
                        </div>
                        <div className="Profile__InputWrap">
                            <FormikErrorWrap formik={formik} name="name">
                                <RegistrationInput
                                    className="Profile__Input Profile__Input--LastInput"
                                    label="Имя"
                                    name="name"
                                    value={formik.values.name}
                                    error={hasError(formik, "name")}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </FormikErrorWrap>
                        </div>
                    </div>
                    <div className="Profile__FormWrapBlock">
                        <Typography className="Profile__TitleLabel" color="dark" size="sm" isBold>
                            Контакты
                        </Typography>
                        <div className="Profile__InputWrap">
                            <FormikErrorWrap formik={formik} name="email">
                                <RegistrationInput
                                    className="Profile__Input"
                                    name="email"
                                    value={email}
                                    disabled
                                    label="Email"
                                    error={hasError(formik, "email")}
                                    onChange={(e) => setEmail(e.target.value)}
                                    onBlur={formik.handleBlur}
                                />
                            </FormikErrorWrap>
                        </div>
                        {TARIFF_WITH_REQUIRED_PHONE.includes(formik.values.tariff as any) && (
                            <div className="Profile__InputWrap">
                                <FormikErrorWrap formik={formik} name="phone">
                                    <PhoneInput
                                        className="Profile__Input"
                                        id="phone"
                                        name="phone"
                                        label="Телефон"
                                        placeholder="Введите номер телефона"
                                        value={formik.values.phone}
                                        error={hasError(formik, "phone")}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </FormikErrorWrap>
                            </div>
                        )}
                    </div>
                    <div className="Profile__FormWrapBlock">
                        <Typography className="Profile__TitleLabel" color="dark" size="sm" isBold>
                            Местонахождение
                        </Typography>
                        <div className="Profile__InputWrap">
                            <FormikErrorWrap formik={formik} name="country_id">
                                <InputSelectCountries
                                    className="Profile__Input"
                                    id="country_id"
                                    name="country_id"
                                    placeholder="Введите свою страну"
                                    label="Страна"
                                    value={formik.values.country_id}
                                    error={hasError(formik, "country_id")}
                                    onSelect={(country_id) => formik.setFieldValue("country_id", country_id)}
                                    onBlur={() => formik.setFieldTouched("country_id")}
                                />
                            </FormikErrorWrap>
                        </div>
                        <div className="Profile__InputWrap">
                            <FormikErrorWrap formik={formik} name="city">
                                <InputSelectCity
                                    className="Profile__Input"
                                    id="city"
                                    name="city"
                                    placeholder="Введите свой город"
                                    label="Город"
                                    error={hasError(formik, "city")}
                                    value={formik.values.city}
                                    countryId={formik.values.country_id}
                                    onSelect={(city) => formik.setFieldValue("city", city)}
                                    onBlur={() => formik.setFieldTouched("city")}
                                />
                            </FormikErrorWrap>
                        </div>
                    </div>
                    <div className="Profile__FormWrapBlock">
                        <Typography className="Profile__TitleLabel" color="dark" size="sm" isBold>
                            Род деятельность
                        </Typography>

                        <FormikErrorWrap formik={formik} name="activity">
                            <div className="ProfileBlockCheckbox">
                                <Checkbox
                                    text="Работаю"
                                    id="work"
                                    name="activity"
                                    value="work"
                                    checked={formik.values.activity.includes("work")}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                            <div className="ProfileBlockCheckbox">
                                <Checkbox
                                    text="Учусь"
                                    id="study"
                                    name="activity"
                                    value="study"
                                    checked={formik.values.activity.includes("study")}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                            <div className="ProfileBlockCheckbox">
                                <Checkbox
                                    text="Не работаю и не учусь"
                                    id="noactivity"
                                    name="activity"
                                    value="no_activity"
                                    checked={formik.values.activity.includes("no_activity")}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                        </FormikErrorWrap>

                        {formik.values.activity.includes("work") && (
                            <>
                                <div className="Profile__InputWrap">
                                    <FormikErrorWrap formik={formik} name="place_of_work">
                                        <RegistrationInput
                                            className="Profile__Input"
                                            name="place_of_work"
                                            label="Место работы"
                                            value={formik.values.place_of_work}
                                            error={hasError(formik, "place_of_work")}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                        />
                                    </FormikErrorWrap>
                                </div>
                                <div className="Profile__InputWrap">
                                    <FormikErrorWrap formik={formik} name="position">
                                        <RegistrationInput
                                            className="Profile__Input"
                                            label="Должность"
                                            name="position"
                                            value={formik.values.position}
                                            error={hasError(formik, "position")}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                        />
                                    </FormikErrorWrap>
                                </div>
                            </>
                        )}

                        {formik.values.activity.includes("study") && (
                            <>
                                <div className="Profile__InputWrap">
                                    <FormikErrorWrap formik={formik} name="place_of_study">
                                        <RegistrationInput
                                            className="Profile__Input"
                                            name="place_of_study"
                                            label="Место учебы"
                                            value={formik.values.place_of_study}
                                            error={hasError(formik, "place_of_study")}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                        />
                                    </FormikErrorWrap>
                                </div>
                                <div className="Profile__InputWrap">
                                    <FormikErrorWrap formik={formik} name="speciality">
                                        <RegistrationInput
                                            className="Profile__Input"
                                            label="Специальность"
                                            name="speciality"
                                            value={formik.values.speciality}
                                            error={hasError(formik, "speciality")}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                        />
                                    </FormikErrorWrap>
                                </div>
                            </>
                        )}
                    </div>
                    {TARIFF_WITH_REQUIRED_TRANSPORT.includes(formik.values.tariff as any) && (
                        <div className="Profile__FormWrapBlock">
                            <Typography className="Profile__TitleLabel" color="dark" size="sm" isBold>
                                Транспорт
                            </Typography>

                            <FormikErrorWrap formik={formik} name="transport">
                                <div className="ProfileBlockCheckbox">
                                    <Radio
                                        className="Profile__Radio"
                                        id="car"
                                        name="transport"
                                        text="Автомобиль"
                                        value="car"
                                        checked={formik.values.transport === "car"}
                                        onChange={formik.handleChange}
                                    />
                                </div>
                                <div className="ProfileBlockCheckbox">
                                    <Radio
                                        className="Profile__Radio"
                                        id="taxi"
                                        name="transport"
                                        text="Такси"
                                        value="taxi"
                                        checked={formik.values.transport === "taxi"}
                                        onChange={formik.handleChange}
                                    />
                                </div>
                                <div className="ProfileBlockCheckbox">
                                    <Radio
                                        className="Profile__Radio"
                                        id="transfer"
                                        name="transport"
                                        text="Трансфер"
                                        value="transfer"
                                        checked={formik.values.transport === "transfer"}
                                        onChange={formik.handleChange}
                                    />
                                </div>
                            </FormikErrorWrap>

                            {formik.values.transport === "car" && (
                                <>
                                    <div className="Profile__InputWrap">
                                        <FormikErrorWrap formik={formik} name="car_brand">
                                            <RegistrationInput
                                                className="Profile__Input"
                                                type="text"
                                                id="car_brand"
                                                name="car_brand"
                                                label="Марка автомобиля"
                                                placeholder="Введите марку автомобиля"
                                                value={formik.values.car_brand}
                                                error={hasError(formik, "car_brand")}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </FormikErrorWrap>
                                    </div>

                                    <div className="Profile__InputWrap">
                                        <FormikErrorWrap formik={formik} name="car_state_number">
                                            <RegistrationInput
                                                className="Profile__Input"
                                                type="text"
                                                id="car_state_number"
                                                name="car_state_number"
                                                label="Гос номер"
                                                placeholder="Гос номер автомобиля"
                                                value={formik.values.car_state_number}
                                                error={hasError(formik, "car_state_number")}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </FormikErrorWrap>
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                    <Tooltip
                        className="Profile__Tooltip"
                        text={formik.dirty ? "Заполните поля" : "Внесите изменения"}
                        diraction="top"
                        disabled={formik.dirty && formik.isValid}
                    >
                        <Button
                            tag="button"
                            BtnType="submit"
                            className="Profile__ButtonSave"
                            gradient={true}
                            type="colored"
                            text="Сохранить изменения"
                            disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
                        />
                    </Tooltip>
                </fieldset>
            </form>
        </>
    );
};

export default Profile;
