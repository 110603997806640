import React from "react";
import { useFormik } from "formik";
import { isAxiosError } from "axios";

import "./index.scss";
import PopupRefreshPassword from "./PopupRefreshPassword";
import useToggle from "@/hooks/useToggle";
import { toastNotifications } from "@/components/Toastify";
import { changePasswordSchema } from "@/Schema/changePasswordSchema";
import TitlePageAdmin from "@/components/TitlePageAdmin";
import RegistrationInput from "@/components/RegistrationInput";
import PasswordRules from "@/components/FormPasswordRules";
import Tooltip from "@/components/Tooltip";
import Button from "@/components/Button";
import { FormikErrorWrap } from "@/components/FormikErrorWrap";
import { hasError } from "@/utils/formik";
import { useUser } from "@/context/UserContext";
import { changeUserPassword } from "@/api";
import { ProfilePaymentInfo } from "@/pages/Profile/ProfilePaymentInfo";
import { Helmet } from "react-helmet";

const ChangePassword = () => {
    const { userInfo } = useUser();
    const [isOpen, togglePopup] = useToggle();

    const formik = useFormik({
        initialValues: {
            old_password: "",
            new_password: "",
            new_password_confirmation: "",
        },
        validationSchema: changePasswordSchema,
        onSubmit: async (formValues, formikHelpers) => {
            try {
                await changeUserPassword(formValues);
                toastNotifications.info("Пароль успешно изменен");
                formikHelpers.resetForm();
                setTimeout(() => {
                    formikHelpers.setTouched({});
                }, 100);
            } catch (error) {
                if (isAxiosError(error) && error.response?.status === 422 && error.response.data.errors) {
                    formikHelpers.setErrors(error.response.data.errors);
                    toastNotifications.error(error.response.data.message);
                } else {
                    toastNotifications.error("Что-то пошло не так. Повторите попытку позже");
                }
            }
        },
    });

    return (
        <>
            <Helmet>
                <title>Смена пароля | СберУниверситет</title>
                <meta name="description" content="Смена пароля" />
            </Helmet>
            <form className="ChangePassword" autoComplete="off" onSubmit={formik.handleSubmit}>
                <div className="ChangePassword__WrapBlock">
                    <TitlePageAdmin textDesktop="Смена пароля" textMobile="Смена пароля" />
                    <ProfilePaymentInfo />
                    <div className="ChangePassword__BlockInputs">
                        <div className="ChangePassword__InputWrap">
                            <FormikErrorWrap formik={formik} name="old_password">
                                <RegistrationInput
                                    className="ChangePassword__Input"
                                    type="password"
                                    name="old_password"
                                    placeholder="Введите ваш пароль"
                                    label="Ваш текущий пароль"
                                    value={formik.values.old_password}
                                    error={hasError(formik, "old_password")}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </FormikErrorWrap>
                        </div>
                        <div className="ChangePassword__InputWrap">
                            <PasswordRules className="ChangePassword__RulesList">
                                <FormikErrorWrap formik={formik} name="new_password">
                                    <RegistrationInput
                                        className="ChangePassword__Input"
                                        type="password"
                                        name="new_password"
                                        placeholder="Введите новый пароль"
                                        label="Новый пароль"
                                        autoComplete="new_password"
                                        value={formik.values.new_password}
                                        error={hasError(formik, "new_password")}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </FormikErrorWrap>
                            </PasswordRules>
                        </div>
                        <div className="ChangePassword__InputWrap">
                            <FormikErrorWrap formik={formik} name="new_password_confirmation">
                                <RegistrationInput
                                    className="ChangePassword__Input"
                                    type="password"
                                    name="new_password_confirmation"
                                    autoComplete="new_password"
                                    placeholder="Новый пароль ещё  раз"
                                    label="Повторите пароль, чтобы не ошибиться"
                                    value={formik.values.new_password_confirmation}
                                    error={hasError(formik, "new_password_confirmation")}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </FormikErrorWrap>
                        </div>
                    </div>
                </div>
                <div className="ChangePassword__RowButtons">
                    <Tooltip
                        className="ChangePassword__Tooltip"
                        text="Необходимо заполнить все поля"
                        diraction="top"
                        disabled={formik.isValid}
                    >
                        <Button
                            className="ChangePassword__ButtonEdit"
                            BtnType="submit"
                            gradient={true}
                            tag="button"
                            type="colored"
                            text="Сохранить"
                            disabled={!formik.isValid || formik.isSubmitting}
                        />
                    </Tooltip>
                    <button className="ChangePassword__ButtonOpenModal" type="button" onClick={togglePopup}>
                        Не помню пароль
                    </button>
                </div>
                {userInfo?.email && (
                    <PopupRefreshPassword isOpen={isOpen} userEmail={userInfo.email} onClose={togglePopup} />
                )}
            </form>
        </>
    );
};

export default ChangePassword;
