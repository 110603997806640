import { Navigate, useLocation } from "react-router-dom";
import useDownloadCertificate from "@/hooks/useDownloadCertificate";
import DownloadCertificate from "./DownloadCertificate";
import routes from "@/routes";
import "./index.scss";

const Certificates: React.FC = () => {
    const { isDownloadActive, showWarning } = useDownloadCertificate();
    const location = useLocation();

    if(!isDownloadActive) {
        showWarning();
        
        return <Navigate to={routes.profile} state={{ from: location }} replace />;
    }

    return (
        <div className="Certificates">
            <div className="Certificates__Header">
                <h2 className="Certificates__Title">Сертификат участника</h2>
                <p className="Certificates__Text">Получить именной сертификат участника</p>
            </div>
            <DownloadCertificate />
        </div>
    )
}

export default Certificates;