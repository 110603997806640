import React, { useEffect, useRef } from 'react';
import SocialElements from '@/components/SocialElements';
import useToggle from '@hooks/useToggle';
import { ReactComponent as SberIcon } from './icons/sber-icon.svg';
import { ReactComponent as ChevronDown } from './icons/chevron-down.svg';
import classNames from "classnames";
import useMedia from '@/hooks/useMedia';
import './index.scss';

const SocialMenuBtn = ({ className }) => {
    const [isOpen, toggle] = useToggle()
    const { isMobile } = useMedia();
    const innerRef = useRef(null);

    useEffect(() => {
        const handleClick = (e) => {
            if (isOpen && e.target !== innerRef.current) {
                toggle();
            }
        };

        window.addEventListener('click', handleClick);

        return () => {
            window.removeEventListener('click', handleClick);
        }
    }, [isOpen])

    return (
        <div className={classNames({
            [className]: className,
        })}
        >
            <div
                className={classNames({
                    'SocialMenuBtn__Btn': true,
                    'SocialMenuBtn__Btn--Open': isOpen,
                })}
                onClick={toggle}
                ref={innerRef}
            >
                {isMobile ? null : <SberIcon />}
                <span className="SocialMenuBtn__Text">Мы в соцсетях</span>
                <ChevronDown className="SocialMenuBtn__Icon" />
            </div>
            {
                isOpen && (
                    <div className="SocialMenuBtn__Modal">
                        <SocialElements />
                    </div>
                )
            }
        </div>
    );
};

export default SocialMenuBtn;