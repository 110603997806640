import React from "react";
import { ReactComponent as LinkArrow } from "./icons/arrow.svg";
import Crown from "./icons/crown.png";
import { Winner } from "../../types";
import "./index.scss";

interface Props {
    winner: Winner;
    onClickCardWinner: () => void;
    setWinner: (winner: Winner) => void;
}

const WinnerCard: React.FC<Props> = ({ onClickCardWinner, winner, setWinner }) => {
    const openModalCurrentWinner = (evt: React.MouseEvent<Element, MouseEvent>) => {
        evt.preventDefault();
        onClickCardWinner();
        setWinner(winner);
    };

    return (
        <a
            className="WinnerCard"
            href={winner.link}
            onClick={(evt) => openModalCurrentWinner(evt)}
            target="_blank"
            rel="noreferrer"
        >
            <div className="WinnerCard__Wrap">
                <div className={`WinnerCard__Logo WinnerCard__Logo--${winner.typeCard}`}>
                    <img src={winner.image} alt={winner.name} />
                </div>
                <h3 className="WinnerCard__Name">{winner.name}</h3>
                <p className="WinnerCard__Text">{winner.text}</p>
                <div className="WinnerCard__Link">
                    <LinkArrow />
                </div>
                {winner.isTop && (
                    <div className="WinnerCard__Top">
                        <div className="WinnerCard__Topinfo">Топ-3</div>
                        <div className="WinnerCard__TopImage">
                            <img src={Crown} alt="Топ-3" />
                        </div>
                    </div>
                )}
            </div>
        </a>
    );
};

export default WinnerCard;
