import classNames from "classnames";
import { ReactComponent as PinIcon } from "../assets/pin-icon.svg";
import HeartIcon from "./HeartIcon";
import "./index.scss";

type Props = {
    isPinned: boolean,
    user_has_rate_for_message: boolean,
    forStage: boolean,
    handleLike: (e: React.MouseEvent<HTMLButtonElement>) => void,
    likes: number
}

const MessageInfoIcons: React.FC<Props> = ({
    isPinned,
    user_has_rate_for_message,
    forStage = false,
    handleLike,
    likes
}) => {

    return (
        <div className="MessageInfoIcons">
            <button
                className={classNames({
                    "MessageInfoIcons__Likes": true,
                    "MessageInfoIcons__Likes--isLiked": user_has_rate_for_message,
                    "MessageInfoIcons__Likes--disabled": forStage,
                })}
                onClick={handleLike}
                disabled={forStage}
            >
                {likes > 0 && likes}
                <HeartIcon haslikes={likes > 0} />
            </button>

            {isPinned && <PinIcon />}
        </div>
    )
}

export default MessageInfoIcons;