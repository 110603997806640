import classNames from "classnames";
import { useState } from "react"
import { Blurhash } from "react-blurhash";
import './index.scss';

type Props = {
    className?: string,
    src: string,
    alt: string,
    hash: string,
    loading?: "lazy" | "eager"
}

const SkeletonImg: React.FC<Props> = ({
    className,
    src,
    alt,
    hash,
    loading = "eager"
}) => {
    const [isLoading, setIsloading] = useState(true);

    const handleLoad = (e: any) => {
        if (e.target.complete) {
            setTimeout(() => {
                setIsloading(false)
            }, 200)
        }
    }

    return (
        <>
            {isLoading ?
                < Blurhash
                    hash={hash}
                    width={"100%"}
                    height={"100%"}
                /> : null}
            <img
                className={classNames(className, {
                    "SkeletonImg": true,
                    "SkeletonImg--active": !isLoading,
                })}
                src={src}
                alt={alt}
                loading={loading}
                onLoad={handleLoad}
            />
        </>
    )
}

export default SkeletonImg;