import Container from 'components/Container';
import SocialItem from 'components/SocialItem';
import Logo from 'components/Logo';
import { SOCIAL_SUPPORT_EMAIL, SOCIAL_SUPPORT_TELEGRAM, SOCIAL_TELEGRAM, SOCIAL_EDUTECH, SOCIAL_VK } from 'constants/social';
import Headline from '@/components/Headline';
import useMedia from '@/hooks/useMedia';
import LogoMobile from './assets/logo-mobile-footer.png';
import { HashLink } from 'react-router-hash-link';
import { NavLink } from 'react-router-dom';
import routes from '@/routes';
import { CONF_POLICY_PDF, OFFER_PDF } from '@/constants/links';
import { ReactComponent as ArrowIcon } from './assets/arrow-up.svg';
import './index.scss';

const Footer = () => {
    const { isMobile, isTablet } = useMedia();

    const scrollToSection = (element: HTMLElement) => {
        const top = element.offsetTop - 200

        window.scrollTo({
            top,
            behavior: "instant"
        })
    }

    const handleClick = () => {
        window.scrollTo({
            top: 0,
            behavior: "instant"
        })
    }

    return (
        <footer className="Footer">
            <Container>
                <div className="Footer__Top">
                    <ul className="Footer__Top-links">
                        <li>
                            <NavLink
                                className="Footer__Top-link"
                                to={routes.conference}
                            >
                                О конференции
                            </NavLink>
                        </li>

                        <li>
                            <HashLink
                                className="Footer__Top-link"
                                to="/#program"
                                scroll={(el) => scrollToSection(el)}
                            >
                                Программа
                            </HashLink >
                        </li>
                        <li>
                            <NavLink
                                className="Footer__Top-link"
                                to={routes.contest}
                            >
                                Конкурс
                            </NavLink>
                        </li>
                        <li>
                            <HashLink
                                className="Footer__Top-link"
                                to="/#faq"
                                scroll={(el) => scrollToSection(el)}
                            >
                                FAQ
                            </HashLink >
                        </li>
                    </ul>
                    <div className="Footer__Top-social">
                        <div>
                            <Headline
                                className='Footer__Top-section-title'
                                level='h4'
                                theme='light'
                            >
                                Поддержка
                            </Headline>
                            <SocialItem
                                className="Footer__SocialItem"
                                icon={SOCIAL_SUPPORT_EMAIL.icon}
                                title={SOCIAL_SUPPORT_EMAIL.title}
                                link={SOCIAL_SUPPORT_EMAIL.link}
                                linkIsEmail
                                theme='light'
                            />
                            <SocialItem
                                className="Footer__SocialItem"
                                icon={SOCIAL_SUPPORT_TELEGRAM.icon}
                                title={SOCIAL_SUPPORT_TELEGRAM.title}
                                link={SOCIAL_SUPPORT_TELEGRAM.link}
                                linkIsHidden
                                theme='light'
                            />
                        </div>
                        <div>
                            <Headline
                                className='Footer__Top-section-title'
                                level='h4'
                                theme='light'
                            >
                                СберУниверситет
                            </Headline>
                            <SocialItem
                                className="Footer__SocialItem"
                                icon={SOCIAL_TELEGRAM.icon}
                                title={SOCIAL_TELEGRAM.title}
                                link={SOCIAL_TELEGRAM.link}
                                theme='light'
                            />
                            <SocialItem
                                className="Footer__SocialItem"
                                icon={SOCIAL_VK.icon}
                                title={SOCIAL_VK.title}
                                link={SOCIAL_VK.link}
                                theme='light'
                            />
                            {(isTablet || isMobile) &&
                                <SocialItem
                                    className="Footer__SocialItem"
                                    icon={SOCIAL_EDUTECH.icon}
                                    title={SOCIAL_EDUTECH.title}
                                    link={SOCIAL_EDUTECH.link}
                                    theme='light'
                                />
                            }
                        </div>
                        {!isTablet && !isMobile &&
                            <div>
                                <Headline
                                    className='Footer__Top-section-title'
                                    level='h4'
                                    theme='light'
                                >
                                    EduTech
                                </Headline>
                                <SocialItem
                                    className="Footer__SocialItem"
                                    icon={SOCIAL_EDUTECH.icon}
                                    title={SOCIAL_EDUTECH.title}
                                    link={SOCIAL_EDUTECH.link}
                                    theme='light'
                                />
                            </div>
                        }
                    </div>
                    {!isTablet &&
                        <div
                            className='Footer__To-top'
                            onClick={handleClick}
                        >
                            Наверх
                            <ArrowIcon />
                        </div>
                    }
                </div>
                <div className='Footer__Bottom'>
                    {isMobile ?
                        <a href="https://sberuniversity.ru" target='_blank'>
                            <img src={LogoMobile} alt='logo' />
                        </a>
                        :
                        <Logo />
                    }
                    <ul className='Footer__Bottom-Items'>
                        <li className='Footer__Bottom-copy-write'>
                            © 2023 СберУниверситет
                        </li>
                        <li className='Footer__Bottom-Item'>
                            <a href={CONF_POLICY_PDF} target="_blank" rel="noreferrer">
                                Политика конфиденциальности
                            </a>
                        </li>
                        <li className='Footer__Bottom-Item'>
                            <a href={OFFER_PDF} target="_blank" rel="noreferrer">
                                Оферта
                            </a>
                        </li>
                    </ul>
                    {isTablet &&
                        <div
                            className='Footer__To-top'
                            onClick={handleClick}
                        >
                            Наверх
                            <ArrowIcon />
                        </div>
                    }
                </div>
            </Container>
        </footer >
    );
};

export default Footer;
