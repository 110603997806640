import React, { useMemo } from "react";
import Radio from "@/components/Radio";
import RegistrationInput from "@/components/RegistrationInput";
import Button from "@/components/Button";
import Tooltip from "@/components/Tooltip";
import { hasError } from "@/utils/formik";
import { FormikErrorWrap } from "@/components/FormikErrorWrap";
import { FormikData } from "@/types/formik";
import "./index.scss";

const TransportInformation = ({ formik, nextStep }: { formik: FormikData<any>; nextStep: () => void }) => {
    const isValid = useMemo(() => {
        return !(
            hasError(formik, "transport", false) ||
            hasError(formik, "car_brand", false) ||
            hasError(formik, "car_state_number", false)
        );
    }, [formik]);

    return (
        <>
            <p className="TransportInformation__text">Как приедете в кампус</p>
            <div className="TransportInformation__Radio-wrapper">
                <Radio
                    className="TransportInformation__Radio"
                    id="car"
                    name="transport"
                    text="Автомобиль"
                    value="car"
                    checked={formik.values.transport === "car"}
                    onChange={formik.handleChange}
                />
                <Radio
                    className="TransportInformation__Radio"
                    id="taxi"
                    name="transport"
                    text="Такси"
                    value="taxi"
                    checked={formik.values.transport === "taxi"}
                    onChange={formik.handleChange}
                />
                <Radio
                    className="TransportInformation__Radio"
                    id="transfer"
                    name="transport"
                    text="Трансфер"
                    value="transfer"
                    checked={formik.values.transport === "transfer"}
                    onChange={formik.handleChange}
                />
            </div>

            <div className="TransportInformation__Fields">
                {formik.values.transport === "car" && (
                    <>
                        <FormikErrorWrap formik={formik} name="car_brand">
                            <RegistrationInput
                                className="TransportInformation__Input"
                                type="text"
                                id="car_brand"
                                name="car_brand"
                                label="Марка автомобиля"
                                placeholder="Введите марку автомобиля"
                                value={formik.values.car_brand}
                                error={hasError(formik, "car_brand")}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </FormikErrorWrap>

                        <FormikErrorWrap formik={formik} name="car_state_number">
                            <RegistrationInput
                                className="TransportInformation__Input"
                                type="text"
                                id="car_state_number"
                                name="car_state_number"
                                label="Гос номер"
                                placeholder="Гос номер автомобиля"
                                value={formik.values.car_state_number}
                                error={hasError(formik, "car_state_number")}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </FormikErrorWrap>
                    </>
                )}
            </div>
            <Tooltip
                className="TransportInformation__Tooltip"
                text="Необходимо заполнить все поля"
                diraction="top"
                disabled={isValid}
            >
                <Button
                    className="TransportInformation__Btn"
                    tag="button"
                    onClick={nextStep}
                    text="Далее"
                    size="lg"
                    type="colored"
                    isWide
                    gradient
                    disabled={!isValid}
                />
            </Tooltip>
        </>
    );
};

export default TransportInformation;
