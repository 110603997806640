import classNames from "classnames";
import { ReactComponent as ArrowIcon } from "./assets/arrow-down.svg";
import "./index.scss";

type Props = {
    className?: string,
    onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const ToStartBtn: React.FC<Props> = ({
    className,
    onClick
}) => {
    return (
        <button
            className={classNames(className, "ToStartBtn")}
            type="button"
            onClick={onClick}
        >
            <span className="ToStartBtn__Icon">
                <ArrowIcon />
            </span>
            К началу
        </button>
    )
}

export default ToStartBtn;