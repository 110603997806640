import { NavLink, useLocation } from 'react-router-dom';
import SocialElements from 'components/SocialElements';
import { ReactComponent as ConferenceIcon } from './icons/conference-icon.svg';
import { ReactComponent as CompetitionIcon } from './icons/competition-icon.svg';
import { ReactComponent as ProgramsIcon } from './icons/programs-icon.svg';
import { ReactComponent as LinkIcon } from './icons/link-icon.svg';
import routes from 'routes';
import { HashLink } from 'react-router-hash-link';
import { useContext } from 'react';
import { NavContext } from 'context/NavContext';
import './index.scss';

const MenuMobile = () => {
    const { hash } = useLocation();

    const { activeSection } = useContext(NavContext);
    const { program } = activeSection;

    const scrollToSection = (element: HTMLElement) => {
        const top = element.offsetTop - 300

        window.scrollTo({
            top,
            behavior: "instant"
        })
    }

    return (
        <div className='MenuMobile'>
            <h2 className='MenuMobile__Title'>Меню</h2>
            <div className="MenuMobile__Nav-items">
                <NavLink
                    className={({ isActive }) => isActive && !program ? "MenuMobile__Link active" : "MenuMobile__Link"}
                    to={routes.conference}
                >
                    <ConferenceIcon className="MenuMobile__Link-icon" />

                    <div className="MenuMobile__Link-body">
                        О конференции
                        <span className="MenuMobile__Link-arrow">
                            <LinkIcon />
                        </span>
                    </div>
                </NavLink>

                < HashLink
                    className={hash === "#program" && program ? "MenuMobile__Link active" : "MenuMobile__Link"}
                    to="/#program"
                    scroll={(el) => scrollToSection(el)}
                >
                    <ProgramsIcon className="MenuMobile__Link-icon" />
                    <div className="MenuMobile__Link-body">
                        Программа
                        <span className="MenuMobile__Link-arrow">
                            <LinkIcon />
                        </span>
                    </div>
                </HashLink >

                <NavLink className="MenuMobile__Link" to={routes.contest} >
                    <CompetitionIcon className="MenuMobile__Link-icon" />
                    <div className="MenuMobile__Link-body">
                        Конкурс
                        <span className="MenuMobile__Link-arrow">
                            <LinkIcon />
                        </span>
                    </div>
                </NavLink>
            </div>
            <div className='MenuMobile__Social'>
                <p className='MenuMobile__Social-title'>МЫ В СОЦСЕТЯХ:</p>
                <SocialElements theme="dark" />
            </div>
        </div>
    )
}

export default MenuMobile;