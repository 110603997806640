import React from "react";
import PlanDescription from "../common/PlanDescription";
import PlanPrice from "../common/PlanPrice";
import PlanFeatures from "../common/PlanFeatures";

const features = [
    {
        title: "общение",
        description: "Участие в интерактивных перерывах — беседах с экспертами рынка",
    },
    {
        title: "комфорт",
        description: "Возможность переключаться между онлайн-трансляциями мероприятий",
    },
    {
        title: "комфорт",
        description: "Записи всех трансляций",
    },
    {
        title: "кое-что новое",
        description: "Доступ к уникальной онлайн-базе инструментов для разработки эффективного обучения",
    },
];

const OnlineParticipationPlan = ({ showOnlineBtn }) => {
    return (
        <>
            <PlanDescription
                text={
                    "Возможность получить новый опыт в любое время и из любой точки\nмира, а также - пообщаться с экспертами рынка."
                }
                theme="light"
            />

            <PlanFeatures features={features} theme="light" />

            <PlanPrice participationFormat="online" showOnlineBtn={showOnlineBtn} />
        </>
    );
};

export default OnlineParticipationPlan;
