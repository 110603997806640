import Popup from 'components/Popup';
import { useToggle } from 'react-use';
import SessionCard from 'components/SessionCard';
import { SessionDataType } from 'types/interfaces';
import useMedia from 'hooks/useMedia';
import './index.scss';

type Props = {
    data: SessionDataType,
    children?: React.ReactNode,
    className?: string,
    mainStage?: boolean,
    vertical?: boolean,
    withLink?: boolean,
    gradientSubtitle?: boolean,
}

const ProgramContainer: React.FC<Props> = ({
    data,
    className,
    mainStage,
    withLink,
    vertical,
    gradientSubtitle,
    children,
}) => {
    const [isActive, toggle] = useToggle(false);
    const { isMobile } = useMedia();

    return (
        <>
            <SessionCard
                className={className}
                data={data}
                mainStage={mainStage}
                withLink={withLink}
                vertical={vertical}
                togglePopup={() => toggle()}
                showSpeakers={!isMobile}
                gradientSubtitle={gradientSubtitle}
            />
            <Popup
                className='ProgramContainer__Popup-box'
                closePopup={toggle}
                isPopupActive={isActive}
                position='right'
            >
                <div className='ProgramContainer__Wrapper'>
                    <SessionCard
                        className="ProgramContainer__Popup-content"
                        data={data}
                        mainStage={mainStage}
                        avatarSize="big"
                        showSpeakerdescription
                        isInSidePopup
                    >
                        {data.sessionDescription &&
                            <div className="ProgramContainer__Popup-text">
                                <div
                                    className="ProgramContainer__Popup-text-main"
                                    dangerouslySetInnerHTML={{ __html: data.sessionDescription }}
                                />
                                <p className="ProgramContainer__Reference">{data?.sessionReference}</p>
                                {children}
                            </div>
                        }
                    </SessionCard>
                </div>
            </Popup>
        </>
    )
}

export default ProgramContainer;