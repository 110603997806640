import { useContext, useEffect, useRef, useState } from "react";
import { ChatTabType, ChatType, StreamMessagesType } from "@/types/chatTypes";
import ChatBox from "./ChatBox";
import ChatHeader from "./ChatHeader";
import ChatInput from "./ChatInput";
import { useToggle } from "react-use";
import useMedia from "@/hooks/useMedia";
import { BroadcastContext } from "@/context/BroadcastContext";
import classNames from "classnames";
import "./index.scss";

const forCommonTabs: ChatTabType[] = [
    {
        tab: "Чат",
        chatType: "common",
    },
    {
        tab: "Вопросы спикерам",
        chatType: "for_speaker",
    },
];

const questionsforModeratorsTabs: ChatTabType[] = [
    {
        tab: "Вопросы спикерам",
        chatType: "for_speaker",
    },
];

const chatforModeratorsTabs: ChatTabType[] = [
    {
        tab: "Общий чат",
        chatType: "for_speaker",
    },
];

type Props = {
    isCollapsible: boolean;
    isForModerators?: boolean;
    forStage?: boolean;
    isCommonChatModerated?: boolean;
    chatData: StreamMessagesType[];
    chatType: ChatType;
};

const LiveChat: React.FC<Props> = ({
    isCollapsible,
    isForModerators = false,
    isCommonChatModerated = false,
    forStage = false,
    chatData,
    chatType,
}) => {
    const { isDesktop } = useMedia();
    const { streamNum, toggleChatType, activeBroadcast } = useContext(BroadcastContext);

    const hasBroadcastStarted = activeBroadcast?.is_active;

    // can collapse and expand for small screens. collapse logic
    const [isExpanded, toggleExpand] = useToggle(false);
    const chatCallapseRef = useRef<null | HTMLDivElement>(null);
    const chatRef = useRef<null | HTMLDivElement>(null);

    // tabs for chat
    const tabs = isCommonChatModerated
        ? chatforModeratorsTabs
        : isForModerators || forStage
        ? questionsforModeratorsTabs
        : forCommonTabs;

    // question Sum
    const questionSum = chatData.length;

    useEffect(() => {
        if (!isCollapsible) return;
        const chatContainer = chatCallapseRef.current;
        const chatBox = chatRef.current;

        // Function to update chatContainer height
        const updateChatContainerHeight = () => {
            if (chatContainer && chatBox && isExpanded && !isDesktop) {
                chatContainer.style.height = chatBox.clientHeight + 75 + "px";
            } else if (chatContainer && chatBox && !isExpanded && !isDesktop) {
                chatContainer.style.height = "50px";
            }
        };

        // Initial update
        updateChatContainerHeight();
    }, [isExpanded, chatRef, isDesktop, isCollapsible]);

    return (
        <div
            className={classNames({
                LiveChat__Collapse: true,
                "LiveChat__Collapse--collapsible": isCollapsible,
            })}
            ref={chatCallapseRef}
        >
            <div className="LiveChat__Container" ref={chatRef}>
                <ChatHeader
                    chatToggle={toggleChatType}
                    chatType={isForModerators ? "for_speaker" : chatType}
                    realChatType={chatType}
                    tabs={tabs}
                    isExpanded={isDesktop ? true : isCollapsible ? isExpanded : true}
                    toggleExpand={toggleExpand}
                    isCollapsible={isCollapsible}
                    isForModerators={isForModerators}
                    streamNum={streamNum}
                    questionSum={questionSum}
                    isCommonChatModerated={isCommonChatModerated}
                    forStage={forStage}
                />
                {hasBroadcastStarted ? (
                    <>
                        <ChatBox
                            chatData={chatData}
                            chatType={chatType}
                            isForModerators={isForModerators}
                            isCommonChatModerated={isCommonChatModerated}
                            forStage={forStage}
                        />

                        {!isForModerators && !forStage && <ChatInput chatType={chatType} />}
                    </>
                ) : (
                    <div
                        className={classNames({
                            "LiveChat__Not-started": true,
                            "LiveChat__Not-started--forModerators": isForModerators || forStage,
                        })}
                    >
                        {chatType === "common"
                            ? "Чат будет доступен\nпосле запуска трансляции."
                            : isForModerators
                            ? "Вопросы будут доступны\nпосле запуска трансляции."
                            : "Вопросы можно будет задавать\nпосле запуска трансляции."}
                    </div>
                )}
            </div>
        </div>
    );
};

export default LiveChat;
