import Container from '@/components/Container';
import Typography from '@/components/Typography';
import Headline from '@/components/Headline';
import GreenLigh from './icons/green-light-left.png';
import RedLigh from './icons/red-light-bottom.png';
import TrophyIcon from './icons/trophy.png';
import { ReactComponent as CheckIcon } from './icons/check-icon.svg';
import { ReactComponent as XIcon } from './icons/x-icon.svg';
import './index.scss';

const Terms = () => {
    return (
        <section className="Terms">
            {/* <img className="Terms__Green-Light" src={GreenLigh} alt="decoration light" />
            <img className="Terms__Red-Light" src={RedLigh} alt="decoration light" /> */}
            <Container className="Terms__Wrapper">
                <img className="Terms__Trophy" src={TrophyIcon} alt="Trophy" />
                <Headline className="Terms__Title">Какие решения <br /> <Typography className="Terms__Title-gradient" component='span' color='white' gradient>участвовали</Typography><br/>в&nbsp;конкурсе</Headline>
                <div className="Terms__Info">
                    <div className="Terms__Accept">
                        <p className="Terms__Text">Приняли:</p>
                        <ul className="Terms__List">
                            <li className="Terms__Сlause">
                                <CheckIcon />
                                сервисы, приложения: для видео и трансляций, тестирований, совместной работы участников, автоматизации процессов и др;</li>
                            <li className="Terms__Сlause">
                                <CheckIcon />
                                решения на основе ИИ: тьюторы, диагностика навыков и др;</li>
                            <li className="Terms__Сlause">
                                <CheckIcon />
                                тренажёры, симуляторы;</li>
                            <li className="Terms__Сlause">
                                <CheckIcon />
                                конструкторы образовательных программ;</li>
                            <li className="Terms__Сlause">
                                <CheckIcon />
                                адаптивные платформы (LXP) и продвинутые LMS полного HR-цикла.</li>
                        </ul>
                    </div>
                    <div className="Terms__Reject">
                        <p className="Terms__Text">Не приняли:</p>
                        <ul className="Terms__List">
                            <li className="Terms__Сlause">
                                <XIcon />
                                конкретные образовательные решения: курсы, уроки, программы, специализации, учебные планы и ресурсы;</li>
                            <li className="Terms__Сlause">
                                <XIcon />
                                образовательные организации: корпоративные университеты, школы, образовательные команды.</li>
                        </ul>
                    </div>
                </div>
            </Container>
        </section>
    )
}

export default Terms;