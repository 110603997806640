import Headline from '@/components/Headline'
import './index.scss'
const TitlePageAdmin = ({ textDesktop, textMobile }) => {

  return (
    <>
      <Headline level={"h1"} className="TitlePageAdmin">
        {textDesktop}
      </Headline>
      <Headline level={"h1"} className="TitlePageAdmin TitlePageAdmin--mobile">
        {textMobile}
      </Headline>
    </>
  )
}

export default TitlePageAdmin