import { useRef, useState } from "react";
import { useClickAway, useToggle } from "react-use";
import { ReactComponent as DownloadIcon } from "./assets/download-icon.svg";
import classNames from "classnames";
import { PresentationLinksType } from "@/types/interfaces";
import VideoPlayer from '@/components/VideoPlayer';
import Popup from "@/components/Popup";
import "./index.scss";

type Props = {
    text: string,
    icon: JSX.Element,
    withDropdown?: boolean,
    openToTop?: boolean,
    isInSidePopup?: boolean,
    presentationData?: PresentationLinksType[],
    mediaLink?: string
}

const SessionMedia: React.FC<Props> = ({ text, icon, withDropdown, presentationData, mediaLink, openToTop, isInSidePopup }) => {
    const [isOpen, toggleOpen] = useToggle(false)
    const [isVideoPopupOpen, togglePopupVideoPopup] = useState<boolean>(false);

    const btnRef = useRef(null);

    useClickAway(btnRef, () => {
        toggleOpen(false);
    }, ["click"])

    const handleClick = () => {
        if (withDropdown) {
            toggleOpen();
        } else {
            togglePopupVideoPopup(true)
        }
    }

    const renderElement = presentationData?.map((el, index) => {
        return (
            <li key={index}>
                <a className="SessionMedia__Dropdown-Menu-Link" href={el.link} target="_blank">
                    <DownloadIcon />
                    <span>{el.text}</span>
                </a>
            </li>
        )
    });

    return (
        <>
            <div className="SessionMedia__Wrapper">
                {isInSidePopup && !mediaLink ?
                    null
                    : (
                        <button
                            ref={btnRef}
                            className="SessionMedia"
                            onClick={handleClick}
                        >
                            {icon}
                            {text}
                        </button>
                    )}

                {withDropdown && isOpen &&
                    <div className={classNames({
                        "SessionMedia__Dropdown": true,
                        "SessionMedia__Dropdown--to-top": openToTop,
                    })}>
                        <h4 className="SessionMedia__Dropdown-Title">Скачать</h4>
                        <ul className="SessionMedia__Dropdown-Menu">
                            {renderElement}
                        </ul>
                    </div>
                }
            </div>
            {mediaLink && (
                <Popup
                    className="SessionMedia__Popup"
                    isPopupActive={isVideoPopupOpen}
                    closePopup={() => togglePopupVideoPopup(prev => !prev)}
                    position="center"
                    withCloseBtn
                    withBodyScroll
                >
                    <VideoPlayer
                        className="SessionMedia__Iframe"
                        videoLink={mediaLink}
                        isWithHash={false}
                    />
                </Popup>
            )}
        </>
    )
}

export default SessionMedia;