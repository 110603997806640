import React, { useMemo } from "react";
import "./index.scss";
import Button from "@/components/Button";
import Tooltip from "@/components/Tooltip";
import classNames from "classnames";
import { hasError } from "@/utils/formik";
import { FormikData } from "@/types/formik";

const AccommodationChoice = ({ formik, nextStep }: { formik: FormikData<any>; nextStep: () => void }) => {
    const withAccommodation = formik.values.is_planning_to_stay_at_the_hotel === true;
    const withNoAccommodation = formik.values.is_planning_to_stay_at_the_hotel === false;

    const isValid = useMemo(() => {
        return !hasError(formik, "is_planning_to_stay_at_the_hotel", false);
    }, [formik]);

    return (
        <>
            <div className="AccommodationChoice__Inputs">
                <label
                    className={classNames("AccommodationChoice__Option", {
                        "AccommodationChoice__Option--active": withAccommodation,
                    })}
                    htmlFor="accommodation_yes"
                >
                    <input
                        type="radio"
                        id="accommodation_yes"
                        name="is_planning_to_stay_at_the_hotel"
                        onChange={() => formik.setFieldValue("is_planning_to_stay_at_the_hotel", true)}
                        checked={withAccommodation}
                    />
                    Да
                </label>

                <label
                    className={classNames("AccommodationChoice__Option", {
                        "AccommodationChoice__Option--active": withNoAccommodation,
                    })}
                    htmlFor="accommodation_no"
                >
                    <input
                        type="radio"
                        id="accommodation_no"
                        name="is_planning_to_stay_at_the_hotel"
                        onChange={() => formik.setFieldValue("is_planning_to_stay_at_the_hotel", false)}
                        checked={withNoAccommodation}
                    />
                    Нет
                </label>
            </div>

            {withAccommodation && (
                <p className="AccommodationChoice__Note">
                    {"По окончанию регистрации мы свяжемся с вами\nпо вопросу бронирования номера"}
                </p>
            )}

            <Tooltip
                className="AccommodationChoice__Tooltip"
                text={"Необходимо выбрать вариант проживания"}
                diraction="top"
                disabled={isValid}
            >
                <Button
                    className="AccommodationChoice__Btn"
                    tag="button"
                    onClick={nextStep}
                    text="Далее"
                    size="lg"
                    type="colored"
                    isWide
                    gradient
                    disabled={!isValid}
                />
            </Tooltip>
        </>
    );
};

export default AccommodationChoice;
