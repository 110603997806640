import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import classNames from "classnames";

import { ReactComponent as IconClose } from "./icons/close.svg";
import Headline from "@/components/Headline";
import Container from "@/components/Container";
import Navbar from "@/components/Navbar";
import TabsNavigation from "@/components/TabsNavigation";
import Avatar from "@components/Avatar";
import { useUser } from "@/context/UserContext";
import useMedia from "@/hooks/useMedia";
import "./index.scss";

const ProfileLayout = () => {
    const navigate = useNavigate();
    const { userInfo } = useUser();

    const { isDesktop } = useMedia();

    return (
        <div className="ProfileLayout">
            <section className="ProfileLayout__Panel">
                <Container className="ProfileLayout__Container">
                    <div className="ProfileLayout__PanelContainer">
                        <div
                            className={classNames("ProfileLayout__NavbarBlock", {
                                "ProfileLayout__NavbarBlock--visible": isDesktop,
                            })}
                        >
                            <button
                                onClick={() => navigate("/")}
                                className="ProfileLayout__ButtonPanelClose ProfileLayout__ButtonPanelClose--Navbar"
                            >
                                <IconClose />
                            </button>
                            <Headline level="h1" className={"ProfileLayout__NavbarTitle"}>
                                Личный кабинет
                            </Headline>
                            <div className="ProfileLayout__ProfileInfo">
                                <Avatar initials="ША" />
                                <div className="ProfileLayout__ProfileInfoName">{userInfo?.name}</div>
                            </div>
                            <Navbar />
                        </div>
                        <div
                            className={classNames("ProfileLayout__Content", {
                                "ProfileLayout__Content--visible": !isDesktop,
                            })}
                        >
                            <button
                                onClick={() => navigate("/")}
                                className="ProfileLayout__ButtonPanelClose ProfileLayout__ButtonPanelClose--Content"
                            >
                                <IconClose />
                            </button>
                            <TabsNavigation />
                            <Outlet />
                        </div>
                    </div>
                </Container>
            </section>
        </div>
    );
};

export default ProfileLayout;
