import React from "react";

import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useIsAuthenticated } from "react-auth-kit";

export type ProtectedRouteProps = React.PropsWithChildren<{
    loginPath: any;
}>;

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ loginPath, children }) => {
    const location = useLocation();
    const isAuth = useIsAuthenticated();

    if (!isAuth()) {
        return <Navigate to={loginPath} state={{ from: location }} replace />;
    }

    return children || <Outlet />;
};

export default ProtectedRoute;
