import Button from "@/components/Button";
import Container from "@/components/Container";
import { Helmet } from "react-helmet";
import "./index.scss";

const NotFound = () => {
    return (
        <>
            <Helmet>
                <title>Ошибка 404</title>
                <meta name="description" content="Извините такой страницы не существует" />
            </Helmet>
            <Container className="NotFound__Container">
                <h1 className="NotFound__Title">Ошибка 404</h1>
                <p className="NotFound__Text">Извините такой страницы не существует</p>
                <Button className="NotFound__Button" size="lg" tag="a" href="/" text="Перейти на главную"></Button>
            </Container>
        </>
    );
};

export default NotFound;
