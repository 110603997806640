import classNames from "classnames";
import { ReactComponent as DiscountIcon } from "./assets/discount-icon.svg";
import useMedia from "@/hooks/useMedia";
import { DISCOUNT_PRICE, GENERAL_PRICE } from "@/constants/tariffs";
import "./index.scss";

const PlanPriceTag = ({
    withDiscount,
    showTag = true,
    className,
}: {
    className?: string;
    showTag?: boolean;
    withDiscount?: any;
    price?: any;
}) => {
    const { isMobile } = useMedia();

    const formatPrice = (price?: any) => {
        const formattedPrice = Intl.NumberFormat("ru", {
            style: "currency",
            currency: "RUB",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(price);

        return formattedPrice;
    };

    return (
        <div className={classNames(className, "PlanPriceTag")}>
            <div className="PlanPriceTag__Price">
                {withDiscount ? (
                    <>
                        <div className="PlanPriceTag__Price-discount">
                            {showTag && formatPrice(GENERAL_PRICE)}

                            {+withDiscount !== 100 && <DiscountIcon />}
                        </div>
                        {+withDiscount !== 100 ? (
                            formatPrice(DISCOUNT_PRICE)
                        ) : (
                            <p className="PlanPriceTag__Free">Бесплатно</p>
                        )}
                    </>
                ) : (
                    formatPrice(GENERAL_PRICE)
                )}
            </div>
            <p className="PlanPriceTag__Price-note">Стоимость за одного участника</p>
        </div>
    );
};

export default PlanPriceTag;
