import SignInForm from './SignInForm';
import ToSignUp from './ToSignUp';
import AuthorizationFormHeader from '@components/AuthorizationFormHeader';

const SignInBox = () => {
    return (
        <>
            <AuthorizationFormHeader
                title={"Войдите в профиль"}
                subtitle={"Здравствуйте!"}
                subtitleOnTop
            />
            <SignInForm />
            {/* removed after 25.10.2023 */}
            {/* <ToSignUp /> */}
        </>
    )
}

export default SignInBox;
