import axios, {isCancel} from "axios";
import { captureException } from "@/utils/sentry";

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (!isCancel(error)) {
            captureException(error);
        }
        return Promise.reject(error);
    },
);

const clientApi = axios.create({
    baseURL: "/api",
});

clientApi.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (!isCancel(error)) {
            captureException(error);
        }
        return Promise.reject(error);
    },
);

export { clientApi };
