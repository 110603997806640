import React, { PropsWithChildren } from "react";
import classNames from "classnames";

import "./index.scss";

const Tooltip = ({
    children,
    className,
    text,
    diraction,
    disabled,
}: PropsWithChildren<{
    className?: string;
    text?: JSX.Element | string;
    diraction?: "top" | "bottom" | "left" | "right";
    disabled?: boolean;
}>) => {
    return (
        <div
            className={classNames(className, "Tooltip", {
                "Tooltip--disabled": disabled,
                "Tooltip--top": diraction === "top",
                "Tooltip--bottom": diraction === "bottom",
                "Tooltip--left": diraction === "left",
                "Tooltip--right": diraction === "right",
            })}
        >
            <div className="Tooltip__Body">{text}</div>
            {children}
        </div>
    );
};

export default Tooltip;
