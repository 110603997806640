import React from "react";
import classNames from "classnames";

import { ReactComponent as StepPassedIcon } from "./assets/step-passed.svg";
import "./index.scss";

export type FormStepperProps = {
    className?: string;
    steps: number;
    startFrom: any;
    currentStep: any;
};

const FormStepper = ({ className, steps, startFrom, currentStep }: FormStepperProps) => {
    const renderSteps = new Array(steps).fill(0).map((el, index) => {
        const stepNum = index + 1;

        return (
            <span
                key={stepNum}
                className={classNames("FormStepper__step", {
                    "FormStepper__step--active": stepNum === currentStep,
                })}
            >
                {stepNum < currentStep ? <StepPassedIcon className="FormStepper__icon" /> : stepNum}
            </span>
        );
    });
    return <>{currentStep >= startFrom && <div className={classNames(className, "FormStepper")}>{renderSteps}</div>}</>;
};

export default FormStepper;
