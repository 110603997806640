import { SpeakerType } from 'types/interfaces';
import './index.scss';

type Props = {
    children?: React.ReactNode,
    data: SpeakerType
}

const SpeakerPopupContent: React.FC<Props> = ({
    children,
    data
}) => {

    return (
        <div className='SpeakerPopupContent__Wrapper'>
            <div className='SpeakerPopupContent__Header'>
                <div className='SpeakerPopupContent__img-container'>
                    <img
                        className='SpeakerPopupContent__img'
                        src={data.photo_url}
                        alt={data.name}
                    />
                </div>
                <div>
                    <p className='SpeakerPopupContent__Name'>{data.name}</p>
                    <p className='SpeakerPopupContent__Name'>{data.surname}</p>
                    <p className='SpeakerPopupContent__Text'>{data.description}</p>
                </div>
            </div>

            {data.details &&
                <div
                    className="SpeakerPopupContent__Details"
                    dangerouslySetInnerHTML={{ __html: data.details }}
                />
            }

            {children}
        </div>
    )
}

export default SpeakerPopupContent;