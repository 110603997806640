import { useRef, useState } from "react";
import classNames from "classnames";
import { useClickAway } from "react-use";
import { ReactComponent as ArrowDown } from "./assets/arrow-down.svg";
import { ReactComponent as ExitIcon } from "./assets/exit-icon.svg";
import { ReactComponent as ArrowRight } from "./assets/arrow-right.svg";
import { ReactComponent as CloseIcon } from "./assets/close-icon.svg";
import { useSignOut } from "react-auth-kit";
import { NavLink } from "react-router-dom";
import Avatar from "@/components/Avatar";
import { useUser } from "@/context/UserContext";
import useDownloadCertificate from "@/hooks/useDownloadCertificate";
import { routesNavigationProfile } from "@/constants/navigations";
import "./index.scss";


const ProfileBtn: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const signOut = useSignOut();
    const innerRef = useRef(null);

    const { isDownloadActive, showWarning } = useDownloadCertificate();

    const { userInfo } = useUser();

    useClickAway(
        innerRef,
        () => {
            setIsOpen(false);
        },
        ["click"],
    );

    const handleClickLogout = () => {
        signOut();
    };

    const renderMenu = routesNavigationProfile.map((el, index) => {
        const isActive = el.navType === "certificates" ? isDownloadActive : true;
        const TagElement = isActive ? NavLink : "span";

        return (
            <li key={index} className="ProfileBtn__Dropdown-item">
                <TagElement className={classNames({
                    "ProfileBtn__Dropdown-link": true,
                    "ProfileBtn__Dropdown-link--disabled": !isActive,
                })}
                    to={el.route}
                    end={isActive ? true : undefined}
                    onClick={isActive ? undefined : showWarning}
                >
                    <span className="ProfileBtn__Dropdown-icon">{<el.icon />}</span>
                    {el.label}
                    <span className="ProfileBtn__Dropdown-arrow-right">
                        <ArrowRight />
                    </span>
                </TagElement>
            </li>
        );
    });

    return (
        <>
            <button
                className={classNames("ProfileBtn", {
                    "ProfileBtn--active": isOpen,
                })}
                ref={innerRef}
                onClick={() => setIsOpen((prev) => !prev)}
            >
                <div className="ProfileBtn__Avatar-container">
                    <Avatar
                        className="ProfileBtn__Avatar"
                        src={userInfo?.avatar_url}
                        name={userInfo?.surname + " " + userInfo?.name}
                    />
                </div>
                <ArrowDown className="ProfileBtn__Icon" />
            </button>

            {isOpen && (
                <div className="ProfileBtn__Dropdown">
                    <div className="ProfileBtn__Dropdown-top">
                        <h2 className="ProfileBtn__Dropdown-top-text">Личный кабинет</h2>
                        <CloseIcon />
                    </div>
                    <div className="ProfileBtn__Dropdown-header">
                        <div className="ProfileBtn__Dropdown-avatar-container">
                            <Avatar
                                className="ProfileBtn__Avatar ProfileBtn__Avatar--dropdown-menu"
                                src={userInfo?.avatar_url}
                                name={userInfo?.surname + " " + userInfo?.name}
                            />
                        </div>
                        <h3 className="ProfileBtn__Dropdown-username">
                            {userInfo?.surname} {userInfo?.name}
                        </h3>
                    </div>
                    <ul className="ProfileBtn__Dropdown-list">{renderMenu}</ul>
                    <div className="ProfileBtn__Dropdown-divider">
                        <span></span>
                    </div>
                    <button className="ProfileBtn__Dropdown-btn" onClick={handleClickLogout}>
                        <span className="ProfileBtn__Dropdown-icon">
                            <ExitIcon />
                        </span>
                        <span className="ProfileBtn__Dropdown-btn-text">Выйти</span>
                    </button>
                </div>
            )}
        </>
    );
};

export default ProfileBtn;