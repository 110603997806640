import { useEffect, useState } from "react";
import Intro from "./Intro";
import ConferenceInNumbers from "./ConferenceInNumbers";
import Events from "./Events";
import Programs from "./Programs";
import VideoMemories from "./VideoMemories";
import Ticket from "./Ticket";
import Share from "./Share";
import Invitation from "./Invitation";
import ChooseTariff from "./ChooseTariff";
import Faq from "./Faq";
import Consultation from "./Consultation";
import OnlineHub from "./OnlineHub";
import { Controller } from "react-scrollmagic";
import ProgramSchedule from "./ProgramSchedule";
import Speakers from "./Speakers";
import MediaPartners from "./MediaPartners";
import Methodologists from "./Methodologists";
import Divider from "components/Divider";
import { Helmet } from "react-helmet";
import Broadcast from "@/pages/Broadcast";
import VideoArchive from "./VideoArchive";
import PhotoArchive from "./PhotoArchive";
import "./index.scss";

const Main: React.FC = () => {
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => setAnimate(true), 100);
        return () => clearInterval(timer);
    }, []);

    return (
        <>
            <Helmet>
                <title>Конференция Больше чем обучение — 2023 | СберУниверситет</title>
                <meta
                    name="description"
                    content="Участвуйте в конференции СберУниверситета про современные тренды в образовании и новый подход к обучению! Вас ждут ✓Доклады от руководителей компаний, топ-менеджмента Сбера  ✓Выставка лучших EdTech-компаний ✓Участие в воркшопах ✓Консультации методологов ✓Диалоги с экспертами ✓ Онлайн и офлайн участие →"
                />
            </Helmet>

            <main className="main">
                <Intro animate={animate} />
                <Controller>
                    {/* {/* removed after 20.10 */}
                    {/* <ConferenceInNumbers /> */} 
                    {/* <Broadcast className="Main__Broadcast" withBackwordBtn={false}/> */}
                    <Events />
                    <ProgramSchedule />
                    <OnlineHub />
                    {/* removed after 20.10 */}
                    {/* <Speakers /> */}
                    {/* <ChooseTariff /> */}
                    <div className="Main__Layer">
                        <Invitation />
                        {/* removed after 20.10 */}
                        {/* <Consultation /> */}
                        {/* <Methodologists /> */}
                        <Divider theme="dark" />
                        <VideoArchive />
                        <Divider theme="dark" />
                        <PhotoArchive />
                    </div>
                    {/* removed after 20.10 */}
                    {/* <Faq /> */}
                    {/* <MediaPartners /> */}
                    <Share />
                </Controller>
            </main>
        </>
    );
};

export default Main;
