import useMedia from "hooks/useMedia";

import Container from "components/Container";
import Headline from "components/Headline";
import Typography from "components/Typography";
import Button from "components/Button";
import OnlineHubDekor from "./components/OnlineHubDekor";
import OnlineHubList from "./components/OnlineHubList";

import { ReactComponent as ArrowIconLink } from "./assets/icon-arrow-link.svg";

import "./index.scss";

const OnlineHub = () => {
    const { isMobile } = useMedia();

    return (
        <div className="OnlineHub">
            <Container className="OnlineHub__Container">
                <Headline className="OnlineHub__Title" level="h5" theme="dark">
                    Лаборатория EduTech
                </Headline>
                {!isMobile ? (
                    <div className="OnlineHub__SubTitle">
                        Три удобных сервиса для создания{" "}
                        <span className="OnlineHub__SubTitleDekorLine">
                            вовлекающего и&nbsp;результативного обучения
                        </span>
                        . Доступна всем на&nbsp;сайте СберУниверситета.
                    </div>
                ) : (
                    <div className="OnlineHub__SubTitle">
                        Три удобных сервиса <br /> для создания&nbsp;
                        <span className="OnlineHub__SubTitleDekorLine OnlineHub__SubTitleDekorLine--FirstLine">
                            вовлекающего
                        </span>
                        <span className="OnlineHub__SubTitleDekorLine OnlineHub__SubTitleDekorLine--Second">
                            и&nbsp;результативного обучения
                        </span>
                        . <br /> Доступна всем на&nbsp;сайте СберУниверситета.
                    </div>
                )}
                <OnlineHubList />
                {!isMobile ? (
                    <div className="OnlineHub__Text">
                        <Typography color="light" gradient component="p" className="OnlineHub__TextIntro">
                            <span>Воспользуйтесь коллекцией</span>
                        </Typography>
                        <p>
                            методик обучения, функциональными приложениями или обзором{" "}
                            <span className="OnlineHub__TextGradient">передовых&nbsp;подходов</span>{" "}
                            развития&nbsp;персонала
                        </p>
                        <OnlineHubDekor />
                    </div>
                ) : (
                    <div className="OnlineHub__Text">
                        <Typography color="light" gradient component="p" className="OnlineHub__TextIntro">
                            <span>Воспользуйтесь</span>
                        </Typography>
                        <br />
                        <p>
                            коллекцией методик обучения, функциональными приложениями или&nbsp;обзором{" "}
                            <span className="OnlineHub__TextGradient">передовых&nbsp;подходов</span>{" "}
                            развития&nbsp;персонала
                        </p>
                        <OnlineHubDekor />
                    </div>
                )}
                <div className="OnlineHub__WrapButton">
                    <Button
                        className="OnlineHub__Link"
                        icon={<ArrowIconLink />}
                        iconRight
                        target="_blank"
                        text="Перейти"
                        href="https://sberuniversity.ru/edutech-club/lab/"
                    />
                </div>
            </Container>
        </div>
    );
};

export default OnlineHub;
