import React from "react";
import AccommodationInfo from "@/components/AccommodationInfo";
import PlanDescription from "../common/PlanDescription";
import PlanFeatures from "../common/PlanFeatures";
import PlanPrice from "../common/PlanPrice";
import "./index.scss";
import { useUser } from "@/context/UserContext";
import { TariffType } from "@/types/registration";
import { FullTimePlanInfo } from "./FullTimePlanInfo";

const features = [
    {
        title: "общение",
        description: "Нетворкинг и погружение в\nпрофессиональное\nсообщество",
    },
    {
        title: "общение",
        description:
            "Посещение выставки EdTech-продуктов, знакомство с сервисами Сбера и книжная выставка-ярмарка от Библиотеки Сбера",
    },
    {
        title: "комфорт",
        description: "Обед и кофе-брейки",
    },
    {
        title: "комфорт",
        description: "Трансфер до и от кампуса,\nпарковка",
    },
    {
        title: "комфорт",
        description: "Прогулки и экскурсии по кампусу",
    },
    {
        title: "кое-что новое",
        description: "Пакет участника с подарками\nот сервисов Сбера и\nСберУниверситета",
    },
    {
        title: "кое-что новое",
        description:
            "Индивидуальные менторские консультации от методологов СберУниверситета (по записи, количество мест ограничено)",
    },
];

const InfoTexts: { [key in TariffType]: string } = {
    from_sber: "Вы зарегистированы как «Я из Сбера».",
    from_education: "Вы зарегистированы как «Я из образования».",
    common: "Вы зарегистированы как «Общий тариф».",
    free: "Вы зарегистированы как «Онлайн участие».",
    vip: "Вы зарегистированы как «VIP».",
};

const FullTimeParticipationPlan = ({ showRegistrationInfo = true }) => {
    const { userInfo } = useUser();
    return (
        <div className="FullTimeParticipationPlan">
            <PlanDescription
                text={
                    "Все бонусы и атмосфера насыщенного мероприятия - знакомства,\nобмен опытом, подарки и масса новых впечатлений."
                }
                theme="dark"
            />

            {userInfo?.info.format === "offline" && showRegistrationInfo && (
                <FullTimePlanInfo className="FullTimeParticipationPlan__Info">
                    {InfoTexts[userInfo.info.tariff]}
                </FullTimePlanInfo>
            )}

            <PlanFeatures features={features} theme="dark" />

            <AccommodationInfo className="FullTimeParticipationPlan__Accommodation" />

            {userInfo?.info.format !== "offline" && (
                <>
                    {/* @ts-ignore */}
                    <PlanPrice participationFormat="offline" />
                </>
            )}
        </div>
    );
};

export default FullTimeParticipationPlan;
