import useMedia from '@/hooks/useMedia';
import Container from '@/components/Container';
import { Scene } from "react-scrollmagic";
import Advantage from "@/components/Advantage";
import Headline from "@/components/Headline";
import Typography from '@/components/Typography';
import Quote from '@/components/Quote';
import './index.scss';

const Advantage_Data = [
    'Как в условиях технологического прогресса сохранить мыслительные и креативные способности?',
    'Как гармонично объединить потенциал естественного и искусственного интеллектов?',
    'Чему и как учиться, чтобы стать человеком будущего уже сегодня?',
    'Как технологии меняют сферу обучения в целом и сделаем ли мы скачок благодаря развитию ИИ и нейросетей?'
];

const EventsBoxTitle = "Наша \nконференция —"

const EventsAdvantages = () => {
    const { isMobile, isTablet } = useMedia();

    const renderAdvantages = () => {
        return Advantage_Data.map(text => (
            <div className="Events__Advantage" key={text}>
                <Advantage text={text} />
            </div>
        ))
    }

    return (
        <section>
            <Container>
                <div className="Events__Box">
                    <Scene
                        classToggle='Animate'
                        offset={-500}
                    >
                        <div className="Events__Box-Title">
                            <Headline level='h3'>{EventsBoxTitle}</Headline>
                        </div>
                    </Scene>
                    <Scene
                        classToggle='Animate'
                        offset={-400}
                    >
                        <div className="Events__Box-Title">
                            <Headline level='h3' gradient>{isMobile || isTablet ? "пространство," : "пространство, где будущее"}</Headline>
                        </div>
                    </Scene>
                    <Scene
                        classToggle='Animate'
                        offset={-300}
                    >
                        <div className="Events__Box-Title">
                            <Headline level='h3' gradient>
                                {isMobile || isTablet ?
                                    "где будущее\nуже наступило"
                                    :
                                    "уже наступило"
                                }
                            </Headline>
                        </div>
                    </Scene>
                </div>
            </Container>
            <Container>
                <Scene
                    classToggle='Animate'
                    offset={-100}
                >
                    <div
                        className="Events__Animate"
                    >
                        <Quote
                            quote={'Фантастика часто рисует, как в один прекрасный день человеческое\nобщество станет единым гармоничным целым, и я думаю, что не стоит\nждать прибытия инопланетян, чтобы этот день наступил'}
                            author='Лю Цысинь'
                            book=', роман «Задача трех тел»'
                        />
                    </div>
                </Scene>
                <Scene
                    classToggle='Animate'
                    offset={-100}
                >
                    <div
                        className="Events__Animate"
                    >
                        <Typography
                            className="Events__Paragraph"
                            color="dark"
                            isBold
                        >
                            Вас поприветствовал цифровой аватар и подвез автомобиль-беспилотник, робот&nbsp;приготовил кофе, искусственный интеллект помог стать художников за 1 минуту, а&nbsp;на наших сессиях вас ждали увлекательные беседы о важных вопросах будущего, которое уже наступило
                        </Typography>
                    </div>
                </Scene>
            </Container>
            <Container className={isMobile && 'Event__container-mobile'}>
                <section className="Events__Section Events__Section--Advantages">
                    <Scene
                        classToggle='Animate'
                        duration={1000}
                        offset={-100}
                    >
                        <div className="Events__Advantages">
                            {renderAdvantages()}
                        </div>
                    </Scene>
                </section>
            </Container>
            <Container>
                <Scene
                    classToggle='Animate'
                    offset={-200}
                >
                    <div
                        className="Events__Animate"
                    >
                        <Quote
                            quote={'Каждый человек — маг в душе, но он становится магом только тогда,\nкогда начинает меньше думать о себе и больше о других'}
                            author='Аркадий и Борис Стругацкие'
                            book=', роман «Понедельник начинается в субботу»'
                        />
                    </div>
                </Scene>
            </Container>
        </section>
    )
}

export default EventsAdvantages;