import classNames from 'classnames';
import { ReactComponent as QuoteMark } from './assets/ic_filled_quotation_marks.svg';
import './index.scss';

type Props = {
    className?: string,
    quote: string,
    author: string,
    book: string,
}

const Quote: React.FC<Props> = ({
    className,
    quote,
    author,
    book,
}) => {
    return (
        <figure className={classNames(className, {
            "Quote": true
        })}>
            <blockquote
                className='Quote__Text'
            >
                <QuoteMark
                    className='Quote__Before'
                />

                {quote}

                <QuoteMark
                    className='Quote__After'
                />
            </blockquote >
            <figcaption
                className='Quote__author'
            >
                <strong>{author}</strong>{book}
            </figcaption>
        </figure >
    )
}

export default Quote;