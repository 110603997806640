import Headline from '@/components/Headline';
import Container from '@/components/Container';
import SliderBtn from '@/components/SliderBtn';
import PauseIcon from "./assets/pause-icon.png";
import VideoIcon from "./assets/video-icon.png";
import { VIDEO_ARCHIVE } from '@/constants/videoArchive';
import classNames from 'classnames';
import VideoPlayer from '@/components/VideoPlayer';
import useMedia from '@/hooks/useMedia';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import "./index.scss";


const VideoArchive: React.FC = () => {
    const { isMobile } = useMedia();

    const renderSlides = VIDEO_ARCHIVE?.map((el, index) => {
        return (
            <SwiperSlide key={index} className='VideoArchive__Slide-Custom'>
                <VideoPlayer videoLink={el.videoLink} />
            </SwiperSlide>
        )
    })

    const swiperParams = {
        slidesPerView: 1,
        loop: true,
        modules: [Navigation, Pagination],
        pagination: {
            el: '.VideoArchive__Slider-Pagination',
            clickable: true
        },
        navigation: {
            prevEl: '.VideoArchive__Slider-Prev',
            nextEl: '.VideoArchive__Slider-Next',
        },
    };

    return (
        <section className='VideoArchive'>
            <Container className="VideoArchive__container">
                <img className='VideoArchive__Decoration-01' src={VideoIcon} alt="Video Icon" />
                <img className='VideoArchive__Decoration-02' src={PauseIcon} alt="Pause Icon" />
                <Headline
                    className='VideoArchive__Headline'
                    level='h2'
                    theme='dark'
                >
                    Как это было
                </Headline>

                <div className="VideoArchive__Slider-Container">
                    <SliderBtn
                        className={classNames({
                            'VideoArchive__Slider-Prev': true,
                            'VideoArchive__Hide-Btn': isMobile,
                        })}
                        arrowDiraction='left'
                    />
                    <Swiper
                        className='VideoArchive__Swiper'
                        {...swiperParams}
                    >
                        {renderSlides}
                    </Swiper>
                    <SliderBtn
                        className={classNames({
                            'VideoArchive__Slider-Next': true,
                            'VideoArchive__Hide-Btn': isMobile,
                        })}
                        arrowDiraction='right'
                    />
                </div>
                <div className="VideoArchive__Slider-Pagination"></div>
                <div className={classNames({
                    'VideoArchive__Slider-Btns': true,
                    'VideoArchive__Hide-Btn': !isMobile,
                })}>
                    <SliderBtn className='VideoArchive__Slider-Prev' arrowDiraction='left' />
                    <SliderBtn className='VideoArchive__Slider-Next' arrowDiraction='right' />
                </div>
            </Container>
        </section>
    )
}

export default VideoArchive;