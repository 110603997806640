import React from 'react';
import {ReactComponent as Close} from "./icons/error-message.svg";

const CloseIcon = () => {
    return (
        <div style={{
            borderRadius: '50%',
            boxShadow: '0px 2.5px 5px 0px rgba(255, 103, 103, 0.80) inset, -2.5px 0px 5px 0px rgba(255, 103, 103, 0.80) inset'
        }}><Close/></div>
    );
};

export default CloseIcon;