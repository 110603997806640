import EduTech from "@/assets/icons/edutech.png";
import { ReactComponent as Telegram } from "@/assets/icons/telegram.svg";
import { ReactComponent as VK } from "@/assets/icons/vk.svg";
import { ReactComponent as Envelope } from "@/assets/icons/envelope.svg";
import { SUPPORT_EMAIL, SUPPORT_TELEGRAM } from "@/constants/common";

export const SOCIAL_TELEGRAM = {
    title: "СберУниверситет в Telegram",
    link: "https://t.me/sber_university",
    icon: <Telegram />,
};
export const SOCIAL_VK = {
    title: "СберУниверситет в ВКонтакте",
    link: "https://vk.com/sber_university",
    icon: <VK />,
};

export const SOCIAL_EDUTECH = {
    title: "EduTech Club в Telegram",
    link: "https://t.me/edutech_club",
    icon: <img src={EduTech} alt="EduTech" />,
};

export const SOCIAL_SUPPORT_EMAIL = {
    title: SUPPORT_EMAIL,
    link: SUPPORT_EMAIL,
    icon: <Envelope />,
};

export const SOCIAL_SUPPORT_TELEGRAM = {
    title: "@help_bcho23",
    link: SUPPORT_TELEGRAM,
    icon: <Telegram />,
};
