import React from "react";
import { openPaymentAcquire } from "@/pages/SignUp/registrations";
import useStepper from "@/hooks/useStepper";
import RegistrationPrevBtn from "@/components/RegistrationPrevBtn";
import FormBadge from "@/pages/SignUp/common/FormBadge";
import AuthorizationFormHeader from "@/components/AuthorizationFormHeader";
import FormStepper from "@/pages/SignUp/common/FormStepper";
import PromoCode from "@/pages/SignUp/common/PromoCode";
import BasicInformation from "@/pages/SignUp/common/BasicInformation";
import LocationInformation from "@/pages/SignUp/common/LocationInformation";
import ActivityInformation from "@/pages/SignUp/common/ActivityInformation";
import TransportInformation from "@/pages/SignUp/common/TransportInformation";
import AccommodationChoice from "@/pages/SignUp/common/AccommodationChoice";
import TicketPayment from "@/pages/SignUp/common/TicketPayment";
import { formHeaderTitles, Steps } from "@/pages/SignUp/FromEducation";
import { useChangeTariffs } from "@/pages/ChangeTariff/change-tariffs";
import { RequireOfflineFormat } from "@/pages/ChangeTariff/RequireOfflineFormat";
import { changeTariffEducation } from "@/Schema/changeTariffSchema";
import { EducationWarn } from "@/pages/SignUp/FromEducation/EducationWarn";
import { EducationAccomodationNote } from "@/pages/SignUp/FromEducation/EducationAccomodationNote";
import { toastNotifications } from "@/components/Toastify";

export const STEPS_CHAIN = [
    Steps.PROMO_CODE,
    Steps.BASE_INFO,
    Steps.LOCATION_INFO,
    Steps.ACTIVITY_INFO,
    Steps.TRANSPORT_INFO,
    Steps.ACCOMODATION_INFO,
    Steps.PAYMENT_INFO,
];

export const TariffEducation: React.FC = () => {
    const formik = useChangeTariffs({
        values: {
            format: "offline",
            tariff: "from_education",
        },
        validationSchema: (initialValues) => changeTariffEducation(initialValues),
        onSuccess: async (values) => {
            if (values.legal_type === "individual") {
                await openPaymentAcquire();
            } else {
                toastNotifications.payment("accepted");
            }
        },
    });

    const { currStep, nextStep, prevStep } = useStepper(STEPS_CHAIN);
    const title = formHeaderTitles[currStep];
    const showSubtitle = Boolean(currStep === Steps.PROMO_CODE || currStep === Steps.BASE_INFO);

    return (
        <>
            {(currStep !== Steps.PAYMENT_INFO || formik.values.legal_type === "legal") && <RequireOfflineFormat />}

            {currStep !== Steps.PROMO_CODE && currStep !== Steps.BASE_INFO && <RegistrationPrevBtn toPrev={prevStep} />}

            <FormBadge text="Я из образования" />

            <AuthorizationFormHeader
                className="FromEducation__Header"
                title={title}
                subtitle={showSubtitle && "Здравствуйте!"}
                subtitleOnTop
            />

            {currStep === Steps.PROMO_CODE && <EducationWarn />}

            {currStep === Steps.ACCOMODATION_INFO && <EducationAccomodationNote />}

            <FormStepper steps={6} startFrom={1} currentStep={currStep} />

            {currStep === Steps.PROMO_CODE ? (
                <PromoCode
                    tariff={formik.values.tariff}
                    onAcceptCode={(codeId) => {
                        formik.setFieldValue("promo_code_id", codeId);
                        nextStep();
                    }}
                />
            ) : (
                <form className="FromEducation__Form" autoComplete="off" onSubmit={formik.handleSubmit}>
                    {currStep === Steps.BASE_INFO && (
                        <BasicInformation formik={formik} disableEmail nextStep={nextStep} />
                    )}

                    {currStep === Steps.LOCATION_INFO && <LocationInformation formik={formik} nextStep={nextStep} />}

                    {currStep === Steps.ACTIVITY_INFO && <ActivityInformation formik={formik} nextStep={nextStep} />}

                    {currStep === Steps.TRANSPORT_INFO && <TransportInformation formik={formik} nextStep={nextStep} />}

                    {currStep === Steps.ACCOMODATION_INFO && (
                        <AccommodationChoice formik={formik} nextStep={nextStep} />
                    )}

                    {currStep === Steps.PAYMENT_INFO && <TicketPayment formik={formik} discount />}
                </form>
            )}
        </>
    );
};
