import { useState } from "react";
import { ReactComponent as NotVisibleIcon } from "./assets/not-visible.svg";
import { ReactComponent as VisibleIcon } from "./assets/visible.svg";
import classNames from "classnames";
import "./index.scss";

export type RegistrationInputProps = {
    className?: string;
    type?: string;
    name?: string;
    id?: string;
    value?: any;
    placeholder?: string;
    autoComplete?: string;
    label?: string;
    error?: boolean;
    disabled?: boolean;
    onChange?: (e: any) => void;
    onBlur?: (e: any) => void;
};

const RegistrationInput = (props: RegistrationInputProps) => {
    const {
        type,
        className,
        name,
        id,
        value,
        placeholder,
        label,
        error = false,
        disabled = false,
        autoComplete,
        onBlur,
        onChange,
    } = props;

    const [showPassword, setShowPassword] = useState(false);

    const handleChangePasswordVisible = (e: any) => {
        e.stopPropagation();
        setShowPassword((prev) => !prev);
    };

    const inputClass = classNames("RegistrationInput__Input", {
        "RegistrationInput__Input--Error": error,
    });

    return (
        <div className={classNames(className, "RegistrationInput")}>
            <label htmlFor={id} className="RegistrationInput__Label">
                {label}
            </label>
            <div className="RegistrationInput__wrapper">
                {type !== "password" ? (
                    <input
                        className={inputClass}
                        type={type}
                        name={name}
                        id={id}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        placeholder={placeholder}
                        disabled={disabled}
                        autoComplete={autoComplete}
                        data-lpingore="ignore"
                    />
                ) : (
                    <>
                        <input
                            className={inputClass}
                            type={showPassword ? "text" : "password"}
                            name={name}
                            id={id}
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            placeholder={placeholder}
                            autoComplete={autoComplete}
                            disabled={disabled}
                            data-lpignore="true"
                            role="note"
                        />
                        <button
                            className="RegistrationInput__show-password"
                            type="button"
                            onClick={handleChangePasswordVisible}
                            tabIndex={-1}
                        >
                            {showPassword ? <VisibleIcon /> : <NotVisibleIcon />}
                        </button>
                    </>
                )}
            </div>
        </div>
    );
};

export default RegistrationInput;
