import { NavLink, useLocation } from 'react-router-dom';
import SocialMenuBtn from "@/components/SocialMenuBtn";
import NavBtn from '@/components/NavBtn';
import useMedia from '@/hooks/useMedia';
import AuthorizationBtn from '@/components/AuthorizationBtn';
import ProfileBtn from '@/components/ProfileBtn';
import routes from '@/routes';
import { useIsAuthenticated } from 'react-auth-kit';
import { HashLink } from 'react-router-hash-link';
import { useContext } from 'react';
import { NavContext } from 'context/NavContext';
import './index.scss';

const NavBar = () => {
    const { isMobile, isTablet } = useMedia();
    const authentication = useIsAuthenticated()
    const isAuth = authentication();
    const { pathname } = useLocation();

    const { activeSection } = useContext(NavContext);
    const { program } = activeSection;

    const scrollToSection = (element) => {
        const top = element.offsetTop - 200

        window.scrollTo({
            top,
            behavior: "instant"
        })
    }

    return (
        <nav className="Nav">
            {isMobile | isTablet ? null :
                <ul className="Nav__Items">
                    <li className="Nav__Item">
                        <NavLink
                            className={({ isActive }) => isActive && !program ? "Nav__Link active" : "Nav__Link"}
                            to={routes.conference}
                        >
                            О конференции
                        </NavLink>
                    </li>
                    <li className="Nav__Item">
                        <HashLink
                            className={program && pathname === "/" ? "Nav__Link active" : "Nav__Link"}
                            to="/#program"
                            scroll={(el) => scrollToSection(el)}
                        >
                            Программа
                        </HashLink >
                    </li>
                    <li className="Nav__Item"><NavLink className="Nav__Link" to={routes.contest}>Конкурс</NavLink></li>
                </ul>
            }

            <div className="Nav__btns">
                {isMobile || isTablet ? null : <SocialMenuBtn className="Nav__Socials" />}

                {isMobile || isTablet ? <NavBtn className="Nav__Menu" /> : null}

                {isAuth ? <ProfileBtn /> : <AuthorizationBtn />}
            </div>
        </nav>
    )
}

export default NavBar;
