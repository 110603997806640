import Button from "components/Button";
import Headline from "components/Headline";
import { ReactComponent as DownloadIcon } from './assets/download-icon.svg';
import classNames from "classnames";
import './index.scss';
import Typography from "@/components/Typography";

type Props = {
    toggleProgram: (program: "online" | "onCampus") => void,
    programFormat: "online" | "onCampus"
}

const ProgramHeading: React.FC<Props> = ({
    toggleProgram,
    programFormat
}) => {

    const isOnline = programFormat === "online";
    const isOnCampus = programFormat === "onCampus";

    return (
        <div className="ProgramHeading">
            <div className="ProgramHeading__Wrapper">
                <Headline
                    className="ProgramHeading__Title"
                    level="h2"
                    theme="light"
                >
                    Конференция<br/>в записи
                    {/* next version */}
                    {/* <Button
                        className="ProgramHeading__Download-link"
                        text="Скачать PDF"
                        tag="a"
                        icon={<DownloadIcon />}
                        iconRight
                        type="transparent"
                    /> */}
                </Headline>
                {/* removed after 20.10 */}
                {/* <div className="ProgramHeading__Btns">
                    <Button
                        className={classNames({
                            "ProgramHeading__Btn": true,
                            "ProgramHeading__Btn--not-active": !isOnCampus,
                        })}
                        tag="button"
                        text="очно"
                        BtnType="button"
                        onClick={() => toggleProgram("onCampus")}
                        type={!isOnCampus ? "colored-borders" : undefined}
                        gradient={isOnCampus}
                    />
                    <Button
                        className={classNames({
                            "ProgramHeading__Btn": true,
                            "ProgramHeading__Btn--not-active": !isOnline,
                        })}
                        tag="button"
                        text="онлайн"
                        BtnType="button"
                        onClick={() => toggleProgram("online")}
                        type={!isOnline ? "colored-borders" : undefined}
                        gradient={isOnline}
                    />
                </div> */}
            </div>
            {/* removed after 20.10 */}
            {/* <p
                className="ProgramHeading__Subtitle"
            >
                {"Вдохновлено идеями\nписателей-фантастов"}
            </p> */}
        </div>

    )
}

export default ProgramHeading;