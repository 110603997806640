import { useContext, useEffect, useState } from "react";
import { BroadcastContext } from "@/context/BroadcastContext";
import { useLocation, useSearchParams } from 'react-router-dom';
import Button from "@/components/Button";
import Tooltip from "@/components/Tooltip";
import classNames from "classnames";
import StreamTooltip from "./StreamTooltip";
import routes from "@/routes";
import useMedia from "@/hooks/useMedia";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "./index.scss";

type Props = {
    isForModerators: boolean,
}

const BroadcastHeader: React.FC<Props> = ({ isForModerators }) => {
    const { isTablet, isMobile } = useMedia();

    const { activeBroadcast, toggleBroadcast, availableBroadcasts } = useContext(BroadcastContext);
    const [searchParams, setSearchParams] = useSearchParams();

    const { pathname } = useLocation();
    const [isInBroadcastPage, setIsInBroadcastPage] = useState<boolean>(false);

    const handleClick = (streamNum: number) => {
        searchParams.set('stream', `${streamNum}`);
        setSearchParams(searchParams)

        toggleBroadcast(streamNum);

        // open new tab when click !
        if (!isInBroadcastPage && !isForModerators) {
            const baseUrl = process.env.PUBLIC_URL + '/broadcast';
            const newTabURL = baseUrl + window.location.search;
            window.open(newTabURL, '_blank');
        }
    }

    useEffect(() => {
        const streamNum = searchParams.get('stream');

        if (streamNum && availableBroadcasts.length > 0) {
            toggleBroadcast(+streamNum)
        }

    }, [searchParams, availableBroadcasts]);

    useEffect(() => {

        if (pathname.includes(`${routes.broadcast}`)) {
            setIsInBroadcastPage(true);
        } else {
            setIsInBroadcastPage(false)
        }

    }, [pathname])


    const renderBtn = availableBroadcasts?.map((el, index) => {
        const streamNum = index + 1;
        const streamId = el.id;

        return (
            <SwiperSlide
                key={index}
                className="BroadcastHeader__Custom-Slide"
            >
                <Tooltip
                    className="BroadcastHeader__Tooltip"
                    text={<StreamTooltip
                        title="Подробнее о стриме:"
                        description={el.description}
                    />}
                    diraction="top"
                    disabled={isTablet || isMobile ? true : false}
                >
                    <Button className={classNames({
                        "BroadcastHeader__Btn": true,
                        "BroadcastHeader__Btn--disabled": false,
                        "BroadcastHeader__Btn--active": activeBroadcast?.id === streamId,
                    })}
                        tag="button"
                        BtnType="button"
                        size='lg'
                        type='transparent'
                        disabled={false}
                        isWide
                        onClick={() => handleClick(streamNum)}
                    >
                        <div className="BroadcastHeader__Title-container">
                            <span className="BroadcastHeader__Subtitle">Стрим&nbsp;{streamNum}</span>
                            <p className="BroadcastHeader__Title">{el.title}</p>
                        </div>
                    </Button>
                </Tooltip>
            </SwiperSlide>
        )
    });


    return (
        <div className="BroadcastHeader">
            {isMobile ? (
                <Swiper
                    className="BroadcastHeader__Swiper"
                    slidesPerView="auto"
                    spaceBetween={8}
                    slidesOffsetBefore={16}
                    slidesOffsetAfter={16}
                    centeredSlides
                    centeredSlidesBounds
                    slideToClickedSlide
                >
                    {renderBtn}
                </Swiper>
            ) : (
                <div className="BroadcastHeader__Inner">
                    {renderBtn}
                </div>
            )}
        </div>
    )
}

export default BroadcastHeader;