import Button from '@/components/Button';
import Icon from '../icons/success-payment.png';
import IconFailed from '../icons/failed-payment.png';
import SuccessIconShadow from '../icons/success-payment-shadow.png';
import FailedIconShadow from '../icons/failed-payment-shadow.png';
import EmojiSmile from '../icons/emoji-smile.png';
import { ReactComponent as CloseBtn } from '../icons/close-btn.svg';
import './index.scss';

/**
 * @param {('accepted' | 'success' | 'failed')} status
 */

const PaymentToast = ({ status }) => {
    const title = status === "failed" ? "К сожалению, оплата\nне прошла" :
        status === "success" ? "Оплата прошла успешно" : "Ваша заявка принята";

    const failedText = () => {
        return (
            <p className='PaymentToast__Text'>
                {"Вы по преждему можете участвоват\nв онлайн-формате"}
                <img className='PaymentToast__Emoji' src={EmojiSmile} alt="emoji smile" width={20} height={20} />
            </p>
        )
    }

    const successText = () => {
        return (
            <p className='PaymentToast__Text'>
                {"Мы отправили вам подтверждение\nна электронную почту"}
            </p>
        )
    }

    const acceptedText = () => {
        return (
            <div className='PaymentToast__Text-container'>
                <p className='PaymentToast__Text'>
                    {"Пока ваша заявка на рассмотрении — вы будете\nзаписаны на онлайн-участие"}
                </p>
                <span>
                    <p className='PaymentToast__Text PaymentToast__Text--gray'>
                        {"Как только мы рассмотрим заявку — с вами\nсвяжется менеджер для проведения оплаты."}
                    </p>
                    <p className='PaymentToast__Text PaymentToast__Text--green'>
                        {"После оплаты ваш тариф будет изменён"}
                    </p>
                </span>
            </div>
        )
    }

    return (
        <>
            <div className='PaymentToast'>
                <div className='PaymentToast__Icon-container'>
                    <img className='PaymentToast__Icon' src={status !== "failed" ? Icon : IconFailed} alt="icon" />
                    <img className='PaymentToast__Shadow' src={status !== "failed" ? SuccessIconShadow : FailedIconShadow} alt="shadow" />
                </div>
                <h2 className='PaymentToast__Title'>{title}</h2>

                {
                    status === 'accepted' ?
                        acceptedText()
                        :
                        status === 'success' ?
                            successText()
                            :
                            failedText()
                }

                <Button
                    className="PaymentToast__Btn"
                    tag="button"
                    text="Хорошо"
                    size='lg'
                    type='colored'
                    isWide
                    gradient
                />
            </div>
            <CloseBtn className='Payment__CloseBtn' />
        </>
    )
}

export default PaymentToast;