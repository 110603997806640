import { useState } from "react";

const useToggle = (initiValue = false) => {
    const [isActive, setIsActive] = useState(initiValue);

    const toggle = () => {
        setIsActive((prev) => !prev);
    };

    return [isActive, toggle] as const;
};

export default useToggle;
