import React, { useMemo } from "react";

import Button from "@/components/Button";
import Tooltip from "@/components/Tooltip";
import { FormikErrorWrap } from "@/components/FormikErrorWrap";
import RegistrationInput from "@/components/RegistrationInput";
import { hasError } from "@/utils/formik";
import { FormikData } from "@/types/formik";

import "./index.scss";

const SberInformation = ({ formik, nextStep }: { formik: FormikData<any>; nextStep: () => void }) => {
    const isValid = useMemo(() => {
        return !(hasError(formik, "place_of_work", false) || hasError(formik, "position", false));
    }, [formik]);

    return (
        <>
            <div className="SberInformation__inputs">
                <FormikErrorWrap formik={formik} name="place_of_work">
                    <RegistrationInput
                        className="SberInformation__input"
                        type="text"
                        id="place_of_work"
                        name="place_of_work"
                        placeholder="Наименование вашей компании"
                        label="Наименование компании"
                        error={hasError(formik, "place_of_work")}
                        value={formik.values.place_of_work}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </FormikErrorWrap>

                <FormikErrorWrap formik={formik} name="position">
                    <RegistrationInput
                        className="SberInformation__input"
                        type="text"
                        id="position"
                        name="position"
                        placeholder="Ваша должность"
                        label="Должность"
                        value={formik.values.position}
                        error={hasError(formik, "position")}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </FormikErrorWrap>
            </div>

            <Tooltip
                className="SberInformation__Tooltip"
                text={"Необходимо заполнить все поля"}
                diraction="top"
                disabled={isValid}
            >
                <Button
                    className="SberInformation__Btn"
                    tag="button"
                    onClick={nextStep}
                    text="Далее"
                    size="lg"
                    type="colored"
                    isWide
                    gradient
                    disabled={!isValid}
                />
            </Tooltip>
        </>
    );
};

export default SberInformation;
