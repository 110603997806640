import React from "react";
import { NavLink } from 'react-router-dom'
import { routesNavigationProfile } from "@/constants/navigations";
import useDownloadCertificate from "@/hooks/useDownloadCertificate";
import classNames from "classnames";
import './index.scss'

const TabsNavigation = () => {
  const { isDownloadActive, showWarning } = useDownloadCertificate();

  const renderElements = routesNavigationProfile.map(link => {
    const isActive = link.navType === "certificates" ? isDownloadActive : true;
    const TagElement = isActive ? NavLink : "span";

    return (
      <li className="TabsNavigation__Item" key={link.id}>
        <TagElement
          className={classNames({
            'TabsNavigation__Link': true,
            'TabsNavigation__Link--disabled': !isActive,
          })}
          to={link.route}
          end={isActive ? true : undefined}
          onClick={isActive ? undefined : showWarning}
        >
          {link.labelDeviceTouch}
        </TagElement>
      </li>
    )
  })

  return (
    <>
      <nav className="TabsNavigation">
        <ul className="TabsNavigation__List">
          {renderElements}
          <li className="TabsNavigationItem">
            <button className={'TabsNavigation__Link TabsNavigation__Link--Logout'}>
              Выйти
            </button>
          </li>
        </ul>
      </nav>
    </>
  )
}

export default TabsNavigation