import React from 'react';
import DateLabel from "@components/DateLabel/DateLabel";
import Container from '@/components/Container';
import Button from "@components/Button";
import coreIcon from './icons/сore-Illustration.png';
import coreIconMobile from './icons/сore-Illustration-mobile.png';
import coreIconTablet from './icons/сore-Illustration-tablet.png';
import { ReactComponent as Shape } from './icons/shape.svg';
import { ReactComponent as ShapeMobile } from './icons/shape-mobile.svg';
import GreenLightLeft from './icons/green-light-left.png';
import BlueLightRight from './icons/blue-light-right.png';
import WhiteLightTopLarge from './icons/white-light-top-large.png';
import WhiteLightTopSmall from './icons/white-light-top-small.png';
import UnderLiner from "@/components/UnderLiner";
import classNames from "classnames";
import useMedia from '@/hooks/useMedia';
import { HashLink } from 'react-router-hash-link';
import routes from "@routes";
import './index.scss';

const Intro = ({
    animate,
}) => {
    const { isMobile, isTablet } = useMedia();

    const scrollToSection = (element) => {
        element.scrollIntoView({
            block: "top",
            behavior: "instant"
        })
    }

    return (
        <section className='Intro'>
            <picture>
                <source srcSet={WhiteLightTopSmall} media='(max-width: 575px)' />
                <img className="Intro__Decoration01" src={WhiteLightTopLarge} alt='decoration light' />
            </picture>
            <img className="Intro__Decoration02" src={BlueLightRight} alt='decoration light' />
            <img className="Intro__Decoration03" src={GreenLightLeft} alt='decoration light' />
            <Container className="Intro__Wrapper">
                <div className={
                    classNames({
                        "Intro__Inner": true,
                        [`Intro--Animate`]: animate,
                    })
                }>
                    <div className="Intro__Content">
                        {/* removed after 20.10 */}
                        {/* <DateLabel className="Intro__DateLabel" date={"20 октября 2023"} /> */}
                        <h1 className="Intro__Title">
                            <span className="Intro__Animated-Wrapper Intro__Animated-Wrapper--1">
                                <span className="Intro__Gradient Intro__SubTitle Intro__SubTitle-firstWord">Больше</span>
                            </span>
                            <br />
                            <span className="Intro__Animated-Wrapper Intro__Animated-Wrapper--2">
                                <span className="Intro__SubTitle">
                                    чем&nbsp;
                                </span>
                            </span>
                            <span className="Intro__Animated-Wrapper Intro__Animated-Wrapper--3">
                                <span className="Intro__SubTitle">
                                    обучение
                                </span>
                            </span>
                        </h1>
                        <h4 className="Intro__Description">
                            VII конференция СберУниверситета
                            <br />
                            <UnderLiner className={animate ? 'Animate' : ''}>
                                Будущее уже наступило: человек,&nbsp;
                            </UnderLiner>
                            дополненный технологиями
                        </h4>
                        <HashLink
                            className="Intro__HashLink"
                            to={routes.programAnchor}
                            scroll={(el) => scrollToSection(el)}
                        >
                            <Button
                                className="Intro__Button"
                                tag='button'
                                BtnType='button'
                                icon={isMobile ? <ShapeMobile /> : <Shape />}
                                text="Смотреть в записи"
                            />
                        </HashLink>
                    </div>
                    <img
                        src={isMobile ? coreIconMobile : isTablet ? coreIconTablet : coreIcon}
                        className="Intro__Icon"
                        onClick={() => { }}
                        alt="Сбер университет"
                    />
                </div>
            </Container>
        </section>
    );
};

export default Intro;
