import { createContext, useState } from "react";

type Props = {
    children: React.ReactNode
}

type NavType = {
    program: boolean,
}

type NavContextType = {
    activeSection: NavType,
    handleActiveSection: (navStatus: NavType) => void
}

const NavContext = createContext<NavContextType>({
    activeSection: {
        program: false,
    },
    handleActiveSection: (navStatus) => { }
})

const NavProvider: React.FC<Props> = ({ children }) => {
    const [activeSection, setActiveSection] = useState<NavType>({
        program: false,
    })

    const handleActiveSection = (navStatus: NavType) => {
        setActiveSection(navStatus)
    }

    return (
        <NavContext.Provider value={{ activeSection, handleActiveSection }}>
            {children}
        </NavContext.Provider>
    )
}

export { NavContext, NavProvider }