import React, { ReactNode } from "react";
import { ReactComponent as LogoIcon } from "./assets/sign-form-logo.svg";
import LogoIconShadow from "./assets/sign-form-logo-shadow.png";
import classNames from "classnames";
import "./index.scss";

const AuthorizationFormHeader = ({
    title,
    subtitle,
    subtitleOnTop,
    className,
    icon,
}: {
    className?: string;
    title: ReactNode;
    subtitle: ReactNode;
    subtitleOnTop?: boolean;
    icon?: ReactNode;
}) => {
    return (
        <div className={classNames(className, "AuthorizationFormHeader")}>
            <div className="AuthorizationFormHeader__Logo">
                <span className="AuthorizationFormHeader__Logo-icon">{icon ? icon : <LogoIcon />}</span>
                <img className="AuthorizationFormHeader__Logo-shadow" src={LogoIconShadow} alt="logo shadow" />
            </div>
            <h2 className="AuthorizationFormHeader__Title">{title}</h2>
            <p
                className={classNames({
                    AuthorizationFormHeader__Subtitle: true,
                    "AuthorizationFormHeader__Subtitle--top": subtitleOnTop,
                })}
            >
                {subtitle}
            </p>
        </div>
    );
};

export default AuthorizationFormHeader;
