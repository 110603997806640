import React, { PropsWithChildren } from "react";
import classNames from "classnames";
import "./index.scss";

type TypographyProps = PropsWithChildren<{
    color: "grey" | "dark" | "light";
    isBold?: boolean;
    size?: "xs" | "sm" | "md" | "lg" | "xlg";
    gradient?: boolean;
    className?: string;
    component?: "p" | "span";
}>;

const Typography: React.FC<TypographyProps> = ({
    children,
    color,
    isBold,
    size = "md",
    gradient = false,
    className,
    component = "p",
}) => {
    const Tag = component;
    return (
        <Tag
            className={classNames(className, "Typography", {
                [`Typography--IsBold`]: isBold,
                [`Typography--${color}`]: color,
                [`Typography--${size}`]: size,
                [`Typography--Gradient`]: gradient,
            })}
        >
            {children}
        </Tag>
    );
};

export default Typography;
