
import BlockedIcon from "./assets/blocked-icon.png";
import BlockedShadow from "./assets/blocked-shadow.png";
import "./index.scss";

const UserBlocked: React.FC = () => {
    return (
        <div className="UserBlocked">
            <div className="UserBlocked__Icon-container">
                <img className="UserBlocked__Icon" src={BlockedIcon} alt="blocked" width={80} height={80}/>
                <img className="UserBlocked__Icon-shadow" src={BlockedShadow} alt="blocked"/>
            </div>
            <h3 className="UserBlocked__Title">Ваш аккаунт заблокирован</h3>
            <p className="UserBlocked__Text">В связи с нарушением правил ваш аккаунт был заблокирован. Вы больше не можете писать сообщения и задавать вопросы, ваши предыдущие сообщения были удалены.</p>
        </div>
    )
}

export default UserBlocked;