import React from "react";
import classNames from "classnames";
import { Accordion, AccordionItem } from "@szhsin/react-accordion";
import FullTimeParticipationPlan from "./FullTimeParticipationPlan";
import OnlineParticipationPlan from "./OnlineParticipationPlan";
import PlanHeader from "./common/PlanHeader";
import { useUser } from "@/context/UserContext";
import "./index.scss";
import { useCheckPaymentStatus } from "@pages/Profile/ProfilePaymentInfo/useCheckPaymentStatus";

const TariffsPlans = ({ className = "" }) => {
    const { userInfo } = useUser();

    const { data: isNotPayedComplete } = useCheckPaymentStatus();
    const isLegalNotPayed =
        userInfo?.info.format === "offline" && userInfo?.info.legal_type === "legal" && isNotPayedComplete;

    const isOnline = userInfo?.info.format === "online" || isLegalNotPayed;
    const isOffline = userInfo?.info.format === "offline" && !isLegalNotPayed;

    return (
        <Accordion className={classNames(className, "TariffsPlans")} transition transitionTimeout={500}>
            <AccordionItem
                className={classNames("TariffsPlans__Light", {
                    "TariffsPlans__Light--selected": isOnline,
                })}
                header={<PlanHeader type="light" title="Онлайн-участие" currentPlan={isOnline} />}
            >
                <OnlineParticipationPlan showOnlineBtn={isOffline} />
            </AccordionItem>
            <AccordionItem
                className={classNames("TariffsPlans__Dark", {
                    "TariffsPlans__Dark--selected": isOffline,
                })}
                header={<PlanHeader type="dark" title="Очное участие" currentPlan={isOffline} />}
            >
                <FullTimeParticipationPlan showRegistrationInfo={isOffline} />
            </AccordionItem>
        </Accordion>
    );
};

export default TariffsPlans;
