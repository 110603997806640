

import classNames from 'classnames';
import { ReactComponent as SberIconGray } from './assets/sber-icon-gray.svg';
import { ReactComponent as SberIconColored } from './assets/sber-icon-colored.svg';
import './index.scss';

const FeatureCard = ({ title, description, theme = "light" }) => {
    return (
        <div className={classNames({
            'FeatureCard': true,
            'FeatureCard--light': theme === "light",
            'FeatureCard--dark': theme === "dark",
        })}>
            <div className='FeatureCard__Header'>
                {
                    theme === "light" ?
                        <SberIconGray />
                        :
                        <SberIconColored />
                }
                <h4 className={classNames({
                    'FeatureCard__Title': true,
                    'FeatureCard__Title--gray':  theme === "light",
                    'FeatureCard__Title--colored':  theme === "dark",
                })}>{title}</h4>
            </div>
            <p className='FeatureCard__Text'>{description}</p>
        </div>
    )
}

export default FeatureCard;