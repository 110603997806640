import classNames from 'classnames';
import './index.scss';

type Props = {
    className?: string,
    icon: JSX.Element,
    title: string,
    link: string,
    linkIsEmail?: boolean,
    linkIsHidden?: boolean,
    theme: "dark" | "light",
}

const SocialItem: React.FC<Props> = ({
    className,
    icon,
    title,
    link,
    linkIsHidden,
    linkIsEmail,
    theme = "light"
}) => {
    const linkToText = !linkIsEmail && !linkIsHidden && link.split('//').length > 1 ? link.split('//')[1] : link

    return (
        <a
            className={classNames(className, {
                "SocialItem": true,
            })}
            href={linkIsEmail ? `mailto:${link}` : link}
            key={title}
            target="_blank"
        >
            <div className="SocialItem__Logo">
                {icon}
            </div>
            <div className="SocialItem__Description">
                <div className={classNames({
                    'SocialItem__Text': true,
                    'SocialItem__Text--dark': theme === "dark"
                })}>
                    {title}
                </div>
                {
                    !linkIsHidden && !linkIsEmail &&
                    <span className={classNames({
                        'SocialItem__Link': true,
                        'SocialItem__Link--dark': theme === "dark"
                    })}>
                        {linkToText}
                    </span>
                }
            </div>
        </a>
    )
}

export default SocialItem;