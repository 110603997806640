import Container from '@/components/Container';
import Headline from "@/components/Headline";
import { useMouseOnMove } from "@/hooks/useMouseOnMove";
import { Scene } from "react-scrollmagic";
import useMedia from '@/hooks/useMedia';
import Widget from "@/components/Widget";
import Divider from "@/components/Divider";
import { ReactComponent as User } from './assets/user.svg';
import { ReactComponent as UserMobile } from './assets/user-mobile.svg';
import { ReactComponent as UserEditor } from './assets/user-editor_fill.svg';
import { ReactComponent as StudentHat } from './assets/student-hat.svg';
import { ReactComponent as Heart } from './assets/heart.svg';
import { ReactComponent as HeartMobile } from './assets/heart-mobile.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import './index.scss'

const EventsWidgets = () => {
    const [handleMouseEnter, handleOnMouseLeave] = useMouseOnMove('.js-widget-event');
    const { isMobile, isTablet } = useMedia();

    const sliderBreakpoints = {
        300: {
            slidesPerView: 1.4,
        },
        576: {
            slidesPerView: 1.8,
        },
        760: {
            slidesPerView: 2.5,
        },
    };

    return (
        <section className="Events__Section Events__Section--Widget" id="speakers">
            <Container className={isMobile ? 'Event__container-mobile' : ''}>
                <Scene
                    classToggle='Animate'
                    duration={1000}
                    offset={-300}
                >
                    <div className="Events__Headline Events__Headline--2">
                        <Headline >Кого <br />приглашаем</Headline>
                    </div>
                </Scene>
            </Container>
            {isMobile | isTablet ?
                <div className="Events__Widgets" id="events-widgets">
                    <Swiper
                        spaceBetween={16}
                        breakpoints={sliderBreakpoints}
                        style={isMobile ? { paddingLeft: '8px' } : { paddingLeft: '20px' }}
                    >
                        <SwiperSlide>
                            <Widget
                                className="Events__Widget"
                                icon={<UserMobile />}
                                title="Руководителей компаний, владельцев бизнеса"
                                description="Разберем, как меняются профессии и&nbsp;требования к ним"
                                theme="green"
                            />
                        </SwiperSlide>
                        <SwiperSlide>
                            <Widget
                                className="Events__Widget"
                                icon={<UserEditor />}
                                title="Разработчиков программ и преподавателей"
                                description="Разберем актуальные подходы и методы обучения, а также сервисы, которые упрощают разработку и реализацию обучения"
                                theme="green"
                            />
                        </SwiperSlide>
                        <SwiperSlide>
                            <Widget
                                className="Events__Widget"
                                icon={<StudentHat />}
                                title="HR-директоров, ректоров и&nbsp;представителей вузов"
                                description="Обсудим актуальные навыки и&nbsp;тренды в обучении"
                                theme="green"
                            />
                        </SwiperSlide>
                        <SwiperSlide>
                            <Widget
                                className="Events__Widget"
                                icon={<HeartMobile />}
                                title="Всех, кто интересуется образованием"
                                description="Рассмотрим, как развивается российский EdTech-рынок и как технологии могут помочь в обучении"
                                theme="green"
                            />
                        </SwiperSlide>
                    </Swiper>
                </div>
                :
                <Container className={isMobile ? 'Event__container-mobile' : ''}>
                    <Scene
                        classToggle='Animate'
                        triggerElement={'#events-widgets'}
                        offset={-100}
                    >
                        <div className="Events__Widgets" id="events-widgets">
                            <Widget
                                className="Events__Widget Events__Widget--1 js-widget-event"
                                icon={<User />}
                                title="Руководителей компаний, владельцев бизнеса"
                                description="Разберем, как меняются профессии и&nbsp;требования к ним"
                                theme="lightGreen"
                                radius={440}
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleOnMouseLeave}
                            />
                            <Widget
                                className="Events__Widget Events__Widget--2 js-widget-event"
                                icon={<UserEditor />}
                                title="Разработчиков программ и преподавателей"
                                description="Разберем актуальные подходы и методы обучения, а также сервисы, которые упрощают разработку и реализацию обучения"
                                theme="green"
                                radius={525}
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleOnMouseLeave}
                            />
                            <Widget
                                className="Events__Widget Events__Widget--3 js-widget-event"
                                icon={<StudentHat />}
                                title="HR-директоров, ректоров и&nbsp;представителей вузов"
                                description="Обсудим актуальные навыки и&nbsp;тренды в обучении"
                                theme="green"
                                radius={378}
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleOnMouseLeave}
                            />
                            <Widget
                                className="Events__Widget Events__Widget--4 js-widget-event"
                                icon={<Heart />}
                                title="Всех, кто интересуется образованием"
                                description="Рассмотрим, как развивается российский EdTech-рынок и как технологии могут помочь в обучении"
                                theme="lightGreen"
                                radius={420}
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleOnMouseLeave}
                            />
                        </div>
                    </Scene>
                </Container>
            }
            <Divider theme="dark" />
        </section>
    )
}

export default EventsWidgets;