import { ReactComponent as PrevIcon } from './assets/prev-icon.svg';
import './index.scss';

const RegistrationPrevBtn = ({ toPrev }) => {
    return (
        <span className='RegistrationPrevBtn'>
            <button
                className='RegistrationPrevBtn__Btn'
                onClick={toPrev}
            >
                <PrevIcon />
            </button>
            <span className='RegistrationPrevBtn__Text'>Назад</span>
        </span>
    )
}

export default RegistrationPrevBtn;