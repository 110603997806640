import NavbarList from "../NavbarList";
import "./index.scss";

const Navbar = () => {
    return (
        <div className="Navbar">
            <nav className="Navbar__Panel">
                <NavbarList />
            </nav>
        </div>
    );
};

export default Navbar;
