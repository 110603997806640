import { useContext, useState } from "react";
import Container from "@/components/Container";
import ProgramHeading from "./ProgramHeading";
import OnCampusProgram from "./OnCampusProgram";
import OnlineProgram from "./OnlineProgram";
import GreenLightTopLeft from './assets/green-light-top-left.png';
import BlueLightTopRight from './assets/blue-light-top-right.png';
import { NavContext } from "context/NavContext";
import { useInView } from 'react-intersection-observer';
import './index.scss';

const ProgramSchedule: React.FC = () => {
    const [programFormat, setIsProgramFormat] = useState<"online" | "onCampus">("online");
    const { handleActiveSection } = useContext(NavContext);

    const { ref, inView } = useInView({
        threshold: 0,
        onChange(inView, entry) {
            if (inView) {
                handleActiveSection({ program: true })
            } else {
                handleActiveSection({ program: false })
            }
        },
    });


    return (
        <>
            <section className="ProgramSchedule" id="program" ref={ref}>
                <img className="ProgramSchedule__Decoration-01" src={GreenLightTopLeft} alt="decoration light" loading='lazy' />
                <img className="ProgramSchedule__Decoration-02" src={BlueLightTopRight} alt="decoration light" loading='lazy' />
                <Container>
                    <ProgramHeading
                        toggleProgram={setIsProgramFormat}
                        programFormat={programFormat}
                    />
                    {
                        programFormat === "onCampus" ?
                            <OnCampusProgram />
                            :
                            <OnlineProgram />
                    }
                </Container>
            </section>
        </>
    )
}

export default ProgramSchedule;