import React from "react";
import Modal from "react-modal";
import { ReactComponent as LinkIcon } from "./icons/link.svg";
import Button from "@/components/Button";

import { Winner } from "../../types";

import "./index.scss";

interface Props {
    isOpen: boolean;
    onRequestClose: () => void;
    winner?: Winner;
}

const ModalWinner: React.FC<Props> = ({ isOpen, onRequestClose, winner }) => {
    return (
        <>
            <Modal
                ariaHideApp={false}
                isOpen={isOpen}
                onRequestClose={onRequestClose}
                closeTimeoutMS={300}
                overlayClassName={"ModalWinner"}
                bodyOpenClassName={"ModalWinner__OverflowHidden"}
                className={"ModalWinner__Container"}
            >
                <div className="ModalWinner__Mask" onClick={onRequestClose}></div>
                <div className="ModalWinner__Content">
                    <button className="ModalWinner_CLoseBtn" onClick={onRequestClose}>
                        close
                    </button>
                    <div className="ModalWinner__Image">
                        <img src={winner?.image} alt={winner?.name} loading="lazy" />
                    </div>
                    <div className="ModalWinner__Name">{winner?.name}</div>
                    {winner?.description !== undefined && (
                        <div
                            className="ModalWinner__Info"
                            dangerouslySetInnerHTML={{ __html: winner.description }}
                        ></div>
                    )}
                    <Button
                        className="ModalWinner__Link"
                        tag="a"
                        iconRight={true}
                        icon={<LinkIcon />}
                        gradient={true}
                        size="lg"
                        target="_blank"
                        href={winner?.link}
                        text="Перейти на сайт"
                    />
                </div>
            </Modal>
        </>
    );
};

export default ModalWinner;
