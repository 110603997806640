import React from 'react';

import './index.scss';

const ShareItem = ({
    icon,
    onClick,
}) => {
    return (
        <a
            className="ShareItem"
            onClick={onClick}
        >
            {icon}
        </a>
    );
};

export default ShareItem;