import React from 'react';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import CloseIcon from "./CloseIcon";
import SuccessIcon from "./SuccessIcon";
import WarnIcon from "./WarnIcon";
import PaymentToast from './PaymentToast';
import SberToast from './SberToast';
import './index.scss';

const ToastifyContainer = () => {
    return (
        <ToastContainer
            autoClose={5000}
            hideProgressBar={true}
            position="bottom-center"
            theme="Toastify-Custome"
        />
    );
};

export const toastNotifications = {
    warn: (message) => toast.warn(message, {
        icon: <CloseIcon />
    }),
    error: (message) => toast.error(message, {
        icon: <CloseIcon />
    }),
    success: (message) => toast.success(message, {
        icon: <SuccessIcon />
    }),
    info: (message) => toast.info(message, {
        icon: <SuccessIcon />
    }),
    warnInfo: (message) => toast.warn(message, {
        icon: <WarnIcon />,
        className: "Toastify__toast--warn-info"
    }),
    payment: (status) => toast(<PaymentToast status={status} />, {
        closeButton: false,
        position: toast.POSITION.BOTTOM_RIGHT,
    }),
    sberAccount: () => toast(<SberToast />, {
        closeButton: false,
        position: toast.POSITION.BOTTOM_RIGHT,
    })
}

export default ToastifyContainer;