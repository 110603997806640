import Select from "react-select";
import AsyncCreatableSelect from "react-select/async-creatable";
import classNames from "classnames";
import "./index.scss";

const selectBoxStyles = {
    height: "64px",
    width: "100%",
    padding: "0px 24px",
    borderRadius: "48px",
    fontSize: "14px",
    lineHeight: "24px",
    color: "white",
    border: "1px solid transparent",
    backgroundClip: "padding-box",
    transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
    background:
        "radial-gradient(108.33% 108.33% at 50.00% 47.45%, rgba(84, 94, 102, 0.00) 0%, rgba(84, 94, 102, 0.24) 100%)",
    boxShadow:
        "6px 0 28px 0 rgba(255, 255, 255, 0.08) inset, 0 6px 8px 0 rgba(255, 255, 255, 0.02) inset, -6px 0px 8px 0px rgba(255, 255, 255, 0.02) inset, 0px -6px 8px 0px rgba(255, 255, 255, 0.08) inset",
    backdropFilter: "blur(48px)",
    cursor: "pointer",
};

const selectMenu = {
    backgroundColor: "#080808",
    padding: "8px 5px 8px 20px",
    borderRadius: "20px",
    border: "1px solid rgba(255, 255, 255, 0.2)",
    color: "white",
};

const selectOption = {
    borderRadius: "60px",
    backgroundColor: "transparent",
    fontSize: "14px",
    color: "white",
    padding: "12px",
};

const selectStyles = {
    control: (baseStyles, state) => ({
        ...baseStyles,
        ...selectBoxStyles,
    }),
    input: (baseStyles, state) => ({
        ...baseStyles,
        color: "white",
    }),
    singleValue: (baseStyles, state) => ({
        ...baseStyles,
        color: "white",
    }),
    valueContainer: (baseStyles, state) => ({
        ...baseStyles,
        padding: "unset",
    }),
    indicatorSeparator: (baseStyles, state) => ({
        ...baseStyles,
        display: "none",
    }),
    menu: (baseStyles, state) => ({
        ...baseStyles,
        ...selectMenu,
    }),
    option: (baseStyles, state) => ({
        ...baseStyles,
        ...selectOption,
        backgroundColor: state.isSelected ? "rgba(84, 94, 102, 0.2)" : "transparent",
    }),
};

const RegistrationSelect = ({
    name,
    id,
    label,
    placeholder,
    options,
    onChange,
    onCreateOption,
    onBlur,
    value,
    error,
    isAsync = false,
    disabled,
    loadOptions,
    className,
    noOptionsMessage,
}) => {
    return (
        <div className={classNames(className, "RegistrationSelect")}>
            <label className="RegistrationSelect__Label" htmlFor={id}>
                {label}
            </label>
            {!isAsync ? (
                <Select
                    className={classNames({ "RegistrationSelect--Error": error })}
                    classNamePrefix="RegistrationSelect"
                    id={id}
                    name={name}
                    placeholder={placeholder}
                    value={value}
                    isDisabled={disabled}
                    options={options}
                    styles={selectStyles}
                    onBlur={onBlur}
                    onChange={onChange}
                />
            ) : (
                <AsyncCreatableSelect
                    className={classNames({ "RegistrationSelectAsync--Error": error })}
                    classNamePrefix="RegistrationSelectAsync"
                    cacheOptions
                    isSearchable
                    allowCreateWhileLoading={false}
                    isDisabled={disabled}
                    loadingMessage={() => "загрузка..."}
                    noOptionsMessage={noOptionsMessage ?? (() => "нет вариантов")}
                    formatCreateLabel={(inputValue) => `выбрать "${inputValue}"`}
                    id={id}
                    name={name}
                    placeholder={placeholder}
                    loadOptions={loadOptions}
                    value={value}
                    styles={selectStyles}
                    onBlur={onBlur}
                    onChange={onChange}
                    onCreateOption={onCreateOption}
                />
            )}
        </div>
    );
};

export default RegistrationSelect;
