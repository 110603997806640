import React from "react";
import * as Sentry from "@sentry/react";
import { useLocation } from "react-router-dom";
import { createRoutesFromChildren, useNavigationType } from "react-router";
import { matchRoutes } from "@remix-run/router";

let isInited = false;

export const initSentry = () => {
    if (process.env.REACT_APP_SENTRY_DSN) {
        isInited = true;
        Sentry.init({
            dsn: process.env.REACT_APP_SENTRY_DSN,
            tracesSampleRate: 0.0,
            integrations: [
                new Sentry.BrowserTracing({
                    // See docs for support of different versions of variation of react router
                    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
                    routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                        React.useEffect,
                        useLocation,
                        useNavigationType,
                        createRoutesFromChildren,
                        matchRoutes,
                    ),
                }),
                // new Sentry.Replay(),
            ],
        });
    }
};

export const captureException = (e: any) => {
    isInited && Sentry.captureException(e);
};
