import Button from "@/components/Button";
import "./index.scss";

type Props = {
    togglePopup: () => void,
    executeFunc: () => void,
    question: string,
    text: string,
}

const WarningMessage: React.FC<Props> = ({
    togglePopup,
    executeFunc,
    question,
    text,
}) => {

    const handleClick = () => {
        executeFunc();
        togglePopup();
    }

    return (
        <div className="WarningMessage">
            <div className="WarningMessage__Header">
                <h3 className="WarningMessage__Title">{question}</h3>
                <p className="WarningMessage__Text">{text}</p>
            </div>
            <div className="WarningMessage__Btns">
                <button
                    className="WarningMessage__Cancel"
                    type="button"
                    onClick={togglePopup}
                >
                    Отмена
                </button>
                <Button
                    className="WarningMessage__Delete"
                    BtnType="button"
                    tag="button"
                    onClick={handleClick}
                    gradient
                >
                    Удалить
                </Button>
            </div>
        </div>
    )
}

export default WarningMessage;