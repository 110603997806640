import React from 'react';
import { forwardRef } from 'react';
import classNames from "classnames";
import './index.scss';

type Props = {
    className?: string;
    onClick?: () => void;
    tag?: 'a' | 'button';
    BtnType?: 'button' | 'submit';
    text?: string;
    href?: string;
    target?: '_blank' | '_parent' | '_self' | '_top';
    icon?: JSX.Element;
    iconRight?: boolean;
    size?: 'lg' | 'sm';
    type?: 'white' | 'colored' | 'transparent' | 'bordered' | 'colored-borders';
    isWide?: boolean;
    gradient?: boolean;
    disabled?: boolean;
    children?: React.ReactNode;
}

const Button: React.FC<Props> = forwardRef<any, Props>(({
    className,
    onClick,
    tag,
    BtnType,
    text,
    href,
    target,
    icon,
    iconRight = false,
    size = 'lg',
    type = 'white',
    isWide = false,
    gradient = false,
    disabled = false,
    children,
}, ref) => {
    const Tag = tag === 'button' ? 'button' : 'a';

    return (
        <Tag
            className={classNames(className, {
                'Button': true,
                [`Button--${type}`]: type,
                [`Button--${size}`]: size,
                [`Button--gradient`]: gradient,
                [`Button--isWide`]: isWide,
                [`Button--disabled`]: disabled,
            })}
            onClick={onClick}
            href={href}
            target={target}
            type={BtnType}
            disabled={disabled}
            ref={ref}
        >
            {icon && !iconRight ? <span className="Button__Icon">{icon}</span> : null}
            {!!text && <span className="Button__Text">{text}</span>}
            {children}
            {icon && iconRight ? <span className="Button__Icon">{icon}</span> : null}
        </Tag>
    );
});

export default Button;