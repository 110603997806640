import React, { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import "./index.scss";

export type AvatarProps = {
    className?: string;
    src?: string;
    name?: string;
};

export const Avatar = ({ className, src, name }: AvatarProps) => {
    const [isErrorAvatar, setIsErrorAvatar] = useState(false);

    const initials = useMemo(() => {
        const [first, second] = (name ?? "").split(" ");
        return [first?.charAt(0), second?.charAt(0)].filter(Boolean).join("");
    }, [name]);

    useEffect(() => {
        setIsErrorAvatar(false);
    }, [src]);

    const handleErrorAvatar = () => {
        setIsErrorAvatar(true);
    };

    const isUndefined = name?.includes("undefined");

    return (
        <div className={classNames(className, "Avatar")} title={name}>
            {src && !isErrorAvatar ?
                (<img className="Avatar__Image" src={src} alt={name} onError={handleErrorAvatar} />)
                :
                (!isUndefined && <span className="Avatar__Initial">{initials}</span>)
            }
        </div>
    );
};

export default Avatar;
