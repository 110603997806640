import { VideosArchiveType } from "@/types/interfaces";



export const VIDEO_ARCHIVE: VideosArchiveType[] = [
    {
        videoLink: 'https://vk.com/video_ext.php?oid=-184379939&id=456239457&hd=2',
    },
    {
        videoLink: "https://vk.com/video_ext.php?oid=-184379939&id=456239455&hd=2",
    },
]