import EvaluateProjects from "./EvaluateProjects";
import Experts from "./Experts";
import Divider from "@/components/Divider";
import Container from "@/components/Container";
import GreenLightBottom from "./icons/green-light-bottom.png";
import GreenLightTop from "./icons/green-light-top.png";
import GreenLightCenter from "./icons/green-light-center.png";
import "./index.scss";
import Terms from "../Terms";

const Criteria = () => {
    return (
        <section className="Criteria">
            <Terms />
            <img className="Criteria__Decoration01" src={GreenLightTop} alt="decoration light" loading="lazy" />
            <img className="Criteria__Decoration02" src={GreenLightBottom} alt="decoration light" loading="lazy" />
            <img className="Criteria__Decoration03" src={GreenLightCenter} alt="decoration light" loading="lazy" />
            <Container className="Criteria__Container">
                <Divider className="Criteria__Divider" theme="dark" />
                <EvaluateProjects />
                <Divider className="Criteria__Divider" theme="dark" />
            </Container>
            <Experts />
        </section>
    );
};

export default Criteria;
