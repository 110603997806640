import React, { useMemo } from "react";

import Button from "@/components/Button";
import Tooltip from "@/components/Tooltip";
import InputSelectCountries from "@/components/InputSelectCountries";
import InputSelectCity from "@/components/InputSelectCity";
import { hasError } from "@/utils/formik";
import { FormikErrorWrap } from "@/components/FormikErrorWrap";
import { FormikData } from "@/types/formik";
import "./index.scss";

const LocationInformation = ({ formik, nextStep }: { formik: FormikData<any>; nextStep: () => void }) => {
    const isValid = useMemo(() => {
        return !(hasError(formik, "country_id", false) || hasError(formik, "city", false));
    }, [formik]);

    return (
        <>
            <div className="LocationInformation__select-boxes">
                <FormikErrorWrap formik={formik} name="country_id">
                    <InputSelectCountries
                        id="country_id"
                        name="country_id"
                        placeholder="Введите свою страну"
                        label="Страна"
                        value={formik.values.country_id}
                        error={hasError(formik, "country_id")}
                        onSelect={(country_id) => formik.setFieldValue("country_id", country_id)}
                        onBlur={() => formik.setFieldTouched("country_id")}
                    />
                </FormikErrorWrap>
                <FormikErrorWrap formik={formik} name="city">
                    <InputSelectCity
                        id="city"
                        name="city"
                        placeholder="Введите свой город"
                        label="Город"
                        error={hasError(formik, "city")}
                        countryId={formik.values.country_id}
                        value={formik.values.city}
                        onSelect={(city) => formik.setFieldValue("city", city)}
                        onBlur={() => formik.setFieldTouched("city")}
                    />
                </FormikErrorWrap>
            </div>

            <Tooltip
                className="LocationInformation__Tooltip"
                text={"Необходимо заполнить все поля"}
                diraction="top"
                disabled={isValid}
            >
                <Button
                    className="LocationInformation__Btn"
                    tag="button"
                    text="Далее"
                    size="lg"
                    type="colored"
                    isWide
                    gradient
                    disabled={!isValid}
                    onClick={nextStep}
                />
            </Tooltip>
        </>
    );
};

export default LocationInformation;
