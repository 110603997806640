import React from "react";
import classNames from "classnames";
import { ReactComponent as Check } from "./assets/check.svg";
import "./index.scss";

export type CheckboxPorps = {
    name?: string;
    id?: string;
    className?: string;
    checked?: boolean;
    text?: string;
    error?: boolean;
    link?: string;
    value?: any;
    linkText?: string;
    target?: string;
    onChange?: (e: any) => void;
    onBlur?: (e: any) => any;
};

const Checkbox = (props: CheckboxPorps) => {
    const { name, id, className, checked, text, error, link, linkText, value, target, onChange, onBlur } = props;
    return (
        <div
            className={classNames(className, "Checkbox", {
                "Checkbox--Error": error,
            })}
        >
            <label
                className={classNames("Checkbox__Box", {
                    "Checkbox__Box--active": checked,
                })}
                htmlFor={id}
            >
                <Check />
            </label>
            <input
                className="Checkbox__Input"
                type="checkbox"
                name={name}
                id={id}
                value={value}
                checked={checked}
                onChange={(e) => {
                    onChange?.(e);
                    setTimeout(() => {
                        onBlur?.(e);
                    });
                }}
            />
            <label className="Checkbox__Description" htmlFor={id}>
                {text}
                {link ? (
                    <a className="Checkbox__Link" href={link} target={target} rel="noopener noreferrer">
                        {linkText}
                    </a>
                ) : null}
            </label>
        </div>
    );
};

export default Checkbox;
