import React, { useEffect, useRef } from 'react';
import DropdownMenu from '@/components/DropdownMenu';
import Popup from '@/components/Popup';
import MenuMobile from './components/MenuMobile';
import useToggle from '@hooks/useToggle';
import useMedia from "@/hooks/useMedia";
import { ReactComponent as SideMenuIcon } from './icons/side-menu-icon.svg';
import classNames from "classnames";
import './index.scss';


const links = [
    {
        link: '/',
        text: 'О конференции'
    },
    {
        link: '/contest',
        text: 'Конкурс'
    }
]

const NavBtn = ({ className }) => {
    const [isOpen, toggle] = useToggle()
    const { isMobile, isTablet } = useMedia();
    const innerRef = useRef(null);

    useEffect(() => {
        const handleClick = (e) => {
            if (isOpen && e.target !== innerRef.current) {
                toggle();
            }
        };

        window.addEventListener('click', handleClick);

        return () => {
            window.removeEventListener('click', handleClick);
        }
    }, [isOpen])

    return (
        <div className={classNames({
            'NavBtn': true,
            [className]: className
        })}>
            <div
                className={classNames({
                    'NavBtn__Btn': true,
                    'NavBtn__Btn--Open': isOpen,
                })}
                onClick={toggle}
                ref={innerRef}
            >
                <span className="NavBtn__Title">Меню</span>
                <SideMenuIcon className="NavBtn__Icon" />
            </div>
            {
                isOpen & !isMobile & !isTablet ?
                    <DropdownMenu links={links} />
                    :
                    <Popup isPopupActive={isOpen} closePopup={toggle} >
                        <MenuMobile />
                    </Popup>
            }
        </div>
    );
};

export default NavBtn;