import { useQuery } from "react-query";
import { checkPaymentStatus } from "@/api";

export const useCheckPaymentStatus = () => {
    return useQuery(["check-status"], async () => {
        const {
            data: { status: statusPayment },
        } = await checkPaymentStatus();
        return !statusPayment;
    });
};
