
import { NavLink } from "react-router-dom";
import { ReactComponent as ArrowLeft } from "./assets/arrow-left.svg";
import routes from "@/routes";
import classNames from "classnames";
import "./index.scss";

type Props = {
    className?: string
}

const BackwordBtn: React.FC<Props> = ({
    className
}) => {
    return (
        <NavLink
            className={classNames(className, "BackwordBtn")}
            to={routes.conference}
        >
            <span className="BackwordBtn__Icon">
                <ArrowLeft />
            </span>
            На главную
        </NavLink>
    )
}

export default BackwordBtn;