import Equeo from "./assets/e-qoeo.png";
import Skillcup from "./assets/skillcup.png";
import Argument from "./assets/argument.png";
import Kinscope from "./assets/kinescopeo.png";
import Math01 from "./assets/01math.png";
import Teachbase from "./assets/teachbase.png";
import Contur from "./assets/contur.png";
import Skysmart from "./assets/skysmart.png";
import Pruffme from "./assets/pruffme.png";
import Igrom from "./assets/igron.png";
import Reezonly from "./assets/reezonly.png";
import Kampus from "./assets/k-ampus.png";
import { Winner } from "./types";

export const dataWinners: Winner[] = [
    {
        name: "Argument",
        text: "No-code платформа по управлению контентом дополненной реальности с целью обучения персонала. Основное применение: обучение и информирование персонала, когда сотруднику нужно быстро...",
        isTop: true,
        link: "https://argument.digital/",
        image: Argument,
        description: `No-code платформа по управлению контентом дополненной реальности с целью обучения персонала. Основное применение: обучение и информирование персонала, когда сотруднику нужно быстро получить инструкцию практически с любого объекта, который находится рядом с его рабочим местом.
        `,
        typeCard: "default",

    },
    {
        name: "Teachbase",
        text: "Платформа для запуска обучения и развития людей. Имеет все необходимые инструменты для разработки курсов...",
        isTop: true,
        link: "https://teachbase.ru/",
        image: Teachbase,
        description: `Платформа для запуска обучения и развития людей. Имеет все необходимые инструменты для разработки курсов, проведения обучения (онлайн и смешанного), аналитики и оценки результативности обучения. 
        `,
        typeCard: "wideLogo",
    },
    {
        name: "Kinescope",
        text: "Видеоплатформа для образовательного бизнеса, которая помогает безопасно хранить...",
        isTop: true,
        link: "https://kinescope.ru/",
        image: Kinscope,
        description: `Видеоплатформа для образовательного бизнеса, которая помогает безопасно хранить и распространять видеоконтент, проводить трансляции, а также взаимодействовать со зрителем и собирать аналитику по просмотрам.`,
        typeCard: "wideLogo",
    },
    {
        name: "Эквио",
        text: "LMS-решение, которое помогает быстро вводить в должность новых сотрудников, мотивировать персонал, давать...",
        isTop: false,
        link: "https://e-queo.com",
        image: Equeo,
        description: `LMS-решение, которое помогает быстро вводить в должность новых сотрудников, мотивировать персонал, давать быструю обратную связь, а также выполняет функцию мобильного корпоративного портала с интерактивными инструментами — геймификацией, рейтингами, тестами, опросами и магазином подарков.`,
        typeCard: "wideLogo",
    },
    {
        name: "Skill Cup",
        text: "Облачная платформа для организации онлайн-обучения сотрудников и создания курсов в формате микрообучения...",
        isTop: false,
        link: "https://www.skillcup.ru/b2b",
        image: Skillcup,
        description: `Облачная платформа для организации онлайн-обучения сотрудников и создания курсов в формате микрообучения. Позволяет автоматизировать процесс обучения и адаптации, отслеживать статистику по каждому сотруднику и повышать вовлечённость. 
        `,
        typeCard: "default",
    },
    {
        name: "Математика01.",
        text: "Обучающая онлайн-система по математике с искусственным интеллектом и адаптивными технологиями...",
        isTop: false,
        link: "https://01math.com/",
        image: Math01,
        description: `Обучающая онлайн-система по математике с искусственным интеллектом и адаптивными технологиями. Система непрерывно сопровождает ученика: подбирает оптимальную траекторию обучения, анализирует работу, строит карту знаний с оценкой успешности пройденных разделов.
        `,
        typeCard: "default",
    },
    {
        name: "Контур.Толк",
        text: "Удобный сервис видеосвязи. Позволяет проводить рабочие совещания и масштабные конференции...",
        isTop: false,
        link: "https://kontur.ru/talk",
        image: Contur,
        description: `Удобный сервис видеосвязи. Позволяет проводить рабочие совещания и масштабные конференции с качественным звуком и видео. Присоединиться к встрече легко по ссылке: сервис работает в браузере и в приложении.`,
        typeCard: "wideLogo",
    },
    {
        name: "Skysmart AI ЕГЭ Эксперт",
        text: "Чат-бот, первый AI, который может полностью заменить проверку экзамена по английскому языку экспертом... ",
        isTop: false,
        link: "https://t.me/ai_ege_expert_bot",
        image: Skysmart,
        description: `Чат-бот, первый AI, который может полностью заменить проверку экзамена по английскому языку экспертом. Точность его оценки — на уровне профессиональных методистов.`,
        typeCard: "wideLogo",
    },
    {
        name: "Онлайн-доска Pruffme",
        text: "Платформа для создания и проведения видеоконференций, вебинаров, автовебинаров, интерактивных досок, курсов, тестов и опросов...",
        isTop: false,
        link: "https://whiteboards.pruffme.com",
        image: Pruffme,
        description: `Платформа для создания и проведения видеоконференций, вебинаров, автовебинаров, интерактивных досок, курсов, тестов и опросов. На онлайн-досках команды могут совместно в режиме реального времени визуализировать процессы, проводить мозговые штурмы, сессии дизайн-мышления и работать в других методологиях.`,
        typeCard: "wideLogo",
    },
    {
        name: "Игрон-сервис",
        text: "Цифровая платформа для проведения психологических, бизнес и обучающих игр, игрофикации обучения....",
        isTop: false,
        link: "https://psygame-service.online",
        image: Igrom,
        description: `Цифровая платформа для проведения психологических, бизнес и обучающих игр, игрофикации обучения. Авторы игровых курсов могут перенести в онлайн свои методики с помощью no-code интерфейса. Все, что нужно уметь автору игры — загружать изображения.`,
        typeCard: "wideLogo",
    },
    {
        name: "Reezonly LMS",
        text: "Конструктор контента с встроенными AI ассистентами, упрощающими каждый этап проектировани и сборки...",
        isTop: false,
        link: "https://reezonly.com/",
        image: Reezonly,
        description: `Конструктор контента с встроенными AI ассистентами, упрощающими каждый этап проектировани и сборки образовательных программ, интсументы монетизации контента, инструменты для тьюторов  и многое другое.`,
        typeCard: "wideLogo",
    },
    {
        name: "LXP-платформа K-ampus",
        text: "LXP-платформа для корпоративного обучения и развития, которая создает вовлекающий и персонализированный учебный опыт...",
        isTop: false,
        link: "http://kampus.team",
        image: Kampus,
        description: `<p>LXP-платформа для корпоративного обучения и развития, которая создает вовлекающий и персонализированный учебный опыт.<p>

        <p>На платформе KAMPUS реализована уникальная система формирования индивидуальных планов развития (ИПР) на основе трех факторов: входная диагностика soft skills, hard skills по должности + практические задачи. Индивидуальная подборка включает разнообразие форматов, при этом именно пользователь выбирает предпочтительный формат, время и последовательность изучения. Самостоятельный выбор повышает вовлеченность и эффективность процесса обучения.</p>`,
        typeCard: "wideLogo",
    },
];
