import classNames from 'classnames';
import { HashLink } from 'react-router-hash-link';
import './index.scss';

const AccommodationInfo = ({ className }) => {

    const scrollToSection = (element) => {
        element.scrollIntoView({
            block: "end",
            behavior: "instant"
        })
    }

    return (
        <div className={classNames({
            "AccommodationInfo__Wrapper": true,
            [className]: className
        })}>
            <p className="AccommodationInfo__Text">{'Вы также сможете остаться в\u00A0кампусе на ночь или приехать \nк\u00A0нам за день до конференции.'}</p>
            <div className="AccommodationInfo__Subtext">
                <p>*Проживание в кампусе оплачивается дополнительно</p>
                < HashLink
                    className="AccommodationInfo__Link"
                    to={'/#faq'}
                    scroll={(el) => scrollToSection(el)}
                >
                    Подробнее
                </HashLink >
            </div>
        </div>
    )
}

export default AccommodationInfo;