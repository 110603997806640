import ReactDOM from "react-dom";
import { useRef, useEffect } from "react";
import { ReactComponent as CloseIcon } from "./icons/close-btn.svg";
import { ReactComponent as CloseIconProgram } from "./icons/close-btn-program.svg";
import { motion, AnimatePresence } from "framer-motion";
import classNames from "classnames";
import "./index.scss";

type Props = {
    isPopupActive: boolean,
    closePopup: () => void,
    children: React.ReactNode,
    className?: string,
    position?: "bottom" | "right" | "center",
    withBodyScroll?: boolean,
    withCloseBtn?: boolean
}

const Popup: React.FC<Props> = ({
    isPopupActive,
    closePopup,
    children,
    className,
    position = "bottom",
    withBodyScroll = false,
    withCloseBtn = true
}) => {

    const PopupLayout = useRef(null);
    const animationSpeed = 0.5;

    useEffect(() => {
        let bodyScroll: any;

        if (!withBodyScroll) {
            bodyScroll = setTimeout(() => {
                document.body.style.overflow = isPopupActive ? "hidden" : "unset";
            }, 300);
        }

        return () => {
            if (!withBodyScroll) {
                window.clearTimeout(bodyScroll);
            }
        };
    }, [isPopupActive, withBodyScroll]);

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (e.target === PopupLayout.current) {
            closePopup();
        }
    };

    const handleClose = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        closePopup();
    };

    return ReactDOM.createPortal(
        <AnimatePresence>
            {isPopupActive && (
                <motion.div
                    className={classNames(className, {
                        "Popup": true,
                        "Popup--right": position === "right",
                        "Popup--center": position === "center",
                    })}
                    ref={PopupLayout}
                    onClick={handleClick}
                    initial={{ backgroundColor: "rgba(8, 8, 8, 0.0)" }}
                    animate={{ backgroundColor: "rgba(8, 8, 8, 0.8)" }}
                    exit={{ backgroundColor: "rgba(8, 8, 8, 0.0)" }}
                    transition={{ duration: animationSpeed, ease: "easeInOut" }}
                >
                    <motion.div
                        className="Popup__Box"
                        initial={position === "right" ? { translateX: "100%" } : position === "center" ? { translateY: "100vh" } : { translateY: "100%" }}
                        animate={position === "right" ? { translateX: "0%" } : position === "center" ? { translateY: "0vh" } : { translateY: "0%" }}
                        exit={position === "right" ? { translateX: "100%" } : position === "center" ? { translateY: "100vh" } : { translateY: "100%" }}
                        transition={{ duration: animationSpeed, ease: "easeInOut" }}
                    >
                        {withCloseBtn &&
                            <button
                                className="Popup__CloseBtn"
                                onClick={handleClose}
                            >
                                {position === "right" ? <CloseIconProgram /> : <CloseIcon />}
                            </button>
                        }

                        {children}
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>,
        document.getElementById("popupPortal") as HTMLElement,
    );
};

export default Popup;
