import React from 'react';
import classNames from "classnames";
import useMedia from '@/hooks/useMedia';
import './index.scss';

const circlesDesktop = 21;
const circlesTablet = 12;
const circlesMobile = 14;

type Props = {
    className?: string,
    theme?: 'light' | 'dark',
}

const Divider: React.FC<Props> = ({
    theme = 'light',
    className,
}) => {

    const { isMobile, isTablet } = useMedia();

    const elements = Array(isMobile ? circlesMobile : isTablet ? circlesTablet : circlesDesktop).fill('1').map((el, index) => <span key={index} className="Divider__Circle" />)
    return (
        <div className={classNames(className, {
            'Divider': true,
            [`Divider--${theme}`]: theme,
        })}>
            {elements}
        </div>
    );
};

export default Divider;