import { useEffect, useState } from 'react';
import { SpeakerType } from 'types/interfaces';
import Popup from '@/components/Popup';
import useToggle from '@/hooks/useToggle';
import SpeakerPopupContent from '@/components/SpeakerPopupContent';
import { ReactComponent as BackwordArrow } from '../assets/backwords-arrow.svg';
import classNames from 'classnames';
import useMedia from '@/hooks/useMedia';
import Typography from "components/Typography";
import './index.scss';

type Props = {
    data: SpeakerType,
    showSpeakerdescription?: boolean,
    isModerator?: boolean,
    avatarSize?: "big" | "small"
}

const SessionSpeaker: React.FC<Props> = ({
    data,
    showSpeakerdescription = false,
    isModerator = false,
    avatarSize = "small",
}) => {
    const [isActive, togglePopup] = useToggle();
    const [backwordBtn, setBackwordBtn] = useState<boolean>(false);
    const { isTablet, isMobile } = useMedia();

    useEffect(() => {
        if (isActive) {
            const countPopups = document.getElementById("popupPortal")?.childNodes.length

            if (countPopups && countPopups > 1) {
                setBackwordBtn(true)
            }
        }

    }, [isActive])

    return (
        <>
            <div
                className={classNames({
                    "SessionSpeaker__container": isModerator,
                    "SessionSpeaker__container--moderator": showSpeakerdescription && isModerator,
                })}
                onClick={togglePopup}
            >
                {isModerator && showSpeakerdescription &&
                    <Typography
                        className='SessionSpeaker__Moderator'
                        color="grey"
                        component="span"
                        size="sm"
                        isBold
                    >
                        {data.title}
                    </Typography>
                }
                <div className={classNames({
                    "SessionSpeaker": true,
                    "SessionSpeaker--big": avatarSize === "big",
                })}>
                    <div className="SessionSpeaker__Avatar-container">
                        <img
                            className="SessionSpeaker__Avatar"
                            src={data.avatar}
                            alt={data.fullName}
                        />
                    </div>
                    <div className="SessionSpeaker__Speaker-info">
                        <p className="SessionSpeaker__Speaker-name">{data.fullName}</p>
                        {isModerator && !showSpeakerdescription && <p className="SessionSpeaker__Speaker-title">{data.title}</p>}
                        {showSpeakerdescription && <p className="SessionSpeaker__Speaker-title">{data.description}</p>}
                    </div>
                </div>
            </div>

            <Popup
                className='SessionSpeaker__Popup'
                closePopup={togglePopup}
                isPopupActive={isActive}
                position='right'
                withCloseBtn={!backwordBtn}
            >
                {backwordBtn &&
                    <button
                        className='SessionSpeaker__BackwordBtn'
                        onClick={togglePopup}
                    >
                        {isTablet || isMobile ?
                            <>
                                <BackwordArrow />
                                Назад
                            </>
                            :
                            <BackwordArrow />
                        }
                    </button>
                }
                <SpeakerPopupContent
                    data={data}
                />
            </Popup >
        </>
    )
}

export default SessionSpeaker;