import GreenLiquid from "./assets/Green-Liquid.png";
import BlueLiquid from "./assets/Blue-Liquid.png";
import "./index.scss";

type Props = {
    time: string
}

const WaitingScreen: React.FC<Props> = ({
    time,
}) => {
    return (
        <div className="WaitingScreen">
            <img className="WaitingScreen__Decoration01" src={GreenLiquid} alt="GreenLiquid" />
            <img className="WaitingScreen__Decoration02" src={BlueLiquid} alt="BlueLiquid" />
            <div className="WaitingScreen__Body">
                <span className="WaitingScreen__Subtitle">Начало трансляции</span>
                <p><span className="WaitingScreen__Title WaitingScreen__Title--gradient">{time} по МСК</span><span className="WaitingScreen__Title WaitingScreen__Title--gray">(UTC +3)</span></p>
            </div>
        </div>
    )
}

export default WaitingScreen;