import { useState, useRef } from 'react';
import useMedia from '@/hooks/useMedia';
import Button from '@/components/Button';
import DropdownMenu from '@/components/DropdownMenu';
import { ReactComponent as SignInIcon } from './assets/sign-in-icon.svg';
import { useClickAway } from 'react-use';
import './index.scss';
import routes from "@routes";
import {useNavigate} from "react-router-dom";

const AuthorizationBtn = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { isMobile } = useMedia();
    const navigate = useNavigate();

    const btnRef = useRef(null);

    return (
        <div className='AuthorizationBtn' >
            <Button
                className="AuthorizationBtn__Button"
                tag="button"
                text={isMobile ? "" : "Войти"}
                size="sm"
                icon={<SignInIcon />}
                iconRight
                onClick={() => {
                    navigate(routes.signin);
                }}
                ref={btnRef}
            />
        </div>
    )
}

export default AuthorizationBtn;
