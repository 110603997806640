import React, { useMemo } from "react";

import Checkbox from "@/components/Checkbox";
import RegistrationInput from "@/components/RegistrationInput";
import Button from "@/components/Button";
import Tooltip from "@/components/Tooltip";
import { hasError } from "@/utils/formik";
import { FormikErrorWrap } from "@/components/FormikErrorWrap";
import { FormikData } from "@/types/formik";

import "./index.scss";

const ActivityInformation = ({ formik, nextStep }: { formik: FormikData<any>; nextStep: () => void }) => {
    const isValid = useMemo(() => {
        return !(
            hasError(formik, "activity", false) ||
            hasError(formik, "place_of_work", false) ||
            hasError(formik, "position", false) ||
            hasError(formik, "place_of_study", false) ||
            hasError(formik, "speciality", false)
        );
    }, [formik]);

    const isCheckWork = formik.values.activity.includes("work");
    const isCheckStudy = formik.values.activity.includes("study");

    return (
        <>
            <p className="ActivityInformation__text">Сейчас я</p>
            <div className="ActivityInformation__Checkboxes">
                <Checkbox
                    className="ActivityInformation__Checkbox"
                    id="work"
                    name="activity"
                    text="Работаю"
                    value="work"
                    checked={formik.values.activity.includes("work")}
                    onChange={formik.handleChange}
                />
                <Checkbox
                    className="ActivityInformation__Checkbox"
                    id="study"
                    name="activity"
                    text="Учусь"
                    value="study"
                    checked={formik.values.activity.includes("study")}
                    onChange={formik.handleChange}
                />
                <Checkbox
                    className="ActivityInformation__Checkbox"
                    id="noactivity"
                    name="activity"
                    text="Не работаю и не учусь"
                    value="no_activity"
                    checked={formik.values.activity.includes("no_activity")}
                    onChange={formik.handleChange}
                />
            </div>

            <div className="ActivityInformation__inputs">
                {isCheckWork && (
                    <>
                        <FormikErrorWrap formik={formik} name="place_of_work">
                            <RegistrationInput
                                className="ActivityInformation__input"
                                type="text"
                                id="place_of_work"
                                name="place_of_work"
                                placeholder="Ваше место работы"
                                label="Место работы"
                                value={formik.values.place_of_work}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={hasError(formik, "place_of_work")}
                            />
                        </FormikErrorWrap>

                        <FormikErrorWrap formik={formik} name="position">
                            <RegistrationInput
                                className="ActivityInformation__input"
                                type="text"
                                id="position"
                                name="position"
                                placeholder="Ваша должность"
                                label="Должность"
                                value={formik.values.position}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={hasError(formik, "position")}
                            />
                        </FormikErrorWrap>
                    </>
                )}

                {isCheckStudy && (
                    <>
                        <FormikErrorWrap formik={formik} name="place_of_study">
                            <RegistrationInput
                                className="ActivityInformation__input"
                                type="text"
                                id="place_of_study"
                                name="place_of_study"
                                placeholder="Ваше место учебы"
                                label="Место учебы"
                                value={formik.values.place_of_study}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={hasError(formik, "place_of_study")}
                            />
                        </FormikErrorWrap>

                        <FormikErrorWrap formik={formik} name="speciality">
                            <RegistrationInput
                                className="ActivityInformation__input"
                                type="text"
                                id="speciality"
                                name="speciality"
                                placeholder="Ваша специальность"
                                label="Специальнось"
                                value={formik.values.speciality}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={hasError(formik, "speciality")}
                            />
                        </FormikErrorWrap>
                    </>
                )}
            </div>
            <Tooltip
                className="ActivityInformation__Tooltip"
                text={
                    hasError(formik, "activity", false)
                        ? "Необходимо выбрать один из вариантов"
                        : "Необходимо заполнить все поля"
                }
                diraction="top"
                disabled={isValid}
            >
                <Button
                    className="ActivityInformation__Btn"
                    tag="button"
                    onClick={nextStep}
                    text="Далее"
                    size="lg"
                    type="colored"
                    isWide
                    gradient
                    disabled={!isValid}
                />
            </Tooltip>
        </>
    );
};

export default ActivityInformation;
