import PlanPriceTag from "@/components/PlanPriceTag";
import PaidPlanForm from "@/components/PaidPlanForm";
import "./index.scss";
import Button from "@/components/Button";
import Tooltip from "@components/Tooltip";

const PlanPrice = ({ showOnlineBtn, participationFormat }) => {
    return (
        <div className="PlanPrice">
            <div className="PlanPrice__Container">
                {participationFormat === "online" ? (
                    <div className="PlanPrice__Online">
                        {showOnlineBtn && (
                            <div className="PlanPrice__OnlineAction">
                                <Tooltip
                                    diraction="top"
                                    text={
                                        "Чтобы изменить тариф на «Онлайн», заполните\nформу в разделе «Вернуть денежные средства»"
                                    }
                                >
                                    <Button className="PlanPrice__OnlineBtn" text="Сменить тариф" disabled />
                                </Tooltip>
                            </div>
                        )}
                        <div className="PlanPrice__OnlineInfo">
                            <PlanPriceTag showTag={false} withDiscount="100" />
                        </div>
                    </div>
                ) : (
                    <PaidPlanForm buttonText="Сменить тариф" showNotes />
                )}
            </div>
        </div>
    );
};

export default PlanPrice;
