import ProgramContainer from "@/components/ProgramContainer";
import { useContext } from "react";
import { ScheduleContext } from "@/context/ScheduleContext";
import Loader from "@/components/Loader";
import Headline from "@/components/Headline";
import "./index.scss";

type Props = {
    streamNum: number,
}

const StreamProgram: React.FC<Props> = ({ streamNum }) => {
    const { onlineProgram } = useContext(ScheduleContext);

    if (onlineProgram.length === 0 || !onlineProgram) {
        return <Loader />
    }

    return (
        <div className="StreamProgram">
            <Headline
                className="StreamProgram__Title"
                level="h2"
                theme="light"
            >
                Расписание стрима
            </Headline>

            {/* opening  */}
            {streamNum === 1 && <ProgramContainer
                data={onlineProgram[0]}
            />}

            {/* discussion  */}
            {streamNum === 1 && <ProgramContainer
                data={onlineProgram[1]}
                mainStage
                withLink
            />}

            {/* coffee_break  */}
            <ProgramContainer
                data={onlineProgram[2]}
            />

            {/* multiple_sessions 01 3/4/5 programs  */}
            <ProgramContainer
                data={onlineProgram[2 + streamNum]}
            />

            {/* coffee_break  */}
            <ProgramContainer
                data={onlineProgram[6]}
            />

            {/* multiple_sessions 02 7/8/9 programs   */}
            <ProgramContainer
                data={onlineProgram[6 + streamNum]}
            />

            {/* coffee_break  */}
            <ProgramContainer
                data={onlineProgram[10]}
            />

            {/* multiple_sessions 03 11/12/13 programs */}
            <ProgramContainer
                data={onlineProgram[10 + streamNum]}
            />

            {/* finish  */}
            <ProgramContainer
                data={onlineProgram[14]}
            />
        </div>
    )
}

export default StreamProgram;