import { BounceLoader } from "react-spinners";

const override = {
    margin: "auto",
};

type Props = {
    color?: string,
    size?: number
}

const Loader: React.FC<Props> = ({
    color = "#23e29d",
    size = 60
}) => {
    return (
        <BounceLoader
            color={color}
            cssOverride={override}
            size={size}
        />
    )
}

export default Loader;