import React from 'react';
import Headline from "@/components/Headline";
import ShareItem from "@/components/ShareItem";
import Container from '@/components/Container';
import { ReactComponent as VK } from './icons/vk.svg';
import { ReactComponent as VKMobile } from './icons/vk-mobile.svg';
import { ReactComponent as TG } from './icons/tg.svg';
import { ReactComponent as TGMobile } from './icons/tg-mobile.svg';
import { ReactComponent as Copy } from './icons/copy.svg';
import { ReactComponent as CopyMobile } from './icons/copy-mobile.svg';
import Decoration3 from './icons/decoration-3.png';
import Decoration4 from './icons/decoration-4.png';
import { toastNotifications } from "@/components/Toastify";
import DecorationImg1 from './icons/decoration-1.png';
import DecorationImg2 from './icons/decoration-2.png';
import useMedia from '@/hooks/useMedia';
import { Scene } from "react-scrollmagic";
import './index.scss';


const Share = () => {
    const { isMobile } = useMedia();

    const ShareData = [
        {
            icon: isMobile ? <VKMobile /> : <VK />,
            action: () => shareVK(),
        },
        {
            icon: isMobile ? <TGMobile /> : <TG />,
            action: () => shareTG(),
        },
        {
            icon: isMobile ? <CopyMobile /> : <Copy />,
            action: () => copyURL(),
        }
    ];

    const shareVK = () =>
        window.open(`https://vk.com/share.php?url=${window.location.href}/&title=Сбер%20Университет`, "_blank");
    const shareTG = () =>
        window.open(`https://t.me/share/url?url=${window.location.href}&text=Сбер%20Университет`, "_blank");
    const copyURL = () => {
        navigator.clipboard.writeText(window.location.href);
        toastNotifications.info("Ссылка скопирована");
    };

    return (
        <section className="Share">
            <img src={DecorationImg1} className="Share__Decoration1" alt="light" />
            <img src={DecorationImg2} className="Share__Decoration2" alt="light" />
            <img src={Decoration3} className="Share__Decoration3" alt="decoration light" />
            <img src={Decoration4} className="Share__Decoration4" alt="decoration light" />

            <Container>
                <Scene
                    classToggle='Animate'
                    offset="-500"
                >
                    <div className="Share__Title">
                        <Headline
                            theme='light'
                            className="Share__Headline"
                        >
                            Поделиться
                        </Headline>
                    </div>
                </Scene>

                <div className="Share__Items">
                    {
                        ShareData.map(({ icon, action }, index) => (
                            <div className="Share__Item" key={index.toString()}>
                                <ShareItem
                                    icon={icon}
                                    onClick={action}
                                />
                            </div>

                        ))
                    }
                </div>
            </Container>
        </section>
    );
};

export default Share;