import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useSignIn } from "react-auth-kit";
import { useFormik } from "formik";
import RegistrationInput from "@components/RegistrationInput";
import Button from "@/components/Button";
import Tooltip from "@components/Tooltip";
import Checkbox from "@components/Checkbox";
import FormErrorMessage from "@/components/FormErrorMessage";
import { signInSchema } from "@/Schema/signInSchema";
import { toastNotifications } from "@/components/Toastify";
import routes from "@/routes";
import { logIn } from "@/api";
import "./index.scss";

const SignInForm = () => {
    const signIn = useSignIn();
    const navigate = useNavigate();

    const [wrongInputs, setWrongInputs] = useState(false);
    const { values, handleChange, handleSubmit, handleBlur, errors, touched, isValid } = useFormik({
        initialValues: {
            userEmail: "",
            userPassword: "",
            rememberUser: false,
        },
        validationSchema: signInSchema,
        validateOnMount: true,
        onSubmit: async (values, actions) => {
            try {
                setWrongInputs(false);
                const data = {
                    email: values.userEmail,
                    password: values.userPassword,
                };

                const response = await logIn(data);
                const resDoc = response.data;

                signIn({
                    token: resDoc.data.token,
                    tokenType: "Bearer",
                    expiresIn: 3600,
                    authState: {},
                });

                navigate("/");
            } catch {
                setWrongInputs(true);
                toastNotifications.error("Что-то пошло не так. Повторите попытку позже");
            }
        },
    });

    return (
        <form className="SignInForm" onSubmit={handleSubmit} autoComplete="off">
            <RegistrationInput
                type="email"
                id="userEmail"
                name="userEmail"
                placeholder="Введите свою почту"
                label="Электронная почта"
                value={values.userEmail}
                onChange={(e) => {
                    setWrongInputs(false);
                    handleChange(e);
                }}
                onBlur={handleBlur}
                error={(touched.userEmail && errors.userEmail) || wrongInputs}
            />

            <RegistrationInput
                type="password"
                id="userPassword"
                name="userPassword"
                placeholder="Введите свой пароль"
                label="Пароль"
                value={values.userPassword}
                onChange={(e) => {
                    setWrongInputs(false);
                    handleChange(e);
                }}
                onBlur={handleBlur}
                error={(touched.userPassword && errors.userPassword) || wrongInputs}
            />

            {wrongInputs && <FormErrorMessage text="Неверно введён пароль и / или электронная почта" />}

            <div className="SignInForm__Links">
                <Checkbox
                    className="SignInForm__Checkbox"
                    id="rememberUser"
                    name="rememberUser"
                    text="Запомнить меня"
                    onChange={handleChange}
                    checked={values.rememberUser}
                />

                <NavLink className="SignInForm__Reset-password-link" to={routes.forgetPassword}>
                    Не помню пароль
                </NavLink>
            </div>
            <Tooltip text={"Необходимо ввести логин и пароль"} diraction="top" disabled={isValid}>
                <Button
                    className="SignInForm__Btn"
                    tag="button"
                    BtnType="submit"
                    text="Войти"
                    size="lg"
                    type="colored"
                    isWide
                    gradient
                    disabled={!isValid}
                />
            </Tooltip>
        </form>
    );
};

export default SignInForm;
