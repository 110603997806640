import RegistrationInput from '@components/RegistrationInput';
import Button from '@/components/Button';
import Tooltip from "@components/Tooltip";
import FormErrorMessage from '@/components/FormErrorMessage';
import AuthorizationFormHeader from "@components/AuthorizationFormHeader";
import { useFormik } from 'formik';
import { resetPasswordSchema } from '@/Schema/resetPasswordSchema';
import { toastNotifications } from '@/components/Toastify';
import { recoverPasswordByEmail } from '@/api';
import './index.scss';


const ByEmail = ({ handleLinkSending, handleEmail }) => {

    const { values, handleChange, handleSubmit, handleBlur, errors, touched, isValid } = useFormik({
        initialValues: {
            userEmail: '',
        },
        validationSchema: resetPasswordSchema,
        onSubmit: async (values, actions) => {
            try {
                const data = {
                    email: values.userEmail,
                }

                const response = await recoverPasswordByEmail(data);

                handleEmail(values.userEmail)
                actions.resetForm();
                handleLinkSending();
            } catch (error) {
                if(error.response.status === 422) {
                  toastNotifications.error(error.response.data.error);
                  return
                }
                toastNotifications.error('Что то пошло не так. Повторите попытку позже');
               
            }
        }
    })

    const nextStepIsAllowed = () => {
        if (
            touched.userEmail && !errors.userEmail
        ) {
            return true
        } else {
            return false
        }
    }

    return (
        <>
            <AuthorizationFormHeader
                className={"ByEmail__Header"}
                title={"Восстановить пароль"}
                subtitle={"Укажите ваш Email, на который будет отправлено\nписьмо с ссылкой для восстановления доступа"}
            />
            <form onSubmit={handleSubmit} className='ByEmail__Form' autoComplete='off'>
                <div className="ByEmail__Inputs">
                    <RegistrationInput
                        type="email"
                        id="userEmail"
                        name="userEmail"
                        placeholder='Введите свою почту'
                        label="Электронная почта"
                        value={values.userEmail}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.userEmail && errors.userEmail}
                    />

                    {touched.userEmail && errors.userEmail && <FormErrorMessage text="Неверный формат электронной почты" />}
                </div>

                <Tooltip
                    text={"Необходимо указать почту"}
                    diraction="top"
                    disabled={nextStepIsAllowed()}
                >
                    <Button
                        className="ByEmail__Btn"
                        tag="button"
                        BtnType="submit"
                        text="Отправить письмо"
                        size='lg'
                        type='colored'
                        isWide
                        gradient
                        disabled={!nextStepIsAllowed()}
                    />
                </Tooltip>
            </form>
        </>
    )
}

export default ByEmail;