import classNames from "classnames";
import "./index.scss";

type Props = {
    haslikes: boolean,
}

const HeartIcon: React.FC<Props> = ({haslikes}) => {
    return (
        <span className={classNames({
            "HeartIcon": true,
            "HeartIcon--haslikes": haslikes,
        })}></span>
    )
}

export default HeartIcon;