import Button from 'components/Button';
import classNames from 'classnames';
import { ReactComponent as LinkArrow } from './assets/link-icon.svg';
import { ReactComponent as PlayIcon } from './assets/play-icon.svg';
import { ReactComponent as PresentationIcon } from './assets/presentation-icon.svg';
import SessionHeader from './SessionHeader';
import SessionSpeaker from './SessionSpeaker';
import SessionSpeakers from './SessionSpeakers';
import { SessionDataType } from 'types/interfaces';
import SessionStatus from './SessionStatus';
import SessionMedia from './SessionMedia';
import './index.scss';


type Props = {
    className?: string,
    data: SessionDataType,
    mainStage?: boolean,
    vertical?: boolean,
    withLink?: boolean,
    showSpeakers?: boolean,
    gradientSubtitle?: boolean,
    isInSidePopup?: boolean,
    showSpeakerdescription?: boolean,
    speakersTitle?: string,
    children?: React.ReactNode,
    togglePopup?: () => void,
    avatarSize?: "big" | "small"
}

const SessionCard: React.FC<Props> = ({
    className,
    data,
    mainStage,
    withLink,
    vertical,
    gradientSubtitle,
    showSpeakerdescription = false,
    children,
    showSpeakers = true,
    speakersTitle,
    togglePopup,
    avatarSize,
    isInSidePopup
}) => {

    return (
        <div className={classNames(className, {
            "SessionCard": true,
            "SessionCard--vertical": vertical,
        })}>
            <div className="SessionCard__Header">
                <div className="SessionCard__Badges">
                    {data?.subtitle && <span className="SessionCard__Time-badge">{data.subtitle}</span>}

                    {mainStage &&
                        <span className={classNames({
                            "SessionCard__Place-badge SessionCard__Place-badge--grey": true,
                            "SessionCard__Status-Finished": data?.streamingStatus === "finished",
                        })}>Мастерская развития человека</span>
                    }

                    {data?.badgeText &&
                        <span className={classNames({
                            "SessionCard__Badge": true,
                            [`SessionCard__Badge--${data?.badgeColor}`]: data?.badgeColor,
                            "SessionCard__Status-Finished": data?.streamingStatus === "finished",
                        })}>
                            {data?.badgeText}
                        </span>
                    }
                </div>
                {
                    withLink &&
                    <Button
                        className={`SessionCard__Btn ${data?.streamingStatus === "finished" ? "SessionCard__Btn--finished SessionCard__Status-Finished" : ""}`}
                        tag='button'
                        icon={<LinkArrow />}
                        onClick={togglePopup}
                        text=''
                    />
                }
            </div>
            <SessionHeader
                className={data?.streamingStatus === "finished" ? "SessionCard__Status-Finished" : ""}
                subtitle=""
                title={data?.title || []}
                reference={data?.reference || ''}
                vertical={vertical}
                link={data?.link}
                gradientSubtitle={gradientSubtitle}
                togglePopup={!!data?.speakers ? togglePopup : undefined}
                titleLinked={!!data?.speakers}
            >
                {children}
                {
                    data?.moderator && showSpeakers &&
                    <SessionSpeaker
                        data={data.moderator}
                        showSpeakerdescription={showSpeakerdescription}
                        isModerator
                        avatarSize={avatarSize}
                    />
                }
            </SessionHeader>
            {data?.speakers && showSpeakers &&
                <SessionSpeakers
                    className={data?.streamingStatus === "finished" ? "SessionCard__Status-Finished" : ""}
                    speakers={data.speakers}
                    vertical={vertical}
                    speakersTitle={speakersTitle}
                    showSpeakerdescription={showSpeakerdescription}
                    avatarSize={avatarSize}
                />
            }
            <div className='SessionCard__Media-Btns'>
                <SessionMedia text='Смотреть видео' icon={<PlayIcon />} mediaLink={data?.mediaLink} isInSidePopup={isInSidePopup} />
                {data?.presentationLinks && data.presentationLinks.length > 0 &&
                    <SessionMedia
                        text='Скачать презентацию'
                        icon={<PresentationIcon />}
                        presentationData={data?.presentationLinks}
                        mediaLink={data?.mediaLink}
                        withDropdown
                        openToTop={vertical || isInSidePopup}
                    />
                }
            </div>
        </div>
    )
}
export default SessionCard;