import Popup from '@/components/Popup';

import { ReactComponent as IconCameraGreen } from './icons/camera-green.svg'
import { ReactComponent as IconGallery } from './icons/gallery.svg'
import { ReactComponent as IconArrow } from './icons/arrow.svg'

import './index.scss'

const PopupImageUpload = ({ isOpen, togglePopup, onClickRefInput, onClickRefInputTouchDevice }) => {
  return (
    <Popup isPopupActive={isOpen} closePopup={togglePopup} className="PopupImageUpload">
      <div className='PopupImageUpload__Block'>
        <h3 className='PopupImageUpload__Title'>Добавить аватар</h3>
        <button className='PopupImageUpload__ButtonTouchDevice' onClick={onClickRefInput}>
          <div className="PopupImageUpload__IconUploadWrap">
            <IconGallery />
          </div>
          <span>
            Фотоальбом
          </span>
          <div className="PopupImageUpload__IconUploadWrap PopupImageUpload__IconUploadWrap--Arrow">
            <IconArrow />
          </div>
        </button>
        <button className='PopupImageUpload__ButtonTouchDevice' onClick={onClickRefInputTouchDevice}>
          <div className="PopupImageUpload__IconUploadWrap">
            <IconCameraGreen />
          </div>
          <span>
            Камера
          </span>
          <div className="PopupImageUpload__IconUploadWrap PopupImageUpload__IconUploadWrap--Arrow">
            <IconArrow />
          </div>
        </button>
      </div>
    </Popup>
  )
}
export default PopupImageUpload