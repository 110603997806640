import React from "react";
import useMedia from "hooks/useMedia";
import "./index.scss";

const OnlineHubList: React.FC = () => {
    const { isMobile } = useMedia();

    return (
        <>
            <ul className="OnlineHubList">
                <li className="OnlineHubList__Item">
                    <p className="OnlineHubList__ItemTitle">Для методологов</p>
                    {!isMobile ? (
                        <p className="OnlineHubList__ItemText">
                            Это&nbsp;
                            <span className="OnlineHubList__ItemTextDekorLine OnlineHubList__ItemTextDekorLine--First">
                                копилка практических инструментов
                            </span>{" "}
                            для&nbsp;разработки образовательных программ.
                        </p>
                    ) : (
                        <p className="OnlineHubList__ItemText">
                            Это&nbsp;
                            <span
                                className="OnlineHubList__ItemTextDekorLine 
                                    OnlineHubList__ItemTextDekorLine--FirstMobile1"
                            >
                                копилка практических
                            </span>
                            <span
                                className="OnlineHubList__ItemTextDekorLine
                                 OnlineHubList__ItemTextDekorLine--FirstMobile2"
                            >
                                инструментов
                            </span>{" "}
                            для&nbsp;разработки образовательных программ.
                        </p>
                    )}
                </li>
                <li className="OnlineHubList__Item OnlineHubList__Item--ItemsStart">
                    <p className="OnlineHubList__ItemTitle">Для руководителей и&nbsp;HR</p>
                    <p className="OnlineHubList__ItemText">
                        Это&nbsp;
                        <span className="OnlineHubList__ItemTextDekorLine OnlineHubList__ItemTextDekorLine--Second">
                            возможность разобраться
                        </span>
                        в&nbsp;современных&nbsp;технологиях обучения, чтобы&nbsp;облегчить взаимодействие
                        с&nbsp;разработчиками обучения при выборе образовательных решений для сотрудников.
                    </p>
                </li>
            </ul>
        </>
    );
};

export default OnlineHubList;
