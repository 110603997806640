import React from "react";
import "./index.scss";

const OnlineHubDekor: React.FC = () => {
    return (
        <>
            <div className="OnlineHubDekor__Left"></div>
            <div className="OnlineHubDekor__Right"></div>
        </>
    );
};

export default OnlineHubDekor;
