import { useState, useEffect } from "react";

const useCustomScreen = (min, max) => {
    const screen = `(min-width: ${min}px) and (max-width: ${max}px)`;

    const [isScreenActive, setIsScreenActive] = useState(
        window.matchMedia(screen).matches
    );

    const getMediaSize = () => {
        setIsScreenActive(window.matchMedia(screen).matches);
    };

    useEffect(() => {
        const handleMediaChange = () => {
            getMediaSize();
        };

        window.addEventListener("resize", handleMediaChange);

        return () => {
            window.removeEventListener("resize", handleMediaChange);
        };
    }, []);

    return [isScreenActive];
};

export default useCustomScreen;