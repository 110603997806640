import React from "react";
import { openPaymentAcquire } from "@/pages/SignUp/registrations";
import useStepper from "@/hooks/useStepper";
import RegistrationPrevBtn from "@/components/RegistrationPrevBtn";
import FormBadge from "@/pages/SignUp/common/FormBadge";
import AuthorizationFormHeader from "@/components/AuthorizationFormHeader";
import FormStepper from "@/pages/SignUp/common/FormStepper";
import BasicInformation from "@/pages/SignUp/common/BasicInformation";
import LocationInformation from "@/pages/SignUp/common/LocationInformation";
import ActivityInformation from "@/pages/SignUp/common/ActivityInformation";
import TransportInformation from "@/pages/SignUp/common/TransportInformation";
import AccommodationChoice from "@/pages/SignUp/common/AccommodationChoice";
import TicketPayment from "@/pages/SignUp/common/TicketPayment";
import { formHeaderTitles, Steps } from "@/pages/SignUp/StudyOnCampus";
import { useChangeTariffs } from "@/pages/ChangeTariff/change-tariffs";
import { RequireOfflineFormat } from "@/pages/ChangeTariff/RequireOfflineFormat";
import { changeTariffCommon } from "@/Schema/changeTariffSchema";
import { toastNotifications } from "@/components/Toastify";

const STEPS_CHAIN = [
    Steps.BASE_INFO,
    Steps.LOCATION_INFO,
    Steps.ACTIVITY_INFO,
    Steps.TRANSPORT_INFO,
    Steps.ACCOMODATION_INFO,
    Steps.PAYMENT_INFO,
];

const headerTitles = {
    ...formHeaderTitles,
    [Steps.BASE_INFO]: "Укажите данные для\nсмены тарифа",
};

export const TariffCommon: React.FC = () => {
    const formik = useChangeTariffs({
        values: {
            format: "offline",
            tariff: "common",
        },
        validationSchema: (initialValues) => changeTariffCommon(initialValues),
        onSuccess: async (values) => {
            if (values.legal_type === "individual") {
                await openPaymentAcquire();
            } else {
                toastNotifications.payment("accepted");
            }
        },
    });

    const { currStep, nextStep, prevStep } = useStepper(STEPS_CHAIN);
    const showSubtitle = Boolean(currStep === Steps.BASE_INFO);
    const title = headerTitles[currStep];

    return (
        <>
            {(currStep !== Steps.PAYMENT_INFO || formik.values.legal_type === "legal") && <RequireOfflineFormat />}

            {currStep !== Steps.BASE_INFO && <RegistrationPrevBtn toPrev={prevStep} />}

            {/* @ts-ignore */}
            <FormBadge text="Очное участие" />

            {/* @ts-ignore */}
            <AuthorizationFormHeader
                className="StudyOnCampus__Header"
                title={title}
                subtitle={showSubtitle && "Здравствуйте!"}
                subtitleOnTop
            />

            {currStep === Steps.ACCOMODATION_INFO && (
                <p className="StudyOnCampus__Accommodation-note">Проживание в кампусе оплачивается дополнительно</p>
            )}

            {/* @ts-ignore */}
            <FormStepper steps={6} startFrom={1} currentStep={currStep} />

            <form className="StudyOnCampus__Form" autoComplete="off" onSubmit={formik.handleSubmit}>
                {currStep === Steps.BASE_INFO && (
                    <BasicInformation formik={formik} withPhoneNumber disableEmail nextStep={nextStep} />
                )}

                {currStep === Steps.LOCATION_INFO && <LocationInformation formik={formik} nextStep={nextStep} />}

                {currStep === Steps.ACTIVITY_INFO && <ActivityInformation formik={formik} nextStep={nextStep} />}

                {currStep === Steps.TRANSPORT_INFO && <TransportInformation formik={formik} nextStep={nextStep} />}

                {currStep === Steps.ACCOMODATION_INFO && <AccommodationChoice formik={formik} nextStep={nextStep} />}

                {currStep === Steps.PAYMENT_INFO && <TicketPayment formik={formik} />}
            </form>
        </>
    );
};
