import { useInView } from 'react-intersection-observer';
import SkeletonImg from '@/components/SkeletonImg';
import Popup from '@/components/Popup';
import useToggle from '@hooks/useToggle';
import useMedia from "@/hooks/useMedia";
import { ReactComponent as ArrowRight } from './icons/arrow_right.svg';
import './index.scss';


const ExpertCard = ({ photo_url, name, surname, description }) => {
    const [isOpen, togglePopup] = useToggle();
    const { isMobile } = useMedia();
    const { ref, inView } = useInView({
        threshold: 0,
        triggerOnce: true
    });

    return (
        <>
            <div className='ExpertCard' ref={ref}>
                <div className='ExpertCard__img-container'>
                    {inView &&
                        <SkeletonImg
                            className='ExpertCard__img'
                            src={photo_url}
                            alt={name}
                            hash="LEHLk~WB2yk8pyo0adR*.7kCMdnj"
                        />
                    }
                </div>
                <div className='ExpertCard__body' onClick={togglePopup}>
                    <p className='ExpertCard__FirstName'>{name}</p>
                    {isMobile ? <p className='ExpertCard__SurName ExpertCard__SurName--mobile'>{surname} <ArrowRight /></p> : <p className='ExpertCard__SurName'>{surname}</p>}
                    {isMobile ? null : <p className='ExpertCard__text'>{description}</p>}
                </div>
            </div>
            {isMobile ?
                <Popup isPopupActive={isOpen} closePopup={togglePopup} >
                    <p className='ExpertCard__FirstName'>{name}</p>
                    <p className='ExpertCard__SurName'>{surname}</p>
                    <p className='ExpertCard__text ExpertCard__text--mobile'>{description}</p>
                </Popup>
                :
                null
            }
        </>
    )
}

export default ExpertCard;