import Headline from '@/components/Headline';
import Container from '@/components/Container';
import useMedia from "@/hooks/useMedia";
import Typography from '@/components/Typography';
import TrophyIcon from './icons/trophy.png';
import ThumbUpIcon from './icons/ThumbUp-icon.png';
import GreenLightTop from './icons/green-light-top.png';
import GreenLightBottom from './icons/green-light-bottom.png';
import GreenBlueLightBottom from './icons/green-blue-light-bottom.png';
import { ReactComponent as ExternalLinkMobileIcon } from './icons/external-link-mobile-icon.svg';
import externalLinkIcon from './icons/external-link-icon.png';
import { NavLink } from 'react-router-dom';
import routes from '@/routes';
import './index.scss';

const InvitationTitle = "Цель конкурса\nEdTech-компаний";
const InvitationText02 = "Они были приглашены на конференцию \n«Больше чем обучение» 20 октября:";
const btnText = "На страницу конкурса";

const Invitation = () => {
    const { isMobile } = useMedia();

    return (
        <section className='Invitation'>
            <img className='Invitation__Decoration02' src={GreenBlueLightBottom} alt='decoration light' loading='lazy'/>
            <Container className="Invitation__container">
                <Headline
                    className='Invitation__Headline'
                    level='h2'
                    theme='dark'
                >
                    Объявили 12 лучших решений конкурса <nobr>EdTech-компаний</nobr>
                </Headline>

                <div className='Invitation__Info'>
                    <div className='Invitation__Card'>
                        <img className='Invitation__Trophy-icon' src={TrophyIcon} alt='Trophy Icon' />
                        <h3 className='Invitation__Title'>{InvitationTitle}</h3>
                        <p className='Invitation__Text'><Typography className="Invitation__Text-gradient" component="span" color="light" gradient>Познакомить разработчиков</Typography> образовательных программ с качественными EdTech-продуктами, которые помогают:</p>
                        <ul className='Invitation__List'>
                            <li className='Invitation__List-item'>быстрее, качественнее или удобнее разрабатывать и проводить обучение;</li>
                            <li className='Invitation__List-item'>укрепить мотивацию участников обучения;</li>
                            <li className='Invitation__List-item'>персонализировать обучение.</li>
                        </ul>
                    </div>
                    <div className='Invitation__Card'>
                        <img className='Invitation__ThumbUp-icon' src={ThumbUpIcon} alt='Thumb up icon' />
                        <h3 className='Invitation__Title'>Получили 63 заявки,<br />из них выбрали<br />12 лучших решений</h3>
                        <p className='Invitation__Text'>{InvitationText02}</p>
                        <ul className='Invitation__List'>
                            <li className='Invitation__List-item'>организовали участие в выставке EdTech-продуктов для компаний-финалистов;</li>
                            <li className='Invitation__List-item'>топ-3 приняли участие в интервью с представителями компаний между сессиями;</li>
                            <li className='Invitation__List-item'>топ-3 торжественно наградили на конференции как победителей конкурса.</li>
                        </ul>
                    </div>
                </div>
                <NavLink className="Button Button--gradient Button--lg Invitation__Button" to={routes.contest}>
                    <span className="Button__Text">{btnText}</span>
                    <span className="Button__Icon">{isMobile ? <ExternalLinkMobileIcon /> : <img src={externalLinkIcon} alt='external Link Icon' />}</span>
                </NavLink>
            </Container>
        </section>
    )
}

export default Invitation;