import React from "react";
import classNames from "classnames";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";

import routes from "@/routes";
import Radio from "@/components/Radio";
import { changeTariffSchema } from "@/Schema/changeTariffSchema";
import Button from "@/components/Button";
import Tooltip from "@/components/Tooltip";
import PlanPriceTag from "@components/PlanPriceTag";
import { toastNotifications } from "@/components/Toastify";
import { ReactComponent as InfoIcon } from "./assets/info-icon.svg";
import { COMMON_TARIFF, FROM_EDUCATION, FROM_SBER, GENERAL_PRICE } from "@/constants/tariffs";
import "./index.scss";

const notes = {
    fromSber: (
        <>Мы предоставляем пригласительные билеты для сотрудников экосистемы Сбера. Количество&nbsp;ограничено.</>
    ),
    fromEduction: (
        <>Мы предоставляем скидку 33% для студентов и&nbsp;представителей государственных образовательных учреждений.</>
    ),
};

const PaidPlanForm = ({
    className,
    buttonText,
    showNotes,
    initValue = false,
    disabled = false,
    navigateToForms = false,
}) => {
    const navigate = useNavigate();

    const { values, handleChange, handleSubmit } = useFormik({
        initialValues: {
            tariff: initValue ? COMMON_TARIFF : "",
        },
        validationSchema: changeTariffSchema,
        onSubmit: async (formValues, actions) => {
            try {
                if (navigateToForms) {
                    switch (formValues.tariff) {
                        case FROM_EDUCATION:
                            navigate(routes.fromEducationSignup);
                            break;
                        case FROM_SBER:
                            navigate(routes.fromSberSignup);
                            break;
                        default:
                            navigate(routes.onCampusSignup);
                            break;
                    }
                } else {
                    switch (formValues.tariff) {
                        case FROM_EDUCATION:
                            navigate(routes.profileChangeTariffEducation);
                            break;
                        case FROM_SBER:
                            navigate(routes.profileChangeTariffSber);
                            break;
                        default:
                            navigate(routes.profileChangeTariffCommon);
                            break;
                    }
                }
            } catch {
                toastNotifications.error("Что-то пошло не так, попробуйте ещё раз");
            }
        },
    });

    const nextStepIsAllowed = () => {
        if (values.tariff && values.tariff !== FROM_SBER && values.tariff !== FROM_EDUCATION && values.tariff !== COMMON_TARIFF) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <div className={classNames(className, "PaidPlanForm")}>
            <form onSubmit={handleSubmit}>
                <p className="PaidPlanForm__Title">Выберите подходящий вам тариф</p>
                <div className="PaidPlanForm__Radio-wrapper">
                    <Radio
                        className="PaidPlanForm__Radio"
                        id="generalPlan"
                        name="tariff"
                        text="Общий тариф"
                        value={COMMON_TARIFF}
                        onChange={handleChange}
                        checked={values.tariff === COMMON_TARIFF}
                    />
                    <Radio
                        className="PaidPlanForm__Radio"
                        id="fromEducation"
                        name="tariff"
                        text="Я из образования"
                        value={FROM_EDUCATION}
                        onChange={handleChange}
                        checked={values.tariff === FROM_EDUCATION}
                    />
                    <Radio
                        className="PaidPlanForm__Radio"
                        id="fromSber"
                        name="tariff"
                        text="Я из Сбера"
                        value={FROM_SBER}
                        onChange={handleChange}
                        checked={values.tariff === FROM_SBER}
                    />
                </div>
                {showNotes && values.tariff  && (
                    <div className="PaidPlanForm__Notes PaidPlanForm__Notes--finsihed">
                        <InfoIcon />
                        <p>Закончились места.</p>
                    </div>
                )}
                <div className="PaidPlanForm__Price-wrapper">
                    <Tooltip
                        className="PaidPlanForm__Tooltip"
                        text={disabled ? disabled : values.tariff === FROM_SBER || values.tariff === FROM_EDUCATION || values.tariff === COMMON_TARIFF ? "Вы можете зарегистрироваться на онлайн-формат участия" : "Выберите тариф"}
                        diraction="top"
                        disabled={disabled ? false : nextStepIsAllowed()}
                    >
                        <Button
                            className="PaidPlanForm__Btn"
                            tag="button"
                            BtnType="submit"
                            text={buttonText}
                            size="lg"
                            type="colored"
                            isWide
                            gradient
                            disabled={disabled || values.tariff === FROM_SBER ? true : !nextStepIsAllowed()}
                        />
                    </Tooltip>
                    {values.tariff && values.tariff !== FROM_SBER && values.tariff !== FROM_EDUCATION && values.tariff !== COMMON_TARIFF && (
                        <PlanPriceTag
                            className="PaidPlanForm__Tag"
                            price={GENERAL_PRICE}
                            withDiscount={
                                values.tariff === FROM_EDUCATION ? true : values.tariff === FROM_SBER ? "100" : false
                            }
                        />
                    )}
                </div>
            </form>
        </div>
    );
};

export default PaidPlanForm;
