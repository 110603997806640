import React from "react";
import ReactDOM from "react-dom/client";
import "normalize.css";
import { initSentry } from "./utils/sentry";
import App from "./App";
import "./index.scss";

/**
 * try fix https://rocketsentry.ru/organizations/rsc/issues/176607/?project=90&referrer=project-issue-stream
 */
if (!window.localStorage) {
    window.localStorage = {
        _data: {},
        setItem: function (id, val) {
            this._data[id] = String(val);
        },
        getItem: function (id) {
            return this._data.hasOwnProperty(id) ? this._data[id] : null;
        },
        removeItem: function (id) {
            delete this._data[id];
        },
        clear: function () {
            this._data = {};
        },
    };
}

initSentry();

const root = ReactDOM.createRoot(document.getElementById("root"));

/**
 * <React.StrictMode> - не можно использоваться так как react-scrollmagic не портирован под последнюю версию реакт, из
 * этого дублируются контроллеры и сцены
 */
root.render(<App />);
