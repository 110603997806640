import ProgramContainer from "@/components/ProgramContainer";
import ProgramSlider from "@/components/ProgramSlider";
import { useContext } from "react";
import { ScheduleContext } from "@/context/ScheduleContext";
import Loader from "@/components/Loader";

const OnlineProgram: React.FC = () => {
    const { onlineProgram } = useContext(ScheduleContext);

    if (onlineProgram.length === 0 || !onlineProgram) {
        return <Loader />
    }

    return (
        <div className="OnlineProgram">
            {/* opening  */}
            <ProgramContainer
                data={onlineProgram[0]}
            />

            {/* discussion  */}
            <ProgramContainer
                data={onlineProgram[1]}
                mainStage
                withLink
            />

            {/* coffee_break  */}
            <ProgramContainer
                data={onlineProgram[2]}
            />

            {/* multiple_sessions 01  */}
            <div className="OnCampusProgram__Multiple_sessions">
                <ProgramSlider data={[onlineProgram[3], onlineProgram[4], onlineProgram[5]]} />
            </div>

            {/* coffee_break  */}
            <ProgramContainer
                data={onlineProgram[6]}
            />

            {/* multiple_sessions 02  */}
            <div className="OnCampusProgram__Multiple_sessions">
                <ProgramSlider data={[onlineProgram[7], onlineProgram[8], onlineProgram[9]]} />
            </div>

            {/* coffee_break  */}
            <ProgramContainer
                data={onlineProgram[10]}
            />

            {/* multiple_sessions 03  */}
            <div className="OnCampusProgram__Multiple_sessions">
                <ProgramSlider data={[onlineProgram[11], onlineProgram[12], onlineProgram[13]]} />
            </div>

            <ProgramContainer
                data={onlineProgram[14]}
            />
        </div>
    )
}

export default OnlineProgram;