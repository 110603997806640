import { useEffect, useRef } from "react";
import Inputmask from "inputmask";
import classNames from "classnames";
import "./index.scss";

const PhoneInput = ({ className, id, name, label, placeholder, value, onChange, onBlur, error }) => {
    const inputRef = useRef();

    useEffect(() => {
        const maskOptions = {
            mask: "+7 (999) 999-99-99",
        };

        Inputmask(maskOptions).mask(inputRef.current);
    }, []);

    return (
        <div className={classNames(className, "PhoneInput")}>
            <label htmlFor={id} className="PhoneInput__Label">
                {label}
            </label>
            <input
                className={classNames("PhoneInput__Input", {
                    "PhoneInput__Input--Error": error,
                })}
                ref={inputRef}
                id={id}
                name={name}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                data-lpignore="true"
            />
        </div>
    );
};

export default PhoneInput;
