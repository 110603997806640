import Container from '@/components/Container';
import Typography from '@/components/Typography';
import { ReactComponent as Arrow } from './icons/outlined_arrow.svg';
import './index.scss';

const Goals = () => {
    return (
        <section className="Goals">
            <Container>
                <div className="Goals__IntroList">
                    <div className="Goals__Info">
                        <h2 className="Goals__Title">Цель конкурса</h2>
                        <p className="Goals__Description"><Typography className="Goals__Description-gradient" component='span' color='white' gradient>Познакомить разработчиков</Typography> образовательных программ с качественными EdTech-продуктами, которые помогают:</p>
                        <ul className="Goals__List">
                            <li className="Goals__List-item">быстрее, качественнее или удобнее разрабатывать и проводить обучение;</li>
                            <li className="Goals__List-item">укрепить мотивацию участников обучения;</li>
                            <li className="Goals__List-item">персонализировать обучение.</li>
                        </ul>
                    </div>
                    <div className="Goals__Info">
                        <h2 className="Goals__Title">Результаты</h2>
                        <p className="Goals__Description"><Typography className="Goals__Description-gradient" component='span' color='white' gradient>Победителей</Typography> представили во время конференции:</p>
                        <ul className="Goals__List">
                            <li className="Goals__List-item">на выставке EdTech-продуктов</li>
                            <li className="Goals__List-item">в интервью с представителями компаний между сессиями</li>
                            <li className="Goals__List-item">на торжественном награждении победителей выставки</li>
                        </ul>
                    </div>
                </div>
              {/*  представим во время конференции: */}
                {/* this link is removed in the current stage ! */}
                {/* <a className="Goals__Action" href="https://public.oprosso.sberbank.ru/p/adcjxqnq" target='_blank'>
                    <Arrow />
                    <span className="Goals__link" >{`Отправить \nзаявку`}</span>
                </a> */}
            </Container>
        </section>
    )
}

export default Goals;