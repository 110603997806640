import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import ScrollToView from "@components/ScrollToView/ScrollToView";
import Header from "@/layouts/Header";
import Footer from "@/layouts/Footer";
import CookieNotificator from "@components/CookieNotificator";
import ToastifyContainer from "@/components/Toastify";
// import CallToActionBanner from "@/layouts/CallToActionBanner";
import ScrollToTop from "@/utils/ScrollToTop";
import routes from "@/routes";
import { toastNotifications } from "@/components/Toastify";
import { useIsAuthenticated } from "react-auth-kit";

const excludedPaths = [
    routes.signin,
    routes.forgetPassword,
    routes.signup,
    routes.onlineSignup,
    routes.fromEducationSignup,
    routes.fromSberSignup,
    routes.onCampusSignup,
    routes.vipSignup,
    routes.profileChangeTariffCommon,
    routes.profileChangeTariffEducation,
    routes.profileChangeTariffSber,
    routes.chatModeration,
    routes.questionsModeration,
    routes.moderatedQuestions,
];

const shouldDisplayHeaderFooter = (pathname) => {
    if (pathname.includes(`reset_password`)) {
        return false;
    }

    return !excludedPaths.includes(pathname);
};

const RootLayout = () => {
    const { pathname, search } = useLocation();
    const navigate = useNavigate();
    const shouldDisplay = shouldDisplayHeaderFooter(pathname);
    const authentication = useIsAuthenticated();
    const isAuth = authentication();

    useEffect(() => {
        const showPaymentStatus = () => {
            const searchParams = new URLSearchParams(search);
            const payment = searchParams.get("payment");
            const type = searchParams.get("type");

            if (payment && type) {
                switch (true) {
                    case payment === "success" && type === "legal":
                        toastNotifications.payment("accepted");
                        break;
                    case payment === "success" && type === "individual":
                        toastNotifications.payment("success");
                        break;
                    case payment === "fail":
                        toastNotifications.payment("failed");
                        break;
                    default:
                        break;
                }
                searchParams.delete("payment");
                searchParams.delete("type");
                navigate(
                    {
                        search: searchParams.toString(),
                    },
                    {
                        replace: true,
                    },
                );
            }
        };

        if (isAuth) {
            showPaymentStatus();
        }
    }, [search, isAuth]);

    return (
        <ScrollToView>
            <ScrollToTop>
                {/* {pathname === "/" ? <CallToActionBanner /> : null} */}
                {shouldDisplay && <Header />}
                <Outlet />
                {shouldDisplay && <Footer />}
                <ToastifyContainer />
                <CookieNotificator />
            </ScrollToTop>
        </ScrollToView>
    );
};

export default RootLayout;
