import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useUser } from "@/context/UserContext";
import Loader from "@/components/Loader";
import routes from "@/routes";
import "./index.scss";

export type ModeratorRouteProps = React.PropsWithChildren;

const ModeratorRoute: React.FC<ModeratorRouteProps> = ({ children }) => {
    const { userInfo, isLoading } = useUser();

    if (isLoading) {
        return (
            <div className="ModeratorRoute__Loading">
                <Loader />
            </div>
        );
    }

    if (!isLoading) {
        if (userInfo?.role === "moderator") {
            return children || <Outlet />;
        } else {
            return <Navigate to={routes.conference} replace />;
        }
    }
};

export default ModeratorRoute;
