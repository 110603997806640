import React, { useCallback, useEffect, useState } from 'react';
import Button from "@components/Button";
import Cookies from 'js-cookie'
import './index.scss'

const CookiesName = 'is-accept-privacy';

const CookieNotificator = () => {
    const [showNotification, setShowNotification] = useState(false);

    useEffect(() => setShowNotification(Cookies.get(CookiesName) === 'true'), []);

    const handleClick = () => {
        Cookies.set(CookiesName, false);
        setShowNotification(false);
    }

    const renderNotification = useCallback(() => {
        if (!showNotification) return null;
        return (
            <div className="CookieNotificator">
                <h6 className="CookieNotificator__Title">
                    Используем куки для улучшения работы сайта
                </h6>

                <p className="CookieNotificator__Text">
                    СберУниверситет, как и большинство сайтов в интернете, использует файлы cookie. На основе этих
                    данных мы делаем наш сайт удобнее и эффективнее для пользователей. Продолжая пользоваться этим
                    сайтом, вы соглашаетесь
                    на использование cookie и обработку данных в соответствии
                    с Политикой обработки&nbsp;
                    <a className="CookieNotificator__Link" href="">персональных данных.</a>
                </p>
                <Button
                    text="Понятно"
                    size="sm"
                    type="transparent"
                    isWide
                    onClick={handleClick}
                />
            </div>
        )
    }, [showNotification])

    return (
        <>
            {renderNotification()}
        </>
    );
};

export default CookieNotificator;