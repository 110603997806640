import { useMemo, useState } from "react";
import { Schema } from "yup";
import { FormikHelpers, useFormik } from "formik";
import { isAxiosError } from "axios";

import { useUser } from "@/context/UserContext";
import { ActivityType, FormatType, TariffType } from "@/types/registration";
import { toastNotifications } from "@/components/Toastify";
import { useActivityControl } from "@/pages/SignUp/registrations";
import { updateUserInformations } from "@/api";
import { delay } from "@/utils/promise";

export const useChangeTariffs = (options: {
    values: { format: FormatType; tariff: TariffType; activity?: ActivityType[] };
    validationSchema: (initialValues: any) => Schema;
    onSuccess: (values: any, formikHelpers: FormikHelpers<any>) => any;
}) => {
    const { values, validationSchema, onSuccess } = options;
    const { userInfo, setUserInfo } = useUser();

    const initialValues = useMemo(() => {
        return {
            legal_type: userInfo?.info.legal_type ?? "individual",
            name: userInfo?.name ?? "",
            surname: userInfo?.surname ?? "",
            email: userInfo?.email ?? "",
            phone: userInfo?.info.phone ?? "",
            agree_processing_of_personal_data: false,
            country_id: userInfo?.info.country_id ?? "",
            city: userInfo?.info.city ?? "",
            place_of_study: userInfo?.info.place_of_study ?? "",
            speciality: userInfo?.info.speciality ?? "",
            place_of_work: userInfo?.info.place_of_work ?? "",
            position: userInfo?.info.position ?? "",
            transport: userInfo?.info.transport ?? "",
            car_brand: userInfo?.info.car_brand ?? "",
            car_state_number: userInfo?.info.car_state_number ?? "",
            is_planning_to_stay_at_the_hotel: userInfo?.info.is_planning_to_stay_at_the_hotel ?? null,
            activity: [
                ...(userInfo?.info.place_of_work ? ["work"] : []),
                ...(userInfo?.info.place_of_study ? ["study"] : []),
                ...(!userInfo?.info.place_of_work && !userInfo?.info.place_of_study ? ["no_activity"] : []),
            ] as Array<ActivityType>,
            ...values,
        };
    }, [userInfo, JSON.stringify(values)]);

    const [enableReinitialize, setEnableReinitialize] = useState(true);

    const formik = useFormik({
        initialValues,
        enableReinitialize,
        validateOnMount: true,
        validateOnBlur: true,
        validateOnChange: true,
        validationSchema: validationSchema(initialValues),
        onSubmit: async (values, formikHelpers) => {
            try {
                setEnableReinitialize(false);
                await delay(50);
                const newUserData = await updateUserInformations(values);
                setUserInfo(newUserData);
                await onSuccess(values, formikHelpers);
            } catch (e) {
                if (isAxiosError(e)) {
                    if (e.response?.data?.errors) {
                        formikHelpers.setErrors(e.response.data.errors);
                    }
                    const errorMsg =
                        e.response?.data?.error ||
                        e.response?.data?.message ||
                        "Что-то пошло не так, попробуйте ещё раз";
                    toastNotifications.error(errorMsg);
                } else {
                    toastNotifications.error("Что-то пошло не так, попробуйте ещё раз");
                }
            } finally {
                setEnableReinitialize(true);
            }
        },
    });

    useActivityControl(formik);

    return formik;
};
