import React, { useEffect, useState, useContext } from 'react';
import Container from '@/components/Container';
import NavBar from './components/NavBar';
import Logo from '@/components/Logo';
import classNames from "classnames";
import { debounce } from "@/utils/debounce";
import useAnimate from '@/hooks/useAnimate';
import './index.scss';

const Header = () => {
    const [initialHeader, setInitialHeader] = useState(true);
    const [animate] = useAnimate();

    const listenScrollEvent = () => {
        setInitialHeader(window.scrollY < 100)
    };

    useEffect(() => {
        const debouncedScrollHandler = debounce(listenScrollEvent, 10);
        window.addEventListener('scroll', debouncedScrollHandler)
        return () => window.removeEventListener('scroll', debouncedScrollHandler)
    }, []);

    return (
        <header className={classNames({
            'Header': true,
            'Header--Scroll': !initialHeader,
            'Header--Animate': animate,
        })}>
            <div className="Header__Wrapper">
                <Container className="Header__Container">
                    <div className="Header__Inner">
                        <Logo />
                        <NavBar />
                    </div>
                </Container>
            </div>
        </header>
    );
};

export default Header;