import React, { useEffect, useState } from "react";
import Avatar from "@/components/Avatar";
import { ReactComponent as IconReset } from "./reset-avatar.svg";
import ImageInput from "@/components/ImageInput";
import "./AvatarInput.scss";

export type AvatarInputProps = {
    src?: string;
    userName?: string;
    onChange?: (file?: File | null) => void;
};

export const AvatarInput: React.FC<AvatarInputProps> = (props) => {
    const { src, userName, onChange } = props;

    const [file, setFile] = useState<File | null>();
    const [fileSrc, setFileSrc] = useState<string>();

    useEffect(() => {
        setFileSrc(undefined);
        if (file) {
            const newFileSrc = URL.createObjectURL(file);
            setFileSrc((prevFileSrc) => {
                if (prevFileSrc) {
                    URL.revokeObjectURL(prevFileSrc);
                }
                return newFileSrc;
            });
        } else {
            setFile(undefined);
            setFileSrc(undefined);
        }
    }, [file]);

    const handleChangeImage = (newFile?: File | null) => {
        setFile(newFile);
        onChange?.(newFile);
    };

    const handleClickResetFile = () => {
        handleChangeImage(null);
    };

    return (
        <div className="AvatarInput">
            <div className="AvatarInput__PreviewWrap">
                <Avatar className="AvatarInput__Preview" src={fileSrc || src} name={userName} />
                {file && (
                    <div className="AvatarInput__Reset" onClick={handleClickResetFile}>
                        <IconReset />
                    </div>
                )}
            </div>
            <ImageInput className="AvatarInput__Control" value={file} onChange={handleChangeImage} />
        </div>
    );
};
