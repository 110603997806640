import { useContext, useEffect } from "react";
import { BroadcastContext } from "@/context/BroadcastContext";
import LiveChat from "@/components/LiveChat";
import Container from "@/components/Container";
import { ErrorBoundary } from "react-error-boundary";
import LiveChatFallback from "@/components/LiveChatFallback";
import BroadcastHeader from "@/components/BroadcastHeader";
import "./index.scss";


const CommenChatModeration: React.FC = () => {
    const { chatMessagesForModerators, toggleChatType } = useContext(BroadcastContext);

    useEffect(() => {
        toggleChatType("common");
    }, [])

    return (
        <main className="CommenChatModeration">
            <Container className="CommenChatModeration__Container">
                <div className="CommenChatModeration__Header">
                    <BroadcastHeader isForModerators />
                </div>
                <ErrorBoundary FallbackComponent={LiveChatFallback}>
                    <LiveChat
                        isCollapsible={false}
                        isForModerators={true}
                        chatData={chatMessagesForModerators}
                        chatType={"common"}
                        isCommonChatModerated
                    />
                </ErrorBoundary>
            </Container>
        </main>
    )
}

export default CommenChatModeration;
