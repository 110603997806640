import ProgramContainer from "@/components/ProgramContainer";
import ProgramSlider from "@/components/ProgramSlider";
import { useContext } from "react";
import { ScheduleContext } from "@/context/ScheduleContext";
import Loader from "@/components/Loader";
import './index.scss';

const OnCampusProgram: React.FC = () => {
    const { onCampusProgram } = useContext(ScheduleContext);

    if (onCampusProgram.length === 0 || !onCampusProgram) {
        return <Loader />
    }

    return (
        <div className="OnCampusProgram">
            {/* opening  */}
            <ProgramContainer
                data={onCampusProgram[0]}
            />

            {/* discussion  */}
            <ProgramContainer
                data={onCampusProgram[1]}
                mainStage
                withLink
            />

            {/* coffee_break  */}
            <ProgramContainer
                data={onCampusProgram[2]}
            />

            {/* multiple_sessions 01  */}
            <div className="OnCampusProgram__Multiple_sessions">
                <ProgramSlider data={[onCampusProgram[3], onCampusProgram[4], onCampusProgram[5]]} />
            </div>

            <ProgramContainer
                data={onCampusProgram[6]}
            />

            {/* multiple_sessions 02  */}
            <div className="OnCampusProgram__Multiple_sessions">
                <ProgramSlider data={[onCampusProgram[7], onCampusProgram[8], onCampusProgram[9]]} />

                <ProgramContainer
                    className="OnCampusProgram__Practice"
                    data={onCampusProgram[10]}
                    withLink
                    gradientSubtitle
                />
            </div>

            {/* break  */}
            <ProgramContainer
                data={onCampusProgram[11]}
            />

            {/* multiple_sessions 03  */}
            <div className="OnCampusProgram__Multiple_sessions">
                <ProgramSlider data={[onCampusProgram[12], onCampusProgram[13], onCampusProgram[14]]} />

                <ProgramContainer
                    className="OnCampusProgram__Practice"
                    data={onCampusProgram[15]}
                    withLink
                    gradientSubtitle
                />
            </div>

            {/* networking  */}
            <ProgramContainer
                data={onCampusProgram[16]}
            />

            {/* finish  */}
            <ProgramContainer
                data={onCampusProgram[17]}
            />
        </div>
    )
}

export default OnCampusProgram;