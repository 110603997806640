import { useState } from "react";
import Container from "@/components/Container";
import Headline from "@/components/Headline";
import SliderBtn from '@/components/SliderBtn';
import GreenLightTop from "./assets/green-light-top.png";
import GreenLightBottom from "./assets/green-light-bottom.png";
import useMedia from "@/hooks/useMedia";
import classNames from "classnames";
import { PICTURES_ARCHIVE } from "@/constants/picturesArchive";
import SkeletonImg from "@/components/SkeletonImg";
import PhotoCard from "./PhotoCard";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Thumbs, FreeMode } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import "./index.scss";
import Popup from "@/components/Popup";


const PhotoArchive: React.FC = () => {
    const { isMobile } = useMedia();
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [isShowPopup, setShowPopup] = useState(false);
    const [selectIndexPopup, setSelectIndexPopup] = useState(0)

    const renderSlides = PICTURES_ARCHIVE?.map((el, index) => {
        return (
            <SwiperSlide key={index} className='PhotoArchive__Slide-Custom'>
                <PhotoCard
                    srcPng={isMobile ? el.src_for_mobile : el.src_original}
                    srcWebp={el.src_original_webp}
                    onClick={() => {
                        setSelectIndexPopup(index);
                        setShowPopup(true);
                    }}
                />
            </SwiperSlide>
        )
    });

    const renderSlidesPopup = PICTURES_ARCHIVE?.map((el, index) => {
        return (
            <SwiperSlide key={index} className='PhotoArchive__Slide-Custom'>
                <picture>
                    <source srcSet={el.src_original_webp} type="image/webp" />
                    <img
                        className="PhotoCard__Img"
                        src={el.src_original}
                        alt=""
                        loading="lazy"
                    />
                </picture>
            </SwiperSlide>
        )
    });

    const renderSlidesThumbs = PICTURES_ARCHIVE?.map((el, index) => {
        return (
            <SwiperSlide key={index} className="PhotoArchive__Slide-Thumb">
                <SkeletonImg
                    src={el.src_small}
                    hash="LEHLk~WB2yk8pyo0adR*.7kCMdnj"
                    alt="Атмосфера мероприятия"
                    loading="lazy"
                />
            </SwiperSlide>
        )
    })

    const swiperParams = {
        slidesPerView: 1,
        loop: true,
        modules: [Navigation, Thumbs, FreeMode],
        navigation: {
            prevEl: '.PhotoArchive__Slider-Prev',
            nextEl: '.PhotoArchive__Slider-Next',
        },
        thumbs: { swiper: thumbsSwiper },
    };

    const swiperParamsPopup = {
        slidesPerView: 1,
        loop: true,
        modules: [Navigation],
        navigation: {
            prevEl: '.PhotoArchive__Slider-Prev',
            nextEl: '.PhotoArchive__Slider-Next',
        },
    };

    return (
        <section className="PhotoArchive">
            <img className='PhotoArchive__Decoration-01' src={GreenLightTop} alt="Green Light Top" />
            <img className='PhotoArchive__Decoration-02' src={GreenLightBottom} alt="Green Light Bottom" />
            <Container className="PhotoArchive__container">
                <Headline
                    className='PhotoArchive__Headline'
                    level='h2'
                    theme='dark'
                >
                    Атмосфера мероприятия
                </Headline>

                <div className="PhotoArchive__Slider-Container">
                    <SliderBtn
                        className={classNames({
                            'PhotoArchive__Slider-Prev': true,
                            'PhotoArchive__Hide-Btn': isMobile,
                        })}
                        arrowDiraction='left'
                    />
                    <Swiper
                        className='PhotoArchive__Swiper'
                        {...swiperParams}
                    >
                        {renderSlides}
                    </Swiper>
                    <SliderBtn
                        className={classNames({
                            'PhotoArchive__Slider-Next': true,
                            'PhotoArchive__Hide-Btn': isMobile,
                        })}
                        arrowDiraction='right'
                    />
                </div>
                <Swiper
                    className='PhotoArchive__Swiper-Thumbs'
                    // @ts-ignore
                    onSwiper={setThumbsSwiper}
                    spaceBetween={isMobile ? 4 : 12}
                    slidesPerView={5}
                    freeMode={true}
                    watchSlidesProgress={true}
                    modules={[FreeMode, Navigation, Thumbs]}
                >
                    {renderSlidesThumbs}
                </Swiper>
                <div
                    className={classNames({
                        "PhotoArchive__Slider-Btns": true,
                        "PhotoArchive__Hide-Btn": !isMobile,
                    })}
                >
                    <SliderBtn className='PhotoArchive__Slider-Prev' arrowDiraction='left' />
                    <SliderBtn className='PhotoArchive__Slider-Next' arrowDiraction='right' />
                </div>
            </Container>


            <Popup
                className="PhotoCard__Popup"
                isPopupActive={isShowPopup}
                closePopup={() => setShowPopup(false)}
                position="center"
                withCloseBtn
                withBodyScroll
            >
                <div className="PhotoArchive__Slider-Container">
                    <SliderBtn
                        className={classNames({
                            'PhotoArchive__Slider-Prev': true,
                            'PhotoArchive__Slider-btn-white': true,
                            'PhotoArchive__Hide-Btn': isMobile,
                        })}
                        arrowDiraction='left'
                    />
                    <Swiper
                        {...swiperParamsPopup}
                        initialSlide={selectIndexPopup}
                    >
                        {renderSlidesPopup}
                    </Swiper>
                    <SliderBtn
                        className={classNames({
                            'PhotoArchive__Slider-Next': true,
                            'PhotoArchive__Slider-btn-white': true,
                            'PhotoArchive__Hide-Btn': isMobile,
                        })}
                        arrowDiraction='right'
                    />
                </div>
            </Popup>

        </section>
    )
}

export default PhotoArchive;