import IntroCompetition from "./Intro";
import Goals from "./Goals";
import Terms from "./Terms";
import Award from "./Award";
import Testimonials from "./Testimonials";
import PastConferenceVideo from "./PastConferenceVideo";
import Stages from "./Stages";
import CallToAction from "./CallToAction";
import Criteria from "./Criteria";
import Winners from "./Winners";
import { Helmet } from "react-helmet";
import Divider from "@/components/Divider";
import Container from "@/components/Container";
import "./index.scss";

const Competition = () => {
    return (
        <>
            <Helmet>
                <title>Конкурс для EduTech-компании | Больше чем обучение - СберУниверсите</title>
                <meta
                    name="description"
                    content="До 23 сентября принимает заявки на участие в выставке на конференции «Больше чем обучение». В конкурсе принять участие могут EduTech-компании, которые развивают свои технологические продукты в сфере образования и хотят и показать"
                />
            </Helmet>
            <main className="main">
                <IntroCompetition />
                <Goals />
                <Winners />
                {/* <Terms /> */}
                {/* <Award /> */}
                {/* <Testimonials /> */}
                {/* <PastConferenceVideo /> */}
                {/* <Stages /> */}
                <Criteria />
                {/* <CallToAction /> */}
            </main>
        </>
    );
};

export default Competition;
