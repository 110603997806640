import React, { PropsWithChildren } from "react";
import classNames from "classnames";

import useMedia from "@/hooks/useMedia";
import useToggle from "@/hooks/useToggle";
import { ReactComponent as ArrowDown } from "./assets/arrow-down.svg";
import { ReactComponent as CloseIcon } from "./assets/close-icon.svg";
import "./index.scss";

const PasswordRules = ({
    children,
    className,
}: PropsWithChildren<{
    className?: string;
}>) => {
    const { isMobile, isTablet } = useMedia();
    const [isActive, toggle] = useToggle();

    return (
        <div className={classNames(className, "PasswordRulesWrapper")}>
            {children}
            <div className="PasswordRules">
                {isTablet || isMobile ? (
                    <p className="PasswordRules__Title" onClick={toggle}>
                        Требования к паролю
                        <ArrowDown
                            className={classNames({
                                "PasswordRules__Title-icon": true,
                                "PasswordRules__Title-icon--active": isActive,
                            })}
                        />
                    </p>
                ) : (
                    <p className="PasswordRules__Title">Требования к паролю:</p>
                )}
                <ul
                    className={classNames("PasswordRules__List", {
                        "PasswordRules__List-small-screen": isMobile || isTablet,
                        "PasswordRules__List-small-screen--active": isActive,
                    })}
                >
                    <span className="PasswordRules__Close-icon" onClick={toggle}>
                        <CloseIcon />
                    </span>
                    <li className="PasswordRules__Item">Не менее 8 символов</li>
                    <li className="PasswordRules__Item">Только латинские буквы</li>
                    <li className="PasswordRules__Item">Минимум 1 заглавная буква</li>
                    <li className="PasswordRules__Item">Минимум 1 цифра</li>
                </ul>
            </div>
        </div>
    );
};

export default PasswordRules;
