import { useState } from "react";
import "./index.scss";
import { recoverPasswordByEmail } from "@/api";
import { ReactComponent as CloseIcon } from "./icons/close.svg";
import Popup from "@/components/Popup";
import { toastNotifications } from "@/components/Toastify";
import IsRefreshPasword from "./components/IsRefreshPassword";
import IsTimer from "./components/IsTimer";

export type PopupRefreshPasswordProps = { isOpen: boolean; onClose?: () => void; userEmail: string };

const PopupRefreshPassword = (props: PopupRefreshPasswordProps) => {
    const { isOpen, userEmail, onClose } = props;
    const [isShowTimer, setIsShowTimer] = useState(false);
    const [isSendingRefreshPassword, setIsSendingRefreshPassword] = useState(false);

    const [timerNow, setTimerNow] = useState(Date.now());

    const handleRecoveryPassword = async () => {
        try {
            setIsSendingRefreshPassword(true);
            await recoverPasswordByEmail({
                email: userEmail,
            });
            setIsShowTimer(true);
            setTimerNow(Date.now());
        } catch (e) {
            toastNotifications.error("Что-то пошло не так. Повторите попытку позже");
        } finally {
            setIsSendingRefreshPassword(false);
        }
    };

    return (
        // @ts-ignore
        <Popup className="PopupRefreshPassword" isPopupActive={isOpen} closePopup={onClose}>
            <div className="PopupRefreshPassword__Wrap">
                <div className="PopupRefreshPassword__Content" onClick={(evt) => evt.stopPropagation()}>
                    <button className="PopupRefreshPassword__Close" onClick={onClose}>
                        <CloseIcon />
                    </button>
                    {!isShowTimer ? (
                        <IsRefreshPasword
                            disabled={isSendingRefreshPassword}
                            onRecoveryPassword={handleRecoveryPassword}
                        />
                    ) : (
                        <IsTimer
                            key={timerNow}
                            now={timerNow}
                            userEmail={userEmail}
                            disabled={isSendingRefreshPassword}
                            onResend={handleRecoveryPassword}
                        />
                    )}
                </div>
            </div>
        </Popup>
    );
};

export default PopupRefreshPassword;
