import { useState } from 'react';
import { nanoid } from 'nanoid';
import ProgramContainer from '@/components/ProgramContainer';
import useCustomScreen from '@/hooks/useCustomScreen';
import { SessionDataType } from '@/types/interfaces';
import { ReactComponent as SliderNavBtn } from './assets/arrow-right.svg';
import { Swiper, SwiperSlide, SwiperProps } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css';
import "./index.scss";

type Props = {
    data: SessionDataType[]
}

const ProgramSlider: React.FC<Props> = ({ data }) => {
    const [isScreenActive] = useCustomScreen(992, 1160);
    const [swiperId, setSwiperId] = useState<string>(nanoid());

    const renderSlides = data.map((el, index) => {
        return (
            <SwiperSlide key={index} className='ProgramSlider__Slider'>
                <ProgramContainer
                    className="ProgramSlider__MultipleSessions"
                    data={el}
                    withLink
                    vertical
                />
            </SwiperSlide>
        )
    })

    const sliderParams: SwiperProps = {
        spaceBetween: 12,
        slidesPerView: isScreenActive ? 2.2 : "auto",
        slidesOffsetBefore: 12,
        centeredSlides: true,
        centeredSlidesBounds: true,
        grabCursor: isScreenActive ? true : false,
        modules: [Navigation],
        navigation: {
            prevEl: `.to-Prev-${swiperId}`,
            nextEl: `.to-Next-${swiperId}`,
        },
    }

    return (
        <div className='ProgramSlider'>
            <Swiper
                {...sliderParams}
            >
                {renderSlides}
            </Swiper>
           
            <div className="ProgramSlider__Slider-Btns">
                <div className={`ProgramSlider__Slider-Prev to-Prev-${swiperId}`}>
                    <SliderNavBtn />
                </div>
                <div className={`ProgramSlider__Slider-Next to-Next-${swiperId}`}>
                    <SliderNavBtn />
                </div>
            </div>
            <div className="ProgramSlider__TextInfo">Параллельные сессии</div> 
        </div>
    )
}

export default ProgramSlider;