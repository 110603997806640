import { ReactComponent as ErrorIcon } from "./assets/error-icon.svg";
import classNames from "classnames";
import "./index.scss";

const ErrorMessage = ({ text, className }) => {
    return (
        <div className={classNames(className, "ErrorMessage")}>
            <ErrorIcon />
            <p>{text}</p>
        </div>
    );
};

export default ErrorMessage;
