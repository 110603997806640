import { NavLink } from "react-router-dom";
import classNames from "classnames";
import { HashLink } from 'react-router-hash-link';
import "./index.scss";

type LinkType = {
    link: string,
    text: string,
    isHashLink: boolean,
}

type Props = {
    className?: string,
    links: LinkType[]
}

const DropdownMenu: React.FC<Props> = ({ links, className }) => {

    const scrollToSection = (element: HTMLElement) => {

        element.scrollIntoView({
            block: "end",
            behavior: "instant"
        })
    }

    const renderlinks = links?.map((el, index) => {
        return (
            <li
                key={index}
                className="DropdownMenu__Item"
            >
                {
                    el.isHashLink ?
                        < HashLink
                            className="DropdownMenu__Link"
                            to={el.link}
                            scroll={(el) => scrollToSection(el)}
                        >
                            {el.text}
                        </HashLink >
                        :
                        <NavLink
                            className="DropdownMenu__Link"
                            to={el.link}
                        >
                            {el.text}
                        </NavLink>
                }
            </li >
        )
    })

    return (
        <div className={classNames(className, {
            "DropdownMenu": true,
        })}>
            <ul className="DropdownMenu__Inner">
                {renderlinks}
            </ul>
        </div>
    )
}

export default DropdownMenu;