import React from "react";
import Countdown from "react-countdown";
import { TIME_COUNTDOWN } from "../../constans/time-countdown";
import RefreshSuccessPasswordImage from "./../../icons/send-succes-icon.png";

import "./index.scss";

const IsTimer = ({ disabled, userEmail, onResend, now }) => {
    const renderer = ({ seconds, minutes, completed }) => {
        if (completed) {
            return (
                <p
                    className="PopupRefreshPassword__Text-Timer PopupRefreshPassword__Text-Timer--Clickable"
                    onClick={() => {
                        if (disabled) return;
                        onResend();
                    }}
                >
                    <span>Отправить повторно</span>
                </p>
            );
        } else {
            const formattedSeconds = seconds.toString().padStart(2, "0");
            const formattedMinutes = minutes.toString().padStart(2, "0");
            return (
                <p className="PopupRefreshPassword__Text-Timer">
                    <span>Отправить повторно</span> {formattedMinutes}:{formattedSeconds}
                </p>
            );
        }
    };
    return (
        <>
            <div className="PopupRefreshPassword__Image">
                <img src={RefreshSuccessPasswordImage} alt="Письмо отправлено" />
            </div>
            <div className="PopupRefreshPassword__Title">Письмо отправлено</div>
            <p className="PopupRefreshPassword__Text">
                Мы отправили вам письмо на <b>{userEmail}</b> с ссылкой для подтверждения
            </p>
            <Countdown date={now + TIME_COUNTDOWN * 1000} renderer={renderer} />
        </>
    );
};

export default IsTimer;
