import React from "react";

import BasicInformation from "../common/BasicInformation";
// import ActivityInformation from "../common/ActivityInformation";
// import LocationInformation from "../common/LocationInformation";
import CreatePassword from "../common/CreatePassword";
import ToSignIn from "../common/ToSignIn";
import AuthorizationFormHeader from "@/components/AuthorizationFormHeader";
import RegistrationPrevBtn from "@/components/RegistrationPrevBtn";
import FormStepper from "../common/FormStepper";
import { signUpOnlineSchema } from "@/Schema/signUpSchema";
import { toastNotifications } from "@/components/Toastify";
import FormBadge from "../common/FormBadge";
import useStepper from "@/hooks/useStepper";
import { useRegistrations } from "@/pages/SignUp/registrations";

import "./index.scss";

const Steps = {
    BASE_INFO: 1,
    // LOCATION_INFO: 2,
    // ACTIVITY_INFO: 3,
    PASSWORD_INFO: 2,
};

const STEPS_CHAIN = [Steps.BASE_INFO, Steps.PASSWORD_INFO];

const formHeaderTitles = {
    [Steps.BASE_INFO]: "Создайте свой профиль",
    // [Steps.LOCATION_INFO]: "Укажите своё\nместонахождение",
    // [Steps.ACTIVITY_INFO]: "Укажите свой\nрод деятельности",
    [Steps.PASSWORD_INFO]: "Придумайте пароль\nдля вашего аккаунта",
};

const StudyOnline = () => {
    const formik = useRegistrations({
        values: {
            format: "online",
            tariff: "free",
        },
        validationScheme: signUpOnlineSchema,
        onRegistered: async () => {
            toastNotifications.success("Вы успешно зарегистрировались");
            window.ym?.(94122972, "reachGoal", "form_online");
        },
    });

    const { currStep, nextStep, prevStep } = useStepper(STEPS_CHAIN);
    const showSubtitle = Boolean(currStep === Steps.BASE_INFO);
    const title = formHeaderTitles[currStep] ?? formHeaderTitles[Steps.BASE_INFO];

    return (
        <>
            {currStep !== Steps.BASE_INFO && <RegistrationPrevBtn toPrev={prevStep} />}

            <FormBadge text="Онлайн-участие" />

            <AuthorizationFormHeader
                className="StudyOnline__Header"
                title={title}
                subtitle={showSubtitle && "Здравствуйте!"}
                subtitleOnTop
            />

            {currStep === Steps.BASE_INFO && (
                <div className="StudyOnline__Warning">
                    Создавая профиль, вы будете автоматически зарегистрированы на онлайн-участие. Вы сможете изменить
                    тариф в Личном кабинете.
                </div>
            )}

            <FormStepper className="StudyOnline__Stepper" steps={2} startFrom={1} currentStep={currStep} />

            <form className="StudyOnline__Form" autoComplete="off" onSubmit={formik.handleSubmit}>
                {currStep === Steps.BASE_INFO && <BasicInformation formik={formik} nextStep={nextStep} />}

                {/* {currStep === Steps.LOCATION_INFO && <LocationInformation formik={formik} nextStep={nextStep} />} */}

                {/* {currStep === Steps.ACTIVITY_INFO && <ActivityInformation formik={formik} nextStep={nextStep} />} */}

                {currStep === Steps.PASSWORD_INFO && <CreatePassword formik={formik} />}
            </form>

            <ToSignIn />
        </>
    );
};

export default StudyOnline;
