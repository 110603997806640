import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { messageRefundSchema } from "@/Schema/messageRefundSchema";
import Textarea from "@/components/Textarea";
import Button from "@/components/Button";
import Tooltip from "@/components/Tooltip";
import TitlePageAdmin from "@/components/TitlePageAdmin";
import { toastNotifications } from "@/components/Toastify";
import { ReactComponent as IconWarnMessage } from "./icons/warn-icon.svg";
import { hasError } from "@/utils/formik";
import { useUser } from "@/context/UserContext";
import "./index.scss";
import { FormikErrorWrap } from "@/components/FormikErrorWrap";
import { checkPaymentStatus, refundRequest } from "@/api";
import { ProfilePaymentInfo } from "@/pages/Profile/ProfilePaymentInfo";
import { useCheckPaymentStatus } from "@/pages/Profile/ProfilePaymentInfo/useCheckPaymentStatus";
import { Helmet } from "react-helmet";

const Refund = () => {
    const { userInfo } = useUser();

    const { data: isNotPayedComplete } = useCheckPaymentStatus();

    const formik = useFormik({
        initialValues: {
            userMessageRefund: "",
        },
        validationSchema: messageRefundSchema,
        onSubmit: async (formValues, actions) => {
            try {
                await refundRequest(formValues.userMessageRefund);
                toastNotifications.info("Ваша заявка отправлена");
                actions.resetForm();
            } catch (error) {
                toastNotifications.error("Ошибка отправки, попробуйте ещё раз");
            }
        },
    });

    const disallowRefund = isNotPayedComplete;

    return (
        <>
            <Helmet>
                <title>Возврат денег | СберУниверситет</title>
                <meta name="description" content="Возврат денег" />
            </Helmet>
            <form className="Refund__Form" onSubmit={formik.handleSubmit}>
                <TitlePageAdmin textDesktop="Возврат денег" textMobile="Возврат денег" />
                <ProfilePaymentInfo />
                <div className="Refund__MessageWarn">
                    <div className="Refund__MessageIconWrap">
                        <IconWarnMessage />
                    </div>
                    <div className="Refund__Message">
                        {disallowRefund ? (
                            <span>У вас нет произведенных оплат</span>
                        ) : (
                            <span>
                                Как только менеджер обработает заявку ― мы отправим ответ на вашу почту:{" "}
                                <b>{userInfo?.email}</b>
                            </span>
                        )}
                    </div>
                </div>
                <fieldset className="Refund__FormWrap" disabled={formik.isSubmitting}>
                    <FormikErrorWrap formik={formik} name="userMessageRefund">
                        <Textarea
                            id="refund-message"
                            name="userMessageRefund"
                            label="Причина возврата"
                            placeholder="Минимум 100 символов"
                            minLength={100}
                            error={hasError(formik, "userMessageRefund")}
                            disabled={disallowRefund}
                            messageError={formik.errors.userMessageRefund}
                            length={formik.values.userMessageRefund.length}
                            value={formik.values.userMessageRefund.replace(/\s+/g, " ")}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                    </FormikErrorWrap>

                    <Tooltip
                        className="Refund__Tooltip"
                        text={disallowRefund ? "У вас нет произведенных оплат" : "Необходимо описать причину"}
                        diraction="top"
                        disabled={!disallowRefund && formik.isValid}
                    >
                        <Button
                            tag="button"
                            BtnType="submit"
                            className="Refund__Button"
                            gradient={true}
                            type="colored"
                            text="Отправить"
                            disabled={!formik.isValid || formik.isSubmitting || disallowRefund}
                        />
                    </Tooltip>
                </fieldset>
            </form>
        </>
    );
};

export default Refund;
