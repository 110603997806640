import Container from "@/components/Container";
import Headline from "@/components/Headline";
import WinnerCard from "./components/WinnerCard";
import { dataWinners } from "./dataWinners";
import ModalWinner from "./components/ModalWinner";
import "./index.scss";
import { useState } from "react";
import { Winner } from "./types";

const Winners: React.FC = () => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [currentWinner, setIsCurrentWinner] = useState<Winner | undefined>();
    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };
    return (
        <section className="Winners">
            <div className="Winners__Dekor Winners__Dekor--Left"></div>
            <div className="Winners__Dekor Winners__Dekor--Right"></div>
            <Container className="Winners__Container">
                <Headline level={"h2"} className="Winners__Title" theme="light" children={"Победители"}></Headline>
                <div className="Winners__List">
                    {dataWinners &&
                        dataWinners.map((winner) => (
                            <WinnerCard
                                onClickCardWinner={openModal}
                                winner={winner}
                                key={winner.name}
                                setWinner={setIsCurrentWinner}
                            />
                        ))}
                </div>
            </Container>
            <ModalWinner isOpen={modalIsOpen} onRequestClose={closeModal} winner={currentWinner} />
        </section>
    );
};

export default Winners;
