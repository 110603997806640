import { toastNotifications } from "@/components/Toastify";
import { downloadCertificateApi } from "@/api";
import { ACCESS_TO_CERTIFICATE  } from "@/constants/dates";
import { useToggle } from "react-use";

const checkDateIsEvent = () => {
    const currentDate = new Date();
    const eventDate = new Date(ACCESS_TO_CERTIFICATE);
    return currentDate >= eventDate;
};

const useDownloadCertificate = () => {
    const isDownloadActive = checkDateIsEvent();
    const [isDownloading, toggleIsDownloading] = useToggle(false);

    const downloadCertificate = async () => {
        try {
            toggleIsDownloading(true)
            const res = await downloadCertificateApi();
            const blobPdf = (res.data as Blob);
            const blobOcte = blobPdf.slice(0, blobPdf.size, 'application/octet-stream');
            const blobUrl = URL.createObjectURL(blobOcte);
            const link = document.createElement('a')

            link.href = blobUrl;
            link.target = "_blank";
            link.download = 'certificate.pdf';
            link.click();
            toggleIsDownloading(false)
        } catch {
            toastNotifications.error("Что-то пошло не так. Повторите попытку позже");
        }
    }

    const showWarning = () => {
        toastNotifications.warnInfo("Доступ к сертификату откроется 20 октября")
    }

    return { isDownloadActive, showWarning, isDownloading, downloadCertificate }
}

export default useDownloadCertificate;