import { useState } from 'react';
import Countdown from 'react-countdown';
import { toastNotifications } from "@/components/Toastify";
import AuthorizationFormHeader from "@components/AuthorizationFormHeader";
import { recoverPasswordByEmail } from '@/api';
import './index.scss';

const LinkSent = ({ userEmail }) => {
    const [reset, setReset] = useState(false);

    const handleClick = async () => {
        try {
            const data = {
                email: userEmail,
            }

            const response = await recoverPasswordByEmail(data);
            toastNotifications.success("Письмо повторно отправлено");
            setReset(prev => !prev)
        } catch {
            toastNotifications.error("Что-то пошло не так. Повторите попытку позже");
        }
    }

    const renderer = ({ minutes, seconds, completed }) => {
        if (completed) {
            return (
                <button
                    className="LinkSent__Send-again"
                    onClick={handleClick}
                >
                    Отправить повторно
                </button>
            );
        } else {
            const formattedSeconds = seconds.toString().padStart(2, '0');
            const formattedMinutes = minutes.toString().padStart(2, '0');
            return (
                <p className="LinkSent__Count-down">
                    Отправить повторно можно через {formattedMinutes}:{formattedSeconds}
                </p>
            );
        }
    };

    return (
        <div className="LinkSent">
            <AuthorizationFormHeader
                className={"ByEmail__Header"}
                title={"Письмо отправлено"}
                subtitle={`Мы отправили вам письмо на ${userEmail}\nс ссылкой для подтверждения`}
            />

            <Countdown
                key={reset}
                date={Date.now() + 10000}
                renderer={renderer}
            />
        </div>
    )
}

export default LinkSent;